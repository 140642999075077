@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 0px;
  flex-basis:1039px;
  /* @media (--viewportMedium) { */
  /*   flex-basis: 567px; */
  /* } */
}


.modalLeftColumn {
/* Rectangle 29 */
background: rgba(80, 140, 240, 0.1);

}

.ModalColumnPadding {
  padding:30px;
}

.modalContent {
  flex-grow: 1;
}

.invoiceFailInfo {
  margin-bottom: 30px;
}

.logo {
  margin-bottom:25px;
  width:150px;
}


.stripeSupport {
  text-align: center;
  margin-top:100%;

  & img{
    background: #fff;
    border-radius:15px;
    padding:15px;
    
  }
}
