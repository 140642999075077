@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 48px;
  }
}
.error {
  @apply --marketplaceModalErrorStyles;
}
.apiKey{
  margin-bottom:20px;
  resize: none;
}
.apiGuide{
  width:100%;
  display: flex;
  margin-top:50px;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding:10px;
  border: 1px solid #eeeeee;
  word-break: break-word;
}