@import '../../marketplace.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  max-width: 100vw;

  @media (--viewportMedium) {
    padding: 30px;
    padding-top: 27px;
  }

  --EditListingPhotosForm_imageWidthDesktop: calc(25% - 12px);
}

.error {
  color: var(--failColor);
}

.goBackButton {
  display: flex;
  align-items: center;
  max-width: 200px;
  font-size: 14px;
  outline: none;
  &:hover {
    text-decoration: none;
  }
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 224px;
  @media (--viewportMedium) {
    margin-bottom: auto;
  }
}

.submitButton,
.submitDraftButton {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 10px;
  flex-shrink: 0;
  width: 100%;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 0 0 0 auto;
  }
}

.submitWorkTimeButton {
  width: 100%;
  min-height: 20px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 80%;
  }
}

.submitDraftButton {
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  border: 1px solid var(--matterColorAnti);

  &:hover {
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColor);
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 24px 0 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  /* height: 100%; */
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    height: 100%;
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (1 / 4));
}

.aspectRatioWrapper label {
  padding: 5px;
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightRegular);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.subscriptionNotActive,
.error {
  color: var(--failColor);
}

.noRightsToEdit,
.requiredError {
  color: var(--failColor);
  font-size: 17px;
}

.description,
.title {
  width: 100%;
  margin: 10px 0;
}

.title input {
  font-size: 16px;
}

.description textarea {
  font-size: 16px;
}

.hours textarea {
  padding: 0px;
  font-size: 16px;
  width: 80%;
}

.hoursModal textarea {
  padding: 0px;
  font-size: 16px;
  width: 100%;
}

.oneRowFields {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.oneRowFields > * {
  @media (--viewportLarge) {
    margin-top: auto;
    margin-right: 10px;
  }
}

.priority,
.deliveryDate .hours .hoursModal {
  width: 100%;
  @media (--viewportLarge) {
    width: unset;
  }
}
