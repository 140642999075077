@import '../../marketplace.css';

/* Main */
.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 32px 0;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
  border-color: var(--matterColorAnti);
  border-radius: 5px;
  @media (--viewportMedium) {
    flex-direction: row-reverse;
    width: 90%;
  }
}
.buttonIcon,
.infoIcon,
.bellIcon {
  vertical-align: text-top;
}
.switchButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 28px;
  @media (--viewportMedium) {
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);
  }
}

.switchButton {
  padding: 10px;
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  border-radius: 5px;
  white-space: nowrap;
  width: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.switchButtonChecked {
  color: var(--successColor);
  border-color: var(--successColor);
}

.content {
  padding: 0 28px;
  overflow: hidden;
}

.stepContent {
  transition: opacity 0.5s ease-in-out;
}

.stepTitle {
  display: block;
  width: 100%;
  font-size: 24px;
}

.ctaButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.ctaButton {
  max-width: 250px;
  min-height: 50px;
  max-height: 50px;
  margin: 0 auto;
  border-radius: 5px;
}

.namedLinkButton {
  display: block;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
}

.expertsPhoto {
  width: 400px;
}

/* Support plans */
.supportGraphicContainer {
  width: 100%;
  height: 200px;
  margin: 35px 0;
  display: flex;
}

.graphicText {
  font-size: 16px;
}

.graphicPhoto {
  width: 100px;
  height: 100px;
}

.coworkerInfo {
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.coworkerInfoLink {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  &:hover {
    text-decoration: underline;
  }
}

/* Post job */
.companiesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.companiesTitle {
  width: 100%;
  font-size: 16px;
  margin: 25px 0 0 0;
}

.companyLogoImg {
  height: 30px;
  margin: 10px 1vw;
}

/* Ticketing system */
.ticketsIconsContainer {
  width: 100%;
  height: 400px;
  margin: 35px 0;
  display: flex;
  flex-wrap: wrap;
}

.ticketsDescription {
  font-size: 16px;
  line-height: 24px;
}

.ticketsFeatures {
  font-size: 13px;
  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.imageSliderContainer {
  display: grid;
}

.imageBilling {
  display: grid;
  height: 250px;
  @media (--viewportMedium) {
    height: 350px;
  }
}

.imageHourlyPlan {
  width: 300px;
  @media (--viewportMedium) {
    width: 400px;
  }
}
