@import '../../marketplace.css';

.mainWrapper {
    margin-bottom: 50px;
}

.scopeWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.jobScopeTaskName {
    width: 100%;
    margin-right: 10px;
}

.HidePriceTask {
    display:none;
}

.scopeTitle {
    font-size: 36px;
    font-weight: var(--fontWeightRegular);
    line-height: 56px;
    letter-spacing: -2px;
    margin-top: 24px;
    margin-bottom: 12px;
}

.scopeTaskWrapper,
.scopeTaskWrapperJob {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.scopeSubtaskWrapper,
.scopeSubtaskWrapperJob {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
}

.scopeSubtaskPointer {
    width: 5%;
    margin-right: 10px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
}

.scopeSubtaskName,
.scopeSubtaskNameJob {
    width: 51%;
    margin-right: 10px;
    margin-top: 18px;
}

.scopeSubtaskNameJob {
    width: 87%;
}

.scopeSubtaskTime {
    width: 20%;
    margin-right: 10px;
    margin-top: 18px;
}

.scopeSubtaskPrice {
    width: 16%;
    margin-right: 10px;
    margin-top: 18px;
}

.scopeSubtaskButtonsWrapper {
    width: 8%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.scopeSubtaskButton {
    width: 8%;
    margin-top: 18px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.suggestionsLabel {
    margin-bottom: 8px;
}

.scopeTaskName,
.scopeTaskNameJob {
    width: 56%;
    margin-right: 10px;
}

.scopeTaskNameJob {
    width: 90%;
}
  
.scopeTaskTime {
    width: 17%;
    margin-right: 10px;
}

.scopeTaskPrice {
    width: 17%;
    margin-right: 10px;
}
  
.scopeTaskButton {
    width: 8%;
    margin-top: 0px !important;
    /* margin-top: 32px !important; */
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.scopeTaskButtonsWrapper {
    width: 8%;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scopeTaskButtonsFirstLine {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.scopeTaskButtonsSecondLine {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
 
.addSubtaskTextButton {
    margin: 0;
    padding: 0;
    font-size: 0.7em;
    text-decoration: underline;
}

.scopeTaskButtonMin {
    width: 50%;
    margin: 0;
}
  
.missingAccountVerification {
    color: var(--failColor)
}
  
.scopeCommentWrapper {
    margin-bottom: 10px;
}
  
.scopeCommentText {
    font-size: 0.7em;
    color: var(--matterColor);
}
  
.scopeInputLabel {
    display: flex;
    flex-direction: row;
    color: var(--matterColor);
    font-size: 16px;
    line-height: 24px;
    font-weight: var(--fontWeightRegular);
    padding-top: 6px;
    padding-bottom: 2px;
}
  
.scopeInputValue {
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 2px solid var(--matterColor);
    padding: 4px 0 10px 0;
}
  
.taskPriceLabelWrapper {
    display: flex;
    flex-direction: row;
}

@media (min-width:321px) and (max-width:758px) {
    .scopeTaskWrapper {
      display: grid;
      grid-template-columns: 4fr 2fr 80px;
      grid-template-rows: 1fr;
      grid-template-areas: 
        "scopeTaskName scopeTaskTime scopeTaskButton";
    }
    .scopeTaskName {
      grid-area: scopeTaskName;
      width: 100%;
    }
    .scopeTaskTime {
      grid-area: scopeTaskTime;
      width: 90%;
      margin-right: 10px;
    }

    @media (max-width:321px) {
        .scopeTaskWrapper {
            display: grid;
            grid-template-columns: 4fr 4fr 80px;
            grid-template-rows: 1fr 1fr;
            grid-template-areas: 
              "scopeTaskName scopeTaskName scopeTaskName"
              "scopeTaskTime scopeTaskTime scopeTaskButton";
          }
    }


    .scopeTaskTime label, .scopeTaskName label {
        @media(--viewportLargeMax){
         font-size:0.875rem; 
        }
      }

    .scopeTaskPrice {
      grid-area: scopeTaskPrice;
      width: 100%;
    }
    .scopeTaskButtonsWrapper {
      grid-area: scopeTaskButton;
      width: 100%;
    }
    .scopeInputValue {
      padding: 5px 0 5px 0;
    }
    .scopeInputLabel {
      padding-top: 0;
      padding-bottom: 0;
    }
    .scopeSubtaskWrapper {
        display: grid;
        grid-template-columns: 1fr 4fr 4fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
          "scopeSubtaskPointer scopeSubtaskName scopeSubtaskName scopeSubtaskName"
          "scopeSubtaskPointer scopeSubtaskTime scopeSubtaskPrice scopeSubtaskButton";
    }
    .scopeSubtaskPointer {
        grid-area: scopeSubtaskPointer;
        width: 100%;
    }
    .scopeSubtaskName {
        grid-area: scopeSubtaskName;
        width: 90%;
    }
    .scopeSubtaskTime {
        grid-area: scopeSubtaskTime;
        width: 90%;
    }
    .scopeSubtaskPrice {
        grid-area: scopeSubtaskPrice;
        width: 90%
    }
    .scopeSubtaskButtonsWrapper {
        grid-area: scopeSubtaskButton;
        width: 100%;
    }
    /* JOB MOBILE VIEW */
    .scopeTaskWrapperJob {
        display: grid;
        grid-template-columns: auto 70px;
        grid-template-areas: 
          "scopeTaskName scopeTaskButton";
    }
    .scopeSubtaskWrapperJob {
        display: grid;
        grid-template-columns: 20px auto 40px;
        grid-template-areas: 
          "scopeSubtaskPointer scopeSubtaskName scopeSubtaskButton";
    }
}