.disableScrolling {
  height: 100vh;
  overflow: hidden;
}

.container {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  /* padding-top: 3.125rem; */
  padding-bottom: 1.125rem;
  min-height: calc(30vh - 72px);

  @media only screen and (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.SearchBar{
  background: #f6f9fe;
  padding-top:3rem;
    @media only screen and (max-width: 600px) {
    padding-top:0.875rem;
  }
  }

.headerBig {
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom:1rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
    text-align: center;
  }
}
.searchBar {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  display: flex;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.searchInput {
  flex-grow: 1;
}

.searchBtn {
  background: #2ecc71;
  padding: 1rem 3rem;
  color: #fff;
  border-radius: 8px;
  margin-left: 10px;
  border: none;
}

.inputStyle {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 1rem;
  font-weight: normal;
  font-size: 1rem;
  background-color: #fff;
}

.inputStyle:focus {
  border: none;
}
.inputStyle:hover {
  border: none;
}

.itemList {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Item container */

.itemContainer {
  border-radius: 8px;
  border: 1px solid #ececec;
  width: 100%;
  padding: 2.5rem 2.5rem 2rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
  transition: all 0.3s;

  @media only screen and (max-width: 768px) {
    padding: 1.5rem 1.5rem 1rem;
    margin-bottom: 1rem;
  }
}

.itemContainer:hover {
  cursor: pointer;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 34%);
  transition: all 0.3s;
}

.imageHolder {
  background: #3c88f8;
  border-radius: 50%;
  display: flex;
  width: 8.125rem;
  height: 8.125rem;
  position: relative;
  align-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;

  & span {
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    flex-grow: 1;
  }

  & img {
    max-width: 8.125rem;
    width: auto;

    @media only screen and (max-width: 600px) {
      max-width: 7rem;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 7rem;
    height: 7rem;
  }
}

.selected {
  background: #3c88f8 !important;
  color: #fff;
}

.rowPadding {
  padding-top:1.2rem;

  @media only screen and (max-width: 600px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}



.headerh1 {
  padding-bottom:1rem;
  font-size:1.5rem;
}

.dataHolder {
}

.exTitle {
  color: #727171;
  font-size: 1.375rem;
  font-weight: 500;
}

.exName {
  color: #727171;
  font-size: 1.25rem;
  margin-bottom: 5px;
  display: flex;
}

.separator {
  background: #d9d9d9;
  display: block;
  height: 1px;
  width: 100%;
  margin: 12px 0px;
}

.profileInfoBlock {
  flex-grow: 1;
}

.softwareBlock {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
  & a {
    background: #f1f3f6;
    font-size: 0.875rem;
    color: #000;
    padding: 8px 9px;
    border-radius: 8px;
  }

  @media only screen and (max-width: 576px) {
    overflow: hidden;
    max-height: 34px;
  }
  &:empty {
    margin-bottom: 0px;
  }
}

.skillsBlock {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
  & a {
    background: #edf3fd;
    font-size: 0.875rem;
    color: #508cf0;
    padding: 8px 9px;
    border-radius: 8px;
  }
  @media only screen and (max-width: 576px) {
    overflow: hidden;
    max-height: 34px;
  }
  &:empty {
    margin-bottom: 0px;
  }
}

.induBlock {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
  & span {
    background: #e9f7ec;
    font-size: 0.875rem;
    color: #62c87a;
    padding: 8px 9px;
    border-radius: 8px;
  }
  @media only screen and (max-width: 576px) {
    overflow: hidden;
    max-height: 34px;
  }

  &:empty {
    margin-bottom: 0px;
  }
}

.loading {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top:5rem;
}

.additionalInfo {
  padding: 5px;
  text-align: center;
}

.verifiedMarker {
  margin-left:10px;
  position: relative;
  @media only screen and (max-width: 576px) {
    margin-left: 0px;
    width: 35px;
    padding: 2px 6px;

    & div {
      position:absolute;
      top:-3px;
    }
  }

  & svg {
    fill: #2ecc71;
    margin-right: 5px;
  }
}

.floatingblock {
  background: #ffffff;
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  border: 2px solid #b8b8b8;
  z-index: 10;
  overflow-y: auto;
}

.threecolumns {
  column-count: 4;

  @media only screen and (max-width: 576px) {
    column-count: 2;
  }
}

.filterOptions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 1rem;
  }
}

.floatingblock_header {
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  padding: 1.2rem 0rem 1.2rem;
  max-width: 1366px;
  margin: 0 auto 1rem;
  align-items: center;

  @media only screen and (max-width: 576px) {
    padding: 10px;
  }
}

.floatingblock_header_header {
  font-size: 1.5rem;
  font-weight: 500;
  flex-grow: 1;
}

.floatingblock_header_apply {
  & button {
    background: #2ecc71;
    color: #fff;
    padding: 6px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 1rem;
    border: none;
  }
}

.floatingblock_header_clearbutton {
  color: #727171;
  font: 1.125rem;

  & button {
    border: 0px solid #fff;
  }
  &:hover {
    color: #508cf0;
    cursor: pointer;
  }
}

.filterBtn {
  border: 1px solid #d9d9d9;
  font-size: 1rem;
  padding: 10px 24px;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
}

.filterBtn:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 9%);
}

.radionBox {
  display: flex;
  gap: 0.375rem;
  width: 25%;
  margin-bottom: 10px;
  width: 100%;

  & input {
    width: 20px;
  }
  & div {
    flex-shrink: 1;
    font-size: 1.125rem;
    display: inline-block;
    padding-left:10px;

  }
  & label {
    display: flex;
    gap: 0.625rem;
  }
}

input[type='radio'] {
  place-content: center;
  position: relative;
  height: 0px;;
}

input[type='radio']::before {
  content: ' ';
  height: 25px;
  border: 1px solid #b8b8b8;
  width: 25px;
  background: #fff;
  display: block;
  z-index: 9999;
  border-radius: 50%;
}

input[type='radio']::after {
  content: ' ';
  height: 17px;
  width: 17px;
  background: #b8b8b8;
  display: block;
  z-index: 9999;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

input[type='radio']:checked::after {
  content: ' ';
  height: 17px;
  width: 17px;
  background: #b8b8b8;
  display: block;
  z-index: 9999;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: 120ms transform ease-in-out;
  transform: scale(1);
}

.Pagination {
  & ul {
   margin: 0 auto !important;
  }
}

.iconCert {
  height:1.875rem;
}

.iconClose{
  &:hover {
    stroke:#000;
  }
}
