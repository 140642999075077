@import '../../marketplace.css';

.root {
  position: relative;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportLarge) {
    flex-direction: row;
    /* max-width: 1128px; */
    margin: 0;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 110px;
  padding:30px;

  @media (--viewportMedium) {
    padding:0px 30px;
  }

  @media (--viewportLarge) {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    padding: 0 89px 0 30px;
    margin-bottom: 0;
  }
}

.txInfoNoMargin {
  margin-bottom: 0px;
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

.viewProfileButton {
  @apply --marketplaceButtonStylesPrimary;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 0.8em;
  font-weight: var(--fontWeightRegular);
}

.linkNoDecoration:hover {
  text-decoration: none !important;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin: 42px auto 0 auto;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 100px;

  @media (--viewportMedium) {
    margin-top: 42px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin: 42px auto 0 auto;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;
  margin: 0 auto;

  @media (--viewportMedium) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 50px 0 0;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 50px 0 0;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0;
  }
}

.mainTitle {
  display: block;
  font-size: 24px;
  line-height: 36px;
  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 48px;
  }
}

.transactionInfoMessage {
  font-size: 16px;
  color: var(--matterColorAnti);
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);
  @media (--viewportLarge) {
    padding-left: 36px;
  }
  @media (--viewportLargeWithPaddings) {
    padding-left: 59px;
  }
}

.detailCard {
  @media (--viewportLarge) {
    /* position: sticky; */
    /* top: -200px;  */
    /* This is a hack to showcase how the component would look when the image isn't sticky */
    /* width: 409px; */
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH4FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

.detailCardMainInfo {
  display: flex;
  margin: 0 48px auto 48px;
  flex-direction: column;
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 18px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 24px 48px 47px 48px;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    padding: 0;
  }
}

.inviteButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  & button {
    margin: 10px auto auto auto;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 10px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 24px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 0;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.modalBooking {
@media (--viewportMedium){
  display: none;
}
@media(--viewportLarge) {
  display:none;
}
@media(--viewportXLarge) {
  display:none;
}
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.infoIcon {
  fill: var(--marketplaceColor);
  vertical-align: text-top;
}

.submitCredentialsButton {
  display: flex;
  width: 100%;
  text-decoration: none;
}

.submitCredentialsButton:hover {
  text-decoration: none;
}

.restButtons {
  margin: 10px auto 10px auto;
  width: 100%;
  min-width: 300px;
}

.viewExpertButtonWrapper {
  display:none;
  @media (--viewportLarge){
    display: block;
  margin: 0 0 16px 0;
  }
}

.viewExpertButtonWrapperMobile {
  margin: 12px 10% 16px 10%;
  @media (--viewportLarge){
    display: none;
  }
}

.viewExpertButton {
  min-height: 50px;
  outline:none;
  &:hover {
    outline:none;
  }
}

.linkViewExpert {
  outline:none;
  text-decoration: none;
  &:hover {
    outline: none;
    text-decoration: none;
    background-color: red;
  }
}

.wherebyLink {
  outline:none;
  text-decoration: none;
  &:hover {
    outline: none;
    text-decoration: none;
    background-color: red;
  }
}

/* OFFER DETAILS: SCOPE, PRICE */
.detailsWrapper {

  margin: 0 0vw;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    width: 75%;
    margin: 0;
    margin-top: 30px;
  }
}

.detailsHeading {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.detailsMainContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
  @media (--viewportLarge) {
    width: 50%;
  }
}

.detailsPriceContainer, .detailsTimeContainer {
  /* margin-bottom: 25px; */
}

.priceIcon, .timeIcon {
  /* font-size: 32px; */
  vertical-align: text-top;
}

.detailsValue {
  color: var(--marketplaceColor);
  display: block;
  font-size: 24px;
  margin-top: 12px;
}

.additionalDetailsMargin {
  margin: 15px 0px;
}

.detailsSection {
  margin-top: 15px;
  font-size: 16px;
}

.bookingTimePanelDesktop {
  display:none;
  @media (--viewportMedium) {
    display: block;
  }
}

.sectionOfferDescription {
  margin-bottom: 24px;
}

.sectionDetailsTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  margin-top: 0;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.sectionDetailsText {
  margin-bottom: 13px;
  font-size: 16px;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
}

.sectionDetailsDescText {
  margin-bottom: 33px;
}

.adminOfferValuesTitleWrapper {
  margin-bottom: 5px;
}

.adminOfferValuesTitle {
  font-size: 16px;
  vertical-align: middle;
}

.adminOfferValues {
  font-size: 13px;
  font-weight: var(--fontWeightRegular);
}

.offersContainer {
  margin-top: 10px;
  margin-bottom: 8px;
}

/* RELATED OFFER CARD */

.initialsDesktop {
  font-size: 26px;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 5px;
  @media (--viewportMedium) {
    font-size: 30px;
    padding-bottom: 6px;
  }
}

.itemLink {
  flex: 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.relatedOfferWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
}

.relatedOfferExpertWrapper {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-areas: "relatedOfferAvatar relatedOfferText";
}

.relatedOfferAvatar {
  grid-area: relatedOfferAvatar;
  width: 40px;
  height: auto;
}

.relatedOfferText {
  grid-area: relatedOfferText;
  display: flex;
  flex-direction: column;
}

.relatedOfferName {
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.relatedOfferPrice {
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.relatedOfferButtonsWrapper {
  display: flex;
  flex-direction: row;
}

.relatedOfferButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
}

/* NEGOTIATE */
.negotiateLabel {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* color: var(--successColorDark); */
}

.negotiateButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.negotiateButton {
  height: 41px;
  min-height: 41px;
  padding: 0 16px;
  white-space: nowrap;
  width: fit-content;
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
}



.acceptClientOfferWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  font-size: 10px;
}

.acceptClientOfferButton {
  height: 60px;
  min-height: 41px;
  padding: 0 16px;
  white-space: nowrap;
  width: fit-content;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
}

.offerWasNegotiatedInfoWrapper {
  margin-bottom: 15px;
}

.offerWasNegotiatedInfoText {
  font-size: 14px;
}

.selectLabel {
  margin: 5px 0px;
}

.negotiationSelectWrapper {
  margin-top: 5px;
  margin-bottom: 25px;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: #555555;
  flex-shrink: 0;
  margin-top: 15px;

  @media (--viewportMedium) {
    margin-bottom: 20px;
  }
}

.downloadButton {
  display: block;
  margin: 0 24px;
  &:hover {
    text-decoration: none;
  }
  @media (--viewportMedium) {
    margin: 12px 0;
  }
}

.subtitle {
  margin: 24px;
  @media (--viewportMedium) {
    margin: 12px 0;
  }
}

/* NPS link*/

.npsLinkWrapper {
  margin: auto 14px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  width: 100%;
  justify-content: space-evenly;
  @media (--viewportLarge) {
    padding: unset;
  }
}

.copyButton,
.copiedText {
  width:10%;
  margin-top: auto;
  margin-bottom: auto;
}

.copyButton:hover {
  color: var(--matterColorDark);
  cursor: pointer;
}

.npsLink {
  margin-left: 0;
  width: 90%;
}

.modalContentContainer {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}
.attachmentList {
  & li{
  margin-bottom:5px;
  list-style:inside;
  }
}


.rightColumn {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);
  @media (--viewportLarge) {
    max-width: 332px;
    padding-left: 36px;
  }
  @media (--viewportLargeWithPaddings) {
    padding-left: 59px;
  }
}
.rightColumnNew {
  background-color: #f1f3f6;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left:10px;
  padding-right: 10px;
  @media (max-width: 756px) {
    order: 1;
    display: none;
  }
}
