@import '../../../marketplace.css';
@import './panelWrappers.css';

.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    margin-bottom: 6vh;
}

.buttonsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.createListingLinkCTA {
    @apply --marketplaceH4FontStyles;
    color: var(--marketplaceColor);
    margin-top: 0;
    margin-bottom: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: var(--marketplaceColorDark);
      text-decoration: none;
    }
  }

  .ctaButton {
    padding: 5px 6px 5px 6px;
    border-radius: 5px;
    white-space: nowrap;
    width: 22vw;
    height: 6vh;
    background-color: var(--successColor);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5vw;
    font-size: 1.1em;
    &:hover {
      background-color: var(--successColorDark);
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

  .companiesHeadingWrapper {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.5em;
    color: lightgray;
  }

  .companiesWrapper {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ticketButton {
    background-color: white;
    color: var(--matterColor);
    border: 1px solid #e7e7e7;
    font-size: 0.9em;
    &:hover {
      background-color: white;
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

/* LOWER THEN 768PX HEIGHT NEW SIDE PANEL */
@media (--viewportHeightMaxHD) {
    .titleWrapper {
        font-size: 1em;
        margin-bottom: 4vh;
    }
    .ctaButton {
        margin: 0.3vh;
    }
}
