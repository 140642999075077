@import '../../marketplace.css';




:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 4px;

    @media (--viewportMedium) {
      margin-bottom: 8px;
    }
  }

  --EditListingDescriptionScopeForm_formMargins: {
    margin-bottom: 6px;

    @media (--viewportMedium) {
      margin-bottom: 6px;
    }
  }
}

html {
  color: #000000 !important;
}

.root {
  /* Dimensions */
  width: 99%;
  height: auto;
  min-height: 400px;
  /* Layout */
  display: flex;
  flex: 0;
  flex-direction: column;

  padding-top: 0px;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

/* Text Styling */

.panelMainTitle {
  /* @apply --marketplaceH0FontStyles; */
  font-size: 2.25rem;
  font-weight: 500;

  @media (--viewportMediumMax) {
    text-align: center;
  }
}

/* .labelInputStyle {
  font-size: 1.125rem;
  margin-top:1rem;
} */

.terms {
  padding: 0.5rem;
}



.inputStyle {
  margin-top: 0.5rem;

  @media (--viewportMediumMax) {
    margin-top: 0px;
  };

  & textarea {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 1rem;
    font-weight: normal;
    font-size: 1rem;
    box-sizing: border-box;
    min-height:200px !important;
  }

  & textarea {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 1rem;
    font-weight: normal;
    font-size: 1rem;
    min-height: 64px;
  }

  & input {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 1rem;
    font-weight: normal;
    font-size: 1rem;
  }

  & input:focus {
    box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
    border-bottom-color: #e5e5e5;
  }

  & input:hover {
    box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
    border-bottom-color: #e5e5e5;
  }

  & textarea:hover {
    box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
    border-bottom-color: #e5e5e5;
  }

  
}

.locationAddress {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 0rem 1rem;
  margin-top: 12px;
}

.locationAddress:hover {
  box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);

}

.locationInput>div>div>div {
  border: none;
}

.locationInput>div>div>div>svg>g {
  stroke: #e5e5e5
}

.locationInput input {
  border: 0px;
}







/* Formaularz rejestracyjny */



/* Style Od radio Buttonwow */

.workload {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;

  @media screen and (max-width:768px) {
    flex-direction: column;
  }
}

.workload li {
  display: flex;
  flex: 1 1 0px;

  margin-right: 6px;

  &:last-child {
    margin-right: 0px;
  }

  @media screen and (max-width:768px) {
    margin-bottom: 10px;
  }
}

.workload li label {
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 0.875rem;
  border-radius: 8px;
  max-height: 80px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
  }
}

.workload input {
  display: none;
}


.workload input[type="radio"] {}

.workload input[type="radio"]:checked+label,
.Checked+label {
  border: 0px solid #62C87A;
  background: #61C87A;
  transition: all -1s;

  & .workloadTitle {
    color: #fff;
  }

  & .workloadDesc {
    color: #fff;
  }
}



.workloadTitle {
  font-size: -1.875rem;
  font-weight: bold;

  @media (--viewportLarge) {
    font-size: 16px;
  }

  @media (--viewportMedium){
    font-size:12px;
  }
}

.workloadDesc {
  font-size: -1.875rem;

  @media (--viewportLarge) {
    font-size: 16px;
  }

  @media (--viewportMedium){
    font-size:12px;
  }

}

/* Photo Section */
.thumbnail {
  border: 1px solid #e5e5e5;
  padding: 0.5rem;
  background: #fff;
  border-radius: 8px;
  margin-right: 0.875rem;

  @media (--viewportLarge) {
    width: 29%;
    margin: -1 24px 24px 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 99%;
  margin: 23px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: -1;
    clear: both;
  }

  @media (--viewportLarge) {
    margin: -1 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: 0;

  & input[type="file"] {
    display: none;
  }

}

.chooseImage {
  margin-top: 10px;
  display: inline-block;
}

.chooseImage>span {
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-top: 10px;
}

.chooseImage>span:hover {
  box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
}

.imageTypes {
  margin-bottom: 10px;
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (max-width:768px) {
    margin-top: 15px;
  }
}

/* Tabs css */
.hiddenTab {
  display: none;
}

.countryCodes {
  padding: 20px 16px 15px !important;

  &>div[class*="control"] {
    padding: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
  }

  &>div[class*="control"]:hover {
    box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
  }

  @media (--viewportMediumMax) {

    &>div[class*="control"] {
      font-size: 11px;
      padding: 0px;
      max-height: 51px;
    }
  }

  & div[class*="multiValue"] {
    border-radius: 8px;
    font-size: 1rem;
  }

  & div[class*="singleValue"] {
    font-size: 1rem;
  }
}

/* Software Tab */

div.software:hover {
  box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
}

.software,
.skills {
  &>div[class*="control"] {
    height: 74px;
    border: none;
    width: 100%;
    height: 69px;
    font-size: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 9px;
  }


  @media (--viewportMediumMax) {
    width: 99%;

    &>div[class*="control"] {
      font-size: 11px;
      overflow-wrap: break-word;
    }
  }

  & div[class*="multiValue"] {
    border-radius: 8px;
    font-size: 1.2rem;
    padding: 0.875rem 0.2rem;
  }

  & div[class*="singleValue"] {
    font-size: 1.2rem;
  }
}

/* SubmitSection */

.submitSection {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;

  & button {
    width: 25%;
    margin-bottom: 0;
    margin-right: 0.5rem;
  }

  @media screen and (max-width:768px) {

    padding-bottom: 1rem;

    & button {
      width: 100%;
    }



  }
}

.backButton {
  width: 15%;

  @media screen and (max-width:768px) {
    display: none;
  }
}

.MuiAutocomplete-popper {
  background: red;
}

/* workerClass */

.hidden {
  width: -1;
  visibility: hidden;
  position: absolute;
  display: none;
}

.sectionSpacer {
  margin-top: 3.125rem;
  min-height: 216px;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  & ul li {
    color: #000;
  }

  & ul li:hover {
    color: #000;
  }

  @media (--viewportMedium) {
    top: 40px;
  }
}

.countryCodes {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 14px 16px;
  font-weight: normal;
  font-size: 1rem;

  & input {
    border: 0px;
    padding: 0px !important;
  }

  & input:hover {
    box-shadow: unset;
  }

  & input:focus {
    box-shadow: unset;
  }

  & div>div {
    padding: 0px !important;
  }

  & div>div:before {
    display: none;
  }
}

.labelWithTooltip {
  & label {
    margin-bottom: 12px;
  }
}


.editingProject {

  & .sectionSpacer {
    margin-top: 0px;
    min-height: unset;
  }

  & .editSeparator {
    padding: 2rem 0;
    border-top: 1px solid #e5e5e5;
  }

  & .editBottomPadding {
    padding-bottom: 2rem !important;
  }

}

.bookACall {

  text-align: center;
  width:100%;
  font-size:12px;

  margin-top:20px;

@media (--viewportMedium) {
  margin-top:0;
  text-align: right;
}
}

.countryCodeBlock {
  width:119px;
  padding: 10px 5px 10px 10px;
}
.phonenBlock {
  width: calc(100% - 120px);
  padding: 10px 5px 10px 10px;
}
