@import '../../marketplace.css';

.root {
}

.choiceText {
  font-size: 16px;
  margin-bottom: 8px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.buttonsWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choiceButton {
  background-color: white;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5vw;
  font-size: 16px;
  padding: 5px 6px 5px 6px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  width: 100px;
  &:hover {
    background-color: white;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    text-decoration: none;
    cursor: pointer;
  }
  @media (--viewportMedium) {
    width: 150px;
    font-size: 18px;
    padding: 10px 6px 10px 6px;
  }
}

.chosen {
  color: white;
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  &:hover {
    color: white;
    background-color: var(--marketplaceColor);
  }
}

.voucherContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 25px;
}

.voucher {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 20px 20px;
    border: solid 1px;
    border-radius: 5px;
    border-color: var(--matterColorNegative);
    box-shadow: var(--boxShadowSectionLocationHover);
    white-space: pre-line;

    @media (--viewportMedium) {
      width: 70%;
      padding: 20px 40px;
    }
}

.logo {
  display: none;
  @media (--viewportMedium) {
    display: flex;
  }
}

.voucherInvite {
    color: var(--marketplaceColor);
    margin: 0 0 15px 0;
    text-align: center;
    @media (--viewportMedium) {
      font-size: 30px;
    }
}

.voucherText {
    font-size: 18px;
    line-height: 1.5em;
    text-align: center;

    @media (--viewportMedium) {
      font-size: 24px;
    }
}

.voucherTitle {
    letter-spacing: 2px;
}

.tenPercent {
    color: var(--marketplaceColor);
    font-size: 3em;
    line-height: 1em;
    font-weight: var(--fontWeightMedium)er;
}

.linkButton {
    width: 70%;
    max-width: 350px;
    margin:16px;
    font-size: 1.25em;
    border-radius: 5px;
}

.linkButtonSecondary {
  width: 70%;
  max-width: 350px;
  border-radius: 5px;
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
}

.referralLink {
  padding: 10px 0 10px 10px ;
  margin: 0;
  font-size: 12px;
  width: 70%;
  border: 2px solid;
  border-color: var(--matterColorNegative);
  border-radius: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.copyButton {
  width: 65px;
  height: 65px;
  margin-left: 20px;
  border-radius: 5px;
  border-width: 2px;
  font-size: 14px;
  &:focus {
    border-color: var(--marketplaceColor);
    color: var(--marketplaceColor);
  }; 
  
}

.copied {
  font-size: 14px;
  color: var(--marketplaceColor);;
}

.helpText {
  width: 90%;
  text-align: left;
  @media screen (--viewportMedium) {
    width: 60%;
  }
}

.helpText li {
  text-align: left;
}

.helpText ol {
  padding: 10px;
}