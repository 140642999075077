@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorLight);
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  margin-bottom: 8px
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightRegular);

  display: inline-block;
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.mapIcon {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);
  display: inline-block;
  background-image: url(./images/map_icon216x105.png);
  background-size: cover;
  border-radius: 3px;
  width: 72px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: 8px;
  cursor: pointer;
}

.mapIconText {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.filtersAndSort {
  display: flex;
}

.searchBar {
  display: flex;
  justify-content: left;
  padding: 10px;;
  margin: 30px 0;
  width: 100%;
  border-bottom: 1px solid;
  border-bottom-color: var(--matterColorNegative);
}

.searchIcon {
  height: auto;
  border: none;
  width: 24px;
  fill: var(--marketplaceColor);
}
