@import '../../marketplace.css';

.layoutWrapper {
  flex-direction: column;
  justify-content: center;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.modalButton {
  width: calc(33% - 14px);
  padding: 10px;
}

.priceText {
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 16px;
    border-bottom: solid 1px var(--marketplaceColor);
    margin-bottom: 28px;
}

.readMoreLink {
    text-align: center;
}

.mostPopular {
  position: absolute;
  top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.mostPopular svg {
  vertical-align: text-top;
}

.balanceWrapper {
  display: inline;
  padding: 20px 30px;
  box-shadow: 0 0 8px 3px rgb(31 44 61 / 10%);
  border-radius: var(--borderRadius);
  @media (--viewportMedium) {
    width: 500px;
  }
}

.balanceDetailsTitle {
  font-size: 24px;
  text-align: center;
}

.balances {
  display: flex;
  justify-content: space-around;
}

.balanceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.balanceInfo > p {
  margin: 4px;
}

.stripeAccountsContent {
  @apply --marketplaceH1FontStyles;
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.zeroBalance, .positiveBalance {
  font-size: 22px;
}

.positiveBalance {
  color: var(--successColor)
}