@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.servicePriceTitle {
  font-size: 16px;
  margin: 0;
  margin-top: 26px;
  margin-bottom: 8px;
}

.servicePriceSubtitle {
  margin: 0;
  margin-bottom: 10px;
}

.serviceError {
  margin-top: 5px;
  margin-bottom: 10px;
  color: var(--failColor);
  font-size: 0.9em;
}

.serviceWarning {
  margin-top: 10px;
  color: var(--attentionColor);
  font-size: 0.9em;
}

.serviceFinalPriceTitle {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: space-evenly;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  margin-right: 10px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.smallPrintLeft {
  text-align: left;
  margin: 0;
  margin-top: 10px;
}

.subtitlePrice {
  color: rgb(74,74,74, 0.9);
  margin-bottom: 10px;
  margin-top: 10px;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 4px;
}

/* SCOPE */
.scopeTitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -2px;
  margin-top: 24px;
  margin-bottom: 12px;
}

.scopeWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.scopeTaskName {
  width: 50%;
  margin-right: 10px;
}

.scopeTaskTime {
  width: 20%;
  margin-right: 10px;
}

.scopeTaskPrice {
  width: 20%;
}

.scopeTaskButton {
  width: 10%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scopeTaskButtonsWrapper {
  width: 10%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scopeTaskButtonMin {
  width: 50%;
  margin: 0;
}

.missingAccountVerification {
  color: var(--failColor)
}

.scopeCommentWrapper {
  margin-bottom: 10px;
}

.scopeCommentText {
  font-size: 0.7em;
  color: var(--matterColor);
}

.scopeInputLabel {
  display: flex;
  flex-direction: row;
  color: var(--matterColor);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 6px;
  padding-bottom: 2px;
}

.scopeInputValue {
  font-family: Helvetica,'sofiapro', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 2px solid var(--matterColor);
  padding: 4px 0 10px 0;
}
.addUserEmail {
  width: 100%;
}

.addUsersWrapper {
  display: flex;
  flex-direction: row;
}

.addUsersButtonWrapper{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: auto;
}

.button {
  margin-right: 0;
}

.taskPriceLabelWrapper {
  display: flex;
  flex-direction: row;
}

/* /SCOPE */

.estimatedTimeInput {
  flex-shrink: 0;
  width: 100%;
  margin: 15px 0;
}

.estimatedTimeAdminWrapper {
  display: flex;
  flex-direction: row;
}

.estimatedTimeInputAdmin {
  flex-shrink: 0;
  width: 20%;
  margin-bottom: 15px;
  margin-right: 10px;
}

.loadServiceScopeLink {
  color: var(--marketplaceColorLight);
  cursor: pointer;
}

.serviceScopeLoadingError {
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);
}

.scopeSubtitle {
  margin-top: 0;
}