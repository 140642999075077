@import '../../../marketplace.css';

.rootForImage {
    /* Layout - image will take space defined by aspect ratio wrapper */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  
    transition: var(--transitionStyleButton);
  
    @media (--viewportMedium) {
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      object-fit: cover;
      border-radius: var(--borderRadius);
  
      &:hover {
        transform: scale(1.005);
        box-shadow: var(--boxShadowListingCard);
      }
    }
  }

  .singleImageContainer {
    width: 40%;
    border-radius: 5px;
    border: 1px solid #e7e7e7;
  }
  
  .threeToTwoWrapper {
    /* Layout */
    display: block;
    width: 100%;
    position: relative;
  }
  
  /* Firefox doesn't support image aspect ratio inside flexbox */
  .aspectWrapper {
    position: relative; /* allow positioning own listing action bar */
    padding-bottom: 100%;
    display: flex;
    flex-wrap: wrap;
  
    /* Image carousel can be opened from the image, therefore we should show a pointer */
    cursor: pointer;
  
    @media (--viewportMedium) {
      padding-bottom: 0; /* No fixed aspect on desktop layouts */
    }
  }
  
  .carouselModalScrollLayer {
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 1, 1, 0.92);
  }
  
  .carouselModalContainer {
    width: 100%;
    height: 100%;
  }
  
  .viewPhotos {
    @apply --marketplaceTinyFontStyles;
    font-weight: var(--fontWeightRegular);
  
    /* Position and dimensions */
    position: absolute;
    bottom: -18px;
    left: -14px;
    margin: 0;
    padding: 6px 13px 8px 13px;
  
    /* Colors */
    background-color: var(--matterColorLight);
  
    /* Borders */
    border: 1px solid #e7e7e7;;
    border-radius: var(--borderRadius);
  
    cursor: pointer;
    transition: var(--transitionStyleButton);
  }

  .sectionImages {
    width: 100%;
  }

  .attachmentsTitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .noAttachments {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    font-style: italic;
  }