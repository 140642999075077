@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.solution {
    font-size: 16px;
    flex-shrink: 0;
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
   
}

.solution textarea {
  font-size: 16px;
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.priceInput {
  flex-shrink: 0;
  width: calc(100% - 48px);
  margin: 0 24px;
  @media (--viewportLarge) {
    width: 100%;
  margin: auto;
  }
}

.infoIcon {
  vertical-align: text-bottom;
  margin-left: 6px;
}

.infoIconPlaceholder {
  margin: auto auto auto 6px
}

.submitButton {
  width: 100%;
  justify-content: flex-end;
  min-height: 40px;
  padding: 0 20px;
  margin-top:3px;
  font-size: 16px;
  @media (--viewportLarge) {
    margin-top:10px;
  }
}

.answerWrapper {
   width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (--viewportLarge) {
    justify-content: flex-end;
  }
}

.buttonWrapper {
  width: auto;
  margin: 20px 0 10px 25px;
 
  display: flex;
  justify-content: flex-end;
  @media (--viewportLarge) {
    margin: 12px 0 10px 25px;;
    width: 25%;
    margin-top: auto;
  }
}