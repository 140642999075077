@import '../../marketplace.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & li {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }

  & table,
  & th,
  & td {
    @apply --marketplaceSmallFontStyles;
    font-size: 16px;
    border: 1px solid black;
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    text-align: center;
    margin: auto;

    @media (--viewportMedium) {
      text-align: center;
      margin: auto;
    }
  }

}

.centered {
  text-align: center;
  margin: auto;
}


