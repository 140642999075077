@import '../../marketplace.css';

.itemsWrapper {
  padding: 50px 20px;
  width: 100%;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.itemRow {
  @media (--viewportMedium) {
    width: calc(50% - 16px);
  }
}

.itemContainer {
  height: 50vh;
  margin-bottom: 16px;
  overflow-y: scroll;
  border: 1px solid var(--matterColorNegative);
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 0 12px;
  margin-top: 0;
  @apply --customScrollbar;
  @media (--viewportMedium) {
    height: 100vh;
    margin-bottom: 0;
  }
}

.approveButton {
  @apply --marketplaceTinyFontStyles;
  width: 20%;
  height: 50%;
  margin-right: 0;
  margin-left: auto;
}

.approvalCard {
  @apply --marketplaceTinyFontStyles;
  box-shadow: var(--boxShadowListingCard);
  border: 1px solid var(--matterColorAnti);
  border-radius: var(--borderRadius);
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
}