@import '../../marketplace.css';

.mainWrapper {
  padding: 50px 36px 36px 36px;
}

.pageTitle {
  text-align: left;
  font-weight: 700;
}

.messageWrapper {
  margin-top: 36px;
}

.mainButtonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.createButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportForTopbar) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 20px 24px 20px 24px;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    padding: 13px 0 82px 36px;
    flex-direction: column;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    padding-right: 2px !important;
  }
}

.creatingExamMessageWrapper {
  margin-top: 20px;
}

.examsListWrapper {
  margin-top: 20px;
}