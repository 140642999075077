@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding: 0 20px;
  border-bottom: none;
  margin-bottom: 0;
  display:flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;

  @media (--viewportMedium) {
    flex-basis: 567px;
    height: 80%;
    max-height: 80%;
  }
  @media (--viewportLarge) {
    flex-basis: 60%;
  }
}
.ticketLayout {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
}
.ticketCentered {
  justify-content: center;
  align-items: center;
}

.leftColumn, .rightColumn {
  display: flex;
  flex-direction: column;
}

.leftColumn {
  width: 100%;
  padding-left: 20px;
  @media (--viewportLarge) {
    padding: 25px 30px 0px 30px;
    flex-basis: 67%;
  }
}

.rightColumn {
  width: 100%;
  
  padding-left: 20px;
  @media (--viewportLarge) {
    border-left: 1px solid var(--matterColorNegative);
    flex-basis: 33%;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
  margin-left: auto;
  margin-right: auto;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.messages {
  margin-top: 14px;
}

.editButton {
  margin: 25px auto 0;
  min-height: 50px;
  width: 75%;
}

.editIconFeather {
  margin-right: 10px;
  vertical-align: text-top;
  cursor: pointer;
} 

.noEditIcon {
  margin-right: 5px;
  font-size: 16px;
  vertical-align: text-top;
}

.attachmentsSection {
  margin-top: 20px;
}

.activitySection {
  margin-top: 25px;
}

.ticketContent {
  display:flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.ticketDetails {

  width: 100%;

}

.ticketEdit {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  &:hover {
    color: black;
    text-decoration: none;
  }
}

.minorTicketDetails {
  display:flex;
  flex-direction: row;
  margin-top: 25px;
}

.firstRow {
  display: flex;

  &:last-child {
    margin-right: 0;
  }
}

.projectTitle,
.ticketTitle,
.description,
.deliveryDate,
.descriptionTitle,
.deliveryDateTitle,
.priorityTitle,
.priority {
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    text-align: left;
  }
}

.priorityTitle,
.descriptionTitle,
.deliveryDateTitle {
  /* color: var(--matterColorAnti); */
  font-size: 15px;
}

.description {
  font-size: 16px;
  margin: 20px 0;
  line-height: 24px;
}

.priority,
.deliveryDate {
  margin-top: 4px;
  font-size: 13px;
}

.ticketTitle {
  font-size: 20px;
}

.description {
  word-wrap: anywhere;
}


.descriptionTitle {
  padding-top: 24px; 
}

.medium,
.high,
.critical,
.low,
.undefined {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-top: 14px;
  margin-right: 4px;
  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.medium {
  background-color: var(--attentionColor)
}

.high {
  background-color: var(--failColor)
}

.critical {
  background-color: var(--matterColorDark)
}

.low {
  background-color: var(--successColor)
}

.undefined {
  background-color: var(--matterColorAnti)
}

.loadingWrapper {
  margin: 20px auto;
}

.sectionTicketAuthor {
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (--viewportMedium) {
    display: flex;
    margin-top: 10px;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.sectionProgress {
  
  display: flex;
  flex-direction: row; 
 
}

.providedByTitle {
  font-size: 16px;
  @media (--viewportMedium) {
    margin-bottom: 6px;
  }
}

.ticketAvatarWrapper {
  margin-top: 6px;
  display: flex;
  grid-template-columns: 50px auto auto;
  grid-template-rows: auto auto;
  grid-template-areas: 
    "expertAvatar expertName viewExpertButton"
    "expertAvatar expertTitle viewExpertButton";
}


.ticketAvatarAspectWrapper {
  grid-area: expertAvatar;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticketAvatarDesktop {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  border-radius: 50%;
  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);
  &:hover {
    text-decoration: none;
  }
  width: 40px;
  height: 40px;
}

.ticketExpertName {
  padding-left: 10px;
  font-size: 14px;
}

.ticketExpertTitle {
  grid-area: expertTitle;
  padding-left: 10px;
  font-size: 0.8em;
}

.viewExpertButtonWrapper {
  display: grid;
  margin-right: 0;
  margin-left: auto;
}

.viewExpertButton {
  @apply --marketplaceMessageFontStyles;
  min-height: 50px;
  width: 100px;
  @media (--viewportMedium) {
    width: 180px;
  }
  @media (--viewportSmallMax) {
    font-size: 0.8em;
    line-height: 1.1em;
  }
}

.linkViewExpert {
  color: var(--matterColor);
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.calendarIcon,
.editIconFeather {
  margin-right: 5px;
  vertical-align: text-top;
  fill: none;
  display: inline;
}

.iconWrapper,
.editIconWrapper {
  display: flex;
  margin-bottom: 0;
  vertical-align: middle;
} 

.editIconWrapper {
  margin-left: auto;
  margin-right: 0;
}

.sectionsIcons {
  margin-right: 5px;
}

.prioritycritical {
  justify-content: center;
  background-color: #e2c7c7;
  color: #8b0000;
}

.priorityhigh {
  justify-content: center;
  background-color: #ffeeee;
  color: #fe5454;
}

.prioritymedium {
  justify-content: center;
  background-color: #fff4eb;
  color: #ffa14f;
}

.prioritylow {
  justify-content: center;
  background-color: #eefff3;
  color: #3fcd68;
}

.sidePanel {
  width: 100%;
  padding-right: 16px;
  @media (--viewportLarge) {
    width: 40%;
  }
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 80vh;
  padding: 50px 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalMobile {
  position: relative;
  margin-top: 50px;
  margin-left: 50px;
}

.sectionCTA {
  margin-top: 15px;
}

.noRightsToBrowse {
  color: var(--failColor);
  margin-right: auto;
  margin-left: auto;
}

.progressLabel {
  padding-top: 20px;
  font-size: 16px;
  margin: 0 0px 0 0;
}

.progressBar {
  margin-top: auto;
  margin-bottom: auto;
  width: 80%;
}

/*DROPBAR*/

.stateDropdown {
  width: 80%;
  font-size: 16px;
  margin-top: 27px;
  padding: 0px;
  color: var(--matterColorAnti);
  border-bottom-color: var(--matterColorAnti);
  padding-right: 20px;
  padding-top: 40px;

  & > option {
    color: var(--matterColor);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: small;
  }
}

/*sections*/

.sectionPriority,
.sectionDeliveryDate{
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 10px 0px 0px 0px;
}