@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 80px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
}

.footer {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.main_container {
  margin-top:30px;
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  /* margin: var(--TopbarMobileMenu_topMargin) 0 0 0; */
}

.aboutShow {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 11px;
}

.icon {
  vertical-align: baseline;
  margin-left: 5px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: #909090;

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
  padding: 8px;
  background: #efefef;
  border-radius: 5px;
  font-size:16 !important
}

.center {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.ListMobileMenu {
  margin-top:20px;

  & li:first-child {
    border-top:1px solid #c6c5c5;
  }
  & li{
    border-bottom:1px solid #c6c5c5;
    padding:12px 5px;
  }

  & a {
    color:#000;
  }
}

.navigationLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.navigationLinkSmall {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-left: 12px;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.greeting {
  margin-top:13px;
  font-size:15px;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationBrowseListings {
  /* Font */
  @apply --marketplaceH2FontStyles;

  margin-bottom: 24px;
  margin-top: 13px;
}

.authenticationLinks {
  white-space: nowrap;
}
.browseLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.jobsLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: #3d88f9;

  /* black left border */
  border-left: 5px solid #3d88f9;
  margin-left: -24px;
  padding-left: 19px;

  & a {
    color: #3d88f9;
  }
}

.footerButtonTicket {
  width:100%;
  margin-left: 5px;
}

.footerButton {
  width:100%;
}

.ctaButton {
  width:100%;
  margin: 10px;
}

.ctaLink {
  width:80%;
  margin: 10px;
  align-items: center;
}
