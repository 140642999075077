@import '../../marketplace.css';

.root {
}

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportForTopbar) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 20px 24px 20px 24px;
  overflow-x: visible;
  overflow-y: hidden;
  flex-wrap: wrap;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 24px 24px;
  }

  @media (--viewportLarge) {
    overflow-y: initial;
    padding: 13px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    padding-right: 2px !important;
  }
}

.tabs {
  margin-right: 0;
  text-align: center;
  margin: auto 0 auto auto;

  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: auto 8% auto auto;
    text-align: left;
    border-left: none;
    padding-top: 12px;
    padding-top: 16px;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.listingPanel {
  width: 100%;
  margin: 24px auto 0 auto;

  @media (--viewportMedium) {
    margin: 0 auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 0 auto 0 auto;
    max-width: 82.5vw;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 22px;
  border-bottom: solid 1px var(--matterColorNegative);

  @media (--viewportMedium) {
    margin-right: auto;
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  @media (--viewportLarge) {
    border-left: none;
    padding-bottom: 0;
  }
}


.headingTitle {
  font-size: 24px;
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.listingCards {
  padding: 20px 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 50px 36px 36px 36px;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;
  /* height: auto; */
  min-height: 200px;
  padding: 15px 15px 17px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

    /**
     * resultPanel: 62.5vw from 1024px = 640px
     * panelPaddings: - 2*36px = 72px
     *
     * columnCount: 2
     * gutterWidthBetweenColumns: 24px
     *
     * (resultPanel - (panelPaddings + gutterWidthBetweenColumns)) / columnCount = listingCardWidth
     * ergo => listingCardWidth: 272px
     *
     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */
  
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%
     *
     * resultPanel: 62.5vw from 1920px = 1200px
     * panelPaddings: - 2*36px = 72px
     *
     * columnCount: 3
     * guttersBetweenColumns: 2*24px = 48px
     *
     * (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
     * ergo => listingCardWidth: 360px
     *
     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */
    /* flex-basis: calc(33.33% - 16px);
    margin-right: 24px; */
  
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

/*.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  /*margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}

/* Navigation side bar styling*/

.navigationMenu {
  display:none;
  @media(--viewportLarge) {
    display: block;
  }
}

.navigationMenuWrapperMobile {
  display: flex;
  flex-direction: row;
  @media(--viewportLarge) {
    display: none;
  }
}

.navigationMenuMobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40%;
  justify-content: space-evenly;
  border-right: solid 1px var(--matterColorNegative);
}

.mainTab {
  margin-bottom: 8px;
  margin-right: 10px;
}

.mobileSubtabs {
  display:flex;
  width: 100%;
}

.loadingListings {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.pagination {
  display:flex;
  align-items: center;
  margin: auto;
}

.currentPage {
  /* Dimensions */
  padding: 21px 5px 18px 5px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--matterColorDark);

  /* Color */
  color: var(--matterColorDark);
}

.toPageLink {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--matterColor);

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.loadingMessage {
  margin-right: 6px;
}

.linksNavigation {
  color: var(--matterColor);
  @apply --marketplaceTabNavSmallFontStyles;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: right;
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    text-align: initial;
    text-align: none;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom-width: 0px;
    margin: 0 auto 8px 42px;
    text-align: left;
  }
}

.tooltip {
  display: none;
  @media (--viewportMedium){
    display: block;
  }
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.navigationWrapper {
  color: var(--matterColor);
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }
}

.inviteButton,
.exportCSVButton {
  width: 100%;
  @media (--viewportLarge) {
    width: 90%;
  }
}

.addServiceButton {
  margin-left: auto;
  margin-top: 40px;
  max-width: 252px;
  margin-right: auto;
}