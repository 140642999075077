@import '../../marketplace.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.mainWrapper {
  margin: 0px 12px;
  @media (--viewportMedium) {
      margin: 0px 36px;
  }
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  justify-content: center;
  @media (--viewportLarge) {
      min-width: 1000px;
      max-width: 1300px;
  }
}

.rowsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportLarge) {
      margin-right: 60px;
  }
}

.goBackButton {
  display: flex;
  align-items: center;
  max-width: 200px;
  outline:none;
  &:hover {
    text-decoration: none;
  }
}

.goBack {
  margin-top: 8px;
  /* margin-right: auto; */
  @media (--viewportMedium) {
      margin-top: 16px;
      padding: 0px 12px 0px;
  }
  @media (--viewportLarge) {
      margin-top: 50px;
      padding: 0px 36px 0px;
  }
}

.goBackIcon {
  fill: none;
  stroke: var(--marketplaceColor);
  margin-right: 4px;
}

.firstRow {
  display: flex;
  flex-direction: column;
  margin: 50px 0px;
  align-items: center;
  border-bottom: solid 0.5px #d4d4d4;
  padding-bottom: 30px;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
  }
  @media (--viewportLarge) {
    padding: 12px 36px 24px;
  }
}

.mainContentWrapper {
  /* flex-basis: 100%; */
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;
  @media (--viewportMedium) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 433px); */
    /* flex-basis: calc(100% - 433px); */
    /* flex-shrink: 0; */
    flex-grow: 1;
    align-items: flex-start;
  }
}  

.title {
  margin-bottom: 19px;
  @media (--viewportLarge) {
    margin-bottom: 18px;
    padding: 1px 0 7px 0;
  }
}

.secondRow {
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: center;
  padding: 0;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 12px;
  }
  @media (--viewportLarge) {
    padding: 0 36px;
  }
} 

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 60vh;
  height: fit-content;

  background-color: var(--matterColorBright);
  padding-bottom: 10px !important;
  margin-bottom: 0;
}

.modalTextWrapper {
  padding: 1vh 1vw;
  font-size: 0.8em;
  margin-bottom: 3vh;
}

@media (--viewportMediumMax) {
  .modalContainer {
    height: 100% !important;
  }
  .infoAcceptButton {
    position: relative;
    margin-bottom: 10px;
  }
}

.fileField {
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 5px;

    &:hover {
            cursor: pointer;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 34%);
    transition: all 0.3s;

    }
}

.fieldTitle {
    padding: 8px;
    display: block;
    margin-bottom: 5px;
}

.hideField {
  display:none;
}
