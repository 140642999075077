@import '../../marketplace.css';

.root {
  @apply --marketplaceChipStyles;
  margin-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.primaryChipRoot {
  @apply --marketplaceChipStylesPrimary;
  margin-right: 10px;
}

.secondaryChipRoot {
  @apply --marketplaceChipStylesSecondary;
  margin-right: 10px;
}

.highlightedChipRoot {
  @apply --marketplaceChipStylesHighlighted;
  margin-right: 10px;
}

.defaultChipRoot {
  @apply --marketplaceChipStyles;
}

.primaryChip,
.secondaryChip,
.highlightedChip,
.defaultChip {
  /* Class handle for chip state styles */
  margin-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}