@import '../../marketplace.css';

.mainContainer {
  flex: 1;
  flex-direction: row;

  @media (--viewportLarge) {
    margin-right: 56px;
  }
}

.chatLoadMoreContainer {
  /* margin-top: 20px; */
  border: 1px solid var(--matterColorNegative);
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(200, 200, 200, 0.1);
  padding: 8px 0;
}

.chatLoadMore {
  min-width: 208px;
  padding: 12px 28px;
  border-radius: 5px;
  /* background-color: rgba(0,0,0,0.03); */
  background-color: #ffffff;
  box-shadow: var(--boxShadowButton);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.refreshSpinnerx {
  max-width: 21px;
  max-height: 21px;
  stroke: var(--matterColorAnti);
  stroke-width: 3px;
}

.chatContainer {
  /* margin-top: 12px; */
}

.centeredSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headingWrapper {
  display: flex;
  margin: 18px 0 0 0;
  @media (--viewportMedium) {
    margin: 24px 0 0 0;
  }
}

.headingButtonWrapper {
  margin: 6px 0 0 10px;
  height: 24px;
  padding: 4px 12px;
  border-radius: 5px;
  /* background-color: rgba(0,0,0,0.03); */
  background-color: #ffffff;
  box-shadow: var(--boxShadowButton);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
}

.groupEditWrapper {
  margin: 7px 0 14px 0;
}

.selectButtonWrapper {
  display: flex;
  width: 200px;
  margin-top: 10px;
}

.groupEditSubmitButton {
  min-height: 30px;
  height: 30px;
  width: 120px;
  font-size: 14px;
}

.groupEditSpinner {
  margin-left: 6px;
}

.headingTitle {
    @apply --marketplaceH1FontStyles;
    padding: 5px 0 1px 0;
  
    @media (--viewportMedium) {
      max-width: 80%;
      padding: 1px 0 7px 0;
    }
}

.messageItem {
  margin-bottom: 20px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.sendMessageForm {
  position: relative;
  margin: 30px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 30px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 30px 0 0 0;
  }
}

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportForTopbar) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 20px 24px 20px 24px;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    padding: 13px 0 82px 36px;
    flex-direction: column;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    padding-right: 2px !important;
  }
}

.goBackIcon {
  fill: none;
  stroke: var(--marketplaceColor);
  margin-right: 4px;
}

.goBackButton {
  display: flex;
  align-items: center;
  margin-top: 12px;
  max-width: 200px;
  outline:none;
  &:hover {
    text-decoration: none;
  }
}

.messagesBox {
  height: 50vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid var(--matterColorNegative);
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 0 12px;
  margin-top: 0;
  @apply --customScrollbar;
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}