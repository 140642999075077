@import '../../marketplace.css';

.root {
}

.input {
  @apply --marketplaceEnquiryFontStyles;
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  height: auto;
}

.description {
  height: 50px;
}

.offer {
  height: 100px;
}

.labelWithTooltip {
  display: flex;
  flex-direction: row;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}