@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingMessages {
  padding: 5px 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 5px 36px 96px 36px;
  }
}

.ticketMessage {
  margin-bottom: 36px;
  margin-right: 20px;
  padding: 20px;
  background-color: var(--matterColorLight);
}

.conversationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 16px;
  /* color: var(--matterColorAnti); */

  margin-top: 0;
  margin-bottom: 5px;
}

.ticketSolved {
  margin-top: 10px;
  text-align: center;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e5e5e5;
}

.subscriptionNotActive {
  color: var(--failColor);
}

.showMoreButton {
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}

.noAnswers {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  font-style: italic;
}