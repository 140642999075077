@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
    margin-bottom: 33px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  color: var(--marketplaceColor);
  margin-bottom: 20px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.desktopBudgetValue {
  /* Font */
  color: var(--marketplaceColor);
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.noExpertProfile {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--failColor);
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.pricingInfo {
  margin-top: 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
    margin-left: 6px;
  }
}

.budget {
  @apply --marketplaceMessageFontStyles;
  margin: auto;
  text-align: center;
  flex-grow: 0;
  width: 50%;
}

.pricing {
  @apply --marketplaceMessageFontStyles;
  margin: auto;
  text-align: center;
  flex-grow: 0;
  width: 50%;
}

.border {
  border-left: 1px solid var(--matterColorNegative);
  padding-bottom: 10px;
}

.bookingTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 9px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.contact {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 186px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 14px 0 14px 0;
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.acceptOfferButton {
  margin-top: 10px;
}

.signupButton {
  margin-top: 10px;
  margin-bottom: 5px;
}

.adminOfferButton,
.contactAdminButton {
  margin-top: 10px;
  margin-bottom: 8px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactAdminButtonClosed {
  margin-top: 80px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupLink:hover {
  text-decoration: none;
}

.contactAdminLink:hover {
  text-decoration: none;
}

.rejectButton {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: darkred;
}

.rejectButton:hover {
  background-color: rgb(100, 0, 0);
}

.toggleButton {
  margin-top: 5px;
  background-color: darkblue;
}

.toggleButton:hover {
  background-color: rgb(0, 0, 100);
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  margin-top: 20px;
  /* Clear padding that is set for link elements looking like buttons */
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 10px;
    margin-bottom: 21px;
  }
}

.smallPrintStripeNeeded {
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.stripeConnectButton {
  margin: auto;
  @media (--viewportLarge) {
    display: block;
  }
}

.bookMeetingLink:hover {
  text-decoration: none;
}

.bookMeetingButton {
  margin-top: 12px;
}

.priceExplanation {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: left;
  margin: auto 12px 12px 12px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 12px;
  }

  @media (--viewportLarge) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

/* New Design */

.bookingForm { 
  padding: 8px;
}

.messageIcon { 
  fill: none;
  margin-right: 15px;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.customersWrapper {
  /* TEMPORARY */
  /* display: none; */
  /* TEMPORARY */
  max-width: 350px;
  border-top: 1px solid var(--matterColorNegative);
  padding-top: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dollarSignIcon { 
  fill: none;
  margin-right: 8px;
}

.buyServiceButton {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminOfferValuesTitleWrapper {
  margin-top: 5px;
  margin-bottom: 5px;
}

.adminOfferValuesTitle {
  font-size: 16px;
  vertical-align: middle;
}

.adminOfferValues {
  font-size: 13px;
  font-weight: 400;
}

.offersContainer {
  margin-top: 10px;
  margin-bottom: 8px;
}