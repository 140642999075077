@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.description {
  font-size: 16px;
  margin-top: 32px;
}

.contactButton {
  width: 200px;
  min-height: 50px;
  max-height: 50px;
  margin-right: 20px;
}

.buttonLink {
  font-size: 16px;
  color: var(--matterColor);
  &:hover {
    text-decoration: none;
  }
}

.buttonIcon {
  margin-right: 5px;
  vertical-align: text-top;
}