@import '../../marketplace.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  /* background-color: var(--matterColorLight); */
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    padding: 13px 0 82px 36px;
    flex-direction: column;
    overflow-x: visible;
    /* background-color: var(--matterColorBright); */
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    min-width:40px;
    padding-right: 2px !important;
  }
}

.navigationNone {
  display: none !important;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  margin-bottom: 24px;
}

.threeToTwoWrapper,
.threeToTwoWrapperJob {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper,
.aspectWrapperJob {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }

  & img {
    width: 23%;
    max-height: 264px;
  }

  @media (max-width: 756px) {
    & img {
      width: 100%;
      max-height: 264px;
    }
  }
}

.actionBar {
  & span {
    background: #fafafa;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.isExpertVerifiedNoBottomMargin {
  margin-bottom: 0px !important;
}

.closedListingText {
  font-size: 1rem;
  color: #4b4b4b;
}

.editListingLink {
  &:hover {
    text-decoration: none;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.singleIcon {
  margin: 0px 8px;
}

.verifiedIcon {
  display: inline-block;
  margin-left: 5px;
  text-align: center;
  color: var(--successColor);
  overflow: auto;
  font-size: 20px;
}

.rootForImage,
.rootForImageJob {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    height:100%;
    right: auto;
    /* height: calc(0.41 * 100vw); */
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightRegular);

  /* Position and dimensions */
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.d-flex {
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.containerPadding {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.SeeMorePhotos {
  text-align: right;
  padding: 1rem;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  width: 100%;
  margin: 0px 0px;
  @media (--viewportMedium) {
    /* max-width: 1056px; */
    margin: 0px 0px;
    padding: 0 0px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    margin: 0px 0px 117px;
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    padding: 0;
    margin: 135px 0px 117px;
  }
}

.contentContainerOuter {
  width: 100vw;
  padding: 0px 12px;
  flex-direction: column;
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0px 0px;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0px auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
    margin: 0px auto 117px;
  }
}

.mainContent {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px); */
    /* max-width: calc(100% - 312px);
    flex-basis: calc(100% - 312px);
    flex-shrink: 0; */
    flex-grow: 1;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);
  @media (--viewportLarge) {
    max-width: 332px;
    padding-left: 36px;
  }
  @media (--viewportLargeWithPaddings) {
    padding-left: 59px;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    height: fit-content;
    display: block;
    /* margin-top: 124px; */
    padding: 12px 0px;
    /* border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative); */

    /* The calendar needs 312px */
    /* flex-basis: 312px; */
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  flex-grow: -1;
  display: inline-flex;
  justify-content: center;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  flex-direction: row;
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  /* display: none; Hide the large avatar from the smaller screens */
  display: flex;
  width: 190px;
  height: 190px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 30px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  flex-grow: 1;
  /* margin-top: 10px; */
  flex-direction: row;
  display: inline-flex;
  margin-bottom: 5px;
  max-width: 83%;
  /* @media(--viewportLarge) {
    margin-top: 30px;
  } */
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 12px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: inline;
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.sectionDescription,
.sectionBenefits {
  padding: 0 8px;
  margin-bottom: 24px;
  font-size: 16px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 32px;
  }
}

.sectionExperience {
  padding: 0 8px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.sectionExperienceAndProjects {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    margin-top: 10px;
  }

  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.sectionVerifiedSoftwaresWrapper {
  margin-top: 16px;
}

.sectionJobLinks {
  margin-bottom: 12px;
}

.sectionPortfolio {
  padding: 0 8px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 32px;
  }
}

.descriptionTitle,
.benefitsTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
}

.plusButton {
  display: inline-block;
  cursor: pointer;
}

.plusButtonErrorMessage {
  color: darkred;
  font-size: 0.8em;
  text-align: center;
}

.descriptionAnnotation {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
}

.portfolioTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
}

.sectionCompany {
  padding: 0 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 32px;
    align-items: flex-start;
  }
}

.companyHeading {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-size: 16px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 13;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 13;
    text-align: left;
  }
}

.companyTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
}

.sectionFeatures {
  padding: 0 8px;
  margin-bottom: 32px;

  & ul {
    display: flex;
    gap: 12px;
    flex-wrap:wrap;
  }

  & li {
    background: #f1f3f6;
    padding: 8px 10px;
    border-radius: 8px;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 32px;
  }
}

.sectionSkills {
  padding: 0 8px;
  margin-bottom: 32px;

  & ul {
    display: flex;
    gap: 12px;
    flex-wrap:wrap;
  }

  & li {
    background: #edf3fd;
    padding: 8px 10px;
    border-radius: 8px;
    color: #508cf0;
  }

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 32px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
}

.skillsTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
}

.imagesTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description,
.portfolio,
.company {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.experienceAndProjects {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 8px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 10;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    margin-top: 10;
    margin-bottom: 10px;
  }
}

.address {
  font-size: 16px;
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.modalOfferRejectedWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalExpertProfileMissingWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */

  max-height: 240px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.modalBooking {
  @media (--viewportMedium) {
    display: none;
  }
  @media (--viewportLarge) {
    display: none;
  }
  @media (--viewportXLarge) {
    display: none;
  }
}

.reminder {
  margin-bottom: 10px;
}

.goBackButton,
.goToProjectBoardButton {
  display: flex;
  align-items: center;
  max-width: 200px;
  outline: none;
  &:hover {
    text-decoration: none;
  }
}

.goToProjectBoardButton {
  margin-right: 0;
  margin-left: auto;
}

.sectionShare {
  padding: 0;
  margin-bottom: 35px;
  @media (--viewportMedium) {
    margin-top: 52px;
    margin-left: 0;
  }
}

.shareContent {
  width: 278px;
  min-height: 52px;
  border-radius: var(--borderRadius);
  padding: 17px 30px;
  margin: 14px auto 0 auto;
  background-color: var(--marketplaceColorVeryLight);
  border: 1px solid var(--matterColorAnti);
  display: flex;
  flex-direction: row;
}

.shareTitle {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  font-size: 14px;
  color: var(--matterColor);
  margin: 0 14px 0 0;
}

.shareButtonsPanel {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.shareButton {
  width: calc(25% - 3px);
}

.shareIcon:hover {
  color: var(--marketplaceColor);
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.smallPrintHeading {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-bottom: 6px;
  }
}

.jobLink:hover {
  text-decoration: none;
}

.jobLinkMobile {
  min-width: 225px;
  @media (--viewportMedium) {
    display: none;
  }
}

.jobLinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.experiencedChip {
  align-self: flex-start;
  display: inline-block;
}

.chipWrapper {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

/* New Design */

.mainWrapper {
  /* margin: 0px 12px;

  @media (--viewportMedium) {
    margin: 0px 36px;
  } */
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  justify-content: center;

  @media (--viewportLarge) {
    flex-direction: row;
    /* min-width: 1000px; */
    /* max-width: 1300px; */
  }
}

.rowsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportLarge) {
    margin-right: 60px;
  }
}

.recommendationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportMedium) {
    align-items: flex-start;
  }
}

.goBack {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  /* margin-right: auto; */

  @media (--viewportMedium) {
    margin-top: 16px;
    padding: 0px 12px 0px;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    padding: 0px 36px 0px;
  }
}

.goBackIcon {
  fill: none;
  stroke: var(--marketplaceColor);
  margin-right: 4px;
}

.firstRow {
  display: flex;
  flex-direction: column;
  margin: 50px 0px 0px;
  align-items: center;
  padding-bottom: 30px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
  }

  @media (--viewportLarge) {
    padding: 12px 36px 24px;
  }
}

.secondRow {
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: center;
  padding: 0;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 12px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.sectionAvatarAndLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-right: 50px;
    margin-bottom: 52px;
  }
}

.sectionAvatarAndLinksJob {
  display: none;
}

.sectionProfileLinks {
  padding: 0 24px;
  margin-top: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 24px;
  }
}

.sectionSideActionBar {
  display: flex;
  flex-direction: column;
}

.sectionCTA {
  display: none;
  @media (--viewportLarge) {
    display: block;
    min-width: 272px;
  }
}

.sectionCTAMargin {
  margin-top: 20px;
}

.mainContentWrapper {
  /* flex-basis: 100%; */
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;

  @media (--viewportMedium) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    /* max-width: calc(100% - 433px); */
    /* flex-basis: calc(100% - 433px); */
    /* flex-shrink: 0; */
    flex-grow: 1;
    align-items: flex-start;
  }
}

.sectionExpertName {
  flex-grow: 1;
  align-self: center;
  display: inline-flex;
  margin-bottom: 5px;
  @media (--viewportMedium) {
    align-self: flex-start;
  }
}

.sectionExpertise {
  margin-bottom: 30px;
  line-height: 42px;
  padding-top: 1rem;
  margin-bottom: 16px;

  &media( max-width: 756px) {
    margin-top: 2rem;
  }
}
/* 
.sectionSoftwares {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.sectionSkillsChip {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }
} */

.experienceAndProjectsWrapper {
  @media (--viewportMedium) {
    margin-right: 32px;
  }
}

.sectionTags,
.sectionTagsWithMargin {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.sectionTagsWithMargin {
  margin-top: 8px;
}

.sectionBudget {
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportMedium) {
  }
}

.sectionCompany {
  width: 100%;
  margin-bottom: 20px;
}

.expertName {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  text-align: center;

  /* Layout */

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    @apply --marketplaceH2FontStyles;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.companyName {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Layout */

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    @apply --marketplaceH2FontStyles;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.expertise {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 36px;
    text-align: left;
  }
}

.icon {
  margin-right: 6px;
  fill: none;
  width: 20px;
  height: 20px;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    width: 25px;
    height: 25px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 0px;
  @media (--viewportMedium) {
    margin-right: 18px;
    align-items: flex-start;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  text-align: right;
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 6px;

  @media (--viewportMedium) {
    font-size: 1rem;
    font-weight: bold;
    line-height: 48px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValueUnset {
  text-align: right;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 1rem;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-size: 16px;
  text-align: center;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

.aspectWrapper {
  width: 189px;
  height: 126px;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 189px;
  height: 126px;
  border-radius: var(--borderRadius);
}

.editButton {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 225px;
  margin-bottom: 8px;
  @media (--viewportLarge) {
    width: 100%;
  }
}

.getFrameButton {
  background-color: white;
  color: rgb(74, 74, 74, 0.9);
  border: 1px solid #e7e7e7;
  &:hover {
    background-color: white;
    color: rgb(74, 74, 74, 0.9);
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  }
}

.editIconFeather {
  fill: none;
  margin-right: 15px;
}

.closeIcon {
  vertical-align: top;
  margin-right: 15px;
}

.externalLinkIconFeather {
  fill: none;
  margin-right: 15px;
}

.briefcaseIcon {
  fill: none;
  margin-right: 8px;
}

.postJobLink:hover {
  text-decoration: none;
}

.postJobButton {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 225px; */
}

.infoIconWrapper {
  display: inline-block;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.newListingLink:hover {
  text-decoration: none;
}

.chip {
  margin: 4px 10px 4px 0px;
}

.ticketInfo {
  display: flex;
  margin-bottom: 8px;
  flex-direction: column;
}

.additionalTicketInfo {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  @media (--viewportMedium) {
    font-size: 15px;
  }
}

/* ---------- SERVICE PAGE ---------- */
.sectionPriceTime {
  display: flex;
  @media (--viewportMedium) {
    display: flex;
    margin-top: 10px;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.priceTimeValue {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    justify-content: flex-start;
  }
}

.sectionServiceAuthor {
  display: flex;
  width: 100%;
  @media (--viewportMedium) {
    display: flex;
    margin-top: 10px;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.sectionServiceAuthor {
  display: flex;
  flex-direction: column;
}

.serviceAvatarWrapper {
  margin-top: 6px;
  display: flex;
  grid-template-columns: 50px auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'expertAvatar expertName viewExpertButton'
    'expertAvatar expertTitle viewExpertButton';
}

.serviceAvatarAspectWrapper {
  grid-area: expertAvatar;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supportPlanProvider {
  display: flex;
  padding: 10px 0 0 0;
  font-size: 1em;
}

.serviceExpertName {
  grid-area: expertName;
  padding-left: 10px;
  font-size: 0.7em;
}

.serviceExpertTitle {
  grid-area: expertTitle;
  padding-left: 10px;
  font-size: 0.8em;
}

.serviceAvatarDesktop {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  border-radius: 50%;
  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);
  &:hover {
    text-decoration: none;
  }
  width: 40px;
  height: 40px;
}

.serviceInitialsDesktop {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  @media (--viewportMedium) {
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
  }
}

.serviceExpertLink {
  color: var(--matterColor); /* Loading BG color */
  &:hover {
    text-decoration: none;
  }
}

.serviceScopeTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 13px;
  }
}

.sectionServiceScopeWrapper {
  display: flex;
  flex-direction: column;
}

.serviceScopeContainer {
  display: flex;
  flex-direction: column;
}

.scopeItemWrapper {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.scopeItemIcon {
  width: 10%;
}

.scopeItemTask {
  width: 60%;
}

.scopeItemTaskWide {
  width: 80%;
}

.scopeItemTaskFull {
  width: 90%;
}

.scopeItemTime {
  width: 18%;
}

.scopeItemPrice {
  width: 12%;
}

.scopeItemSummaryWrapper {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.scopeItemSummaryTitle {
  width: 18%;
  font-weight: var(--fontWeightMedium);
}

.scopeItemSummaryPrice {
  width: 12%;
  font-weight: var(--fontWeightMedium);
}

.scopeSmallPrintImportant {
  color: #555555;
}

@media (--viewportMediumMax) {
  .scopeItemSummaryWrapper,
  .scopeItemWrapper {
    font-size: 0.8em;
  }
  .scopeItemIcon {
    width: 7%;
  }
  .scopeItemTask {
    width: 53%;
  }
  .scopeItemTime {
    width: 25%;
    text-align: center;
  }
  .scopeItemPrice {
    width: 15%;
    text-align: center;
  }
  .scopeItemSummaryTitle {
    width: 25%;
  }
  .scopeItemSummaryPrice {
    width: 15%;
  }
}

@media (--viewportSmallMax) {
  .scopeItemSummaryWrapper,
  .scopeItemWrapper {
    font-size: 0.8em;
  }
}

.viewExpertButtonWrapper {
  display: grid;
  margin-right: 0;
  margin-left: auto;
}

.viewExpertButton {
  @apply --marketplaceMessageFontStyles;
  min-height: 50px;
  width: 100px;
  @media (--viewportMedium) {
    width: 180px;
  }
  @media (--viewportSmallMax) {
    font-size: 0.8em;
    line-height: 1.1em;
  }
}

.linkViewExpert {
  outline: none;
  text-decoration: none;
  margin-left: 5%;
  margin-right: 5%;
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

.dates {
  color: var(--matterColorAnti);
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 0;
}

.dateValue {
  color: var(--matterColor);
}

.viewRecommendations {
  margin-left: 8px;
}

.addServiceModalText {
  font-size: 18px;
  margin-bottom: 24px;
}

.linkStyled {
  @apply --marketplaceLinkStyles;
}

.offerSectionTitle {
  margin-bottom: 20px;
}

.offersContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 25px 0;
}

.header_gray_color {
  color: #999999 !important;
}

.offerCard {
  box-shadow: var(--boxShadowLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius);
  padding: 10px 10px 0;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--matterColorLight);
    border-color: var(--matterColorAnti);
    box-shadow: var(--boxShadowButton);
  }
}

.offerCardFirstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.offerCardInfo {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}

.offerCardAuthorName {
  margin: 0 0 16px 16px;
  width: 100%;
  font-size: 20px;
  text-align: left;
}

.avatarOffer {
  width: 60px;
  height: 60px;
  min-width: 60px;
}

.offerCardSecondRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.offerDate {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  display: block;
  margin: 10px 0 0;
}

.offerPriceValue {
  font-size: 16px;
  color: #5ad68e;
  text-align: right;
}

.noOffers {
  font-size: 16px;
  color: var(--matterColorAnti);
  font-style: italic;
}

.openProjectBoardIcon {
  color: white;
}

.availableExperts {
  margin-bottom: 20px;
  text-align: left;
  font-size: 0.875rem;
  color: #727171;
}

.adminOfferValuesTitleWrapper {
  margin-top: 5px;
  margin-bottom: 5px;
}

.adminOfferValuesTitle {
  font-size: 1.125rem;
  vertical-align: middle;
  color: #000;
}

.adminOfferValues {
  font-size: 13px;
  font-weight: 400;
  background: #fafafa;
  padding: 12px;
  border-radius: 8px;
}

.offersContainer {
  margin-top: 10px;
  margin-bottom: 8px;
}

.project_info {
  @media (max-width: 756px) {
    flex-wrap: wrap;
  }
}

.projectInfoItem {
  border-right: 1px solid #949494;
  padding: 2px 24px;

  @media (max-width: 756px) {
    width: 50%;
    padding: 2px 0px;
    border: 0px;
  }
}

.projectInfoItem:first-child {
  padding-left: 0px;
}
.projectInfoItem:last-child {
  border-right: 0px solid #949494;
}

.devider {
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
}

.rightColumnNew {
  background-color: #f1f3f6;
  padding-top: 2rem;
  padding-bottom: 1rem;
  @media (max-width: 756px) {
    order: 1;
    display: none;
  }
}

.NewContainerStyle {
  padding-right: 0px;

  @media (max-width: 756px) {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0px;
  }
}
.mobileOrder {
  @media (max-width: 756px) {
    order: 2;
  }
}

.MenuButton {
  background: #fff;
  width: 100%;
  padding: 1rem;
  font-size: 18px;
  text-align: left;
  border-radius: 8px;
}

.MenuContainer {
  position: relative;
}

.MenuDrop {
  padding: 1rem;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
  z-index: 10;
  position: absolute;
  width: 100%;
}

.ProjectTitleHeader {
  font-size: 18px;
  color: #000;
  margin-bottom: 1rem;
}

.benefits{
  padding:15px;
  background: #FAFAFA;
  border-radius: 4px;
}

.benefitsHeader {
  font-size:16px;
  font-weight:500;
  text-align: left;
}

.benefitsList {
  margin-top:10px;

  & li {
    font-size:14px;
    color:#727171;
    padding-bottom:3px

  }

    & img {
      margin-top:-3px;
      margin-right:5px;
    }
}



