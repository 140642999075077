@import '../../marketplace.css';

.root {
}

.input {
  @apply --marketplaceEnquiryFontStyles;
  border-bottom-color: var(--successColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  height: auto;
}

.description {
  /* height: 50px; */
}

.offer {
  height: 100px;
}

.labelWithTooltip {
  display: flex;
  flex-direction: row;
}