@import '../../../marketplace.css';

.mainWrapper {
  width: 100%;
  display: flex;
}

.formWrapper {
    width: 100%;
}

.answerInputWrapper,
.answersLabelsBar {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 8fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
      "spaceHolder textInput checkbox buttons";
}

.answersLabelsBar {
    margin-top: 26px;
}

.spaceHolder {
    grid-area: spaceHolder;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox {
    grid-area: checkbox;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.answerInput {
    grid-area: textInput;
    width: 100%;
}

.sideButtonsWrapper {
    grid-area: buttons;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.buttonWrapper {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.createButton {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);

    padding: 0 16px;
    min-height: auto;
    min-width: 150px;
    height: 41px;
  
    display: inline-block;
    margin: 26px 0 0 0;
    width: auto;
}

.hookInputWrapper {
    margin-top: 10px;
}

.errorText {
    color: var(--failColor);
    font-size: 14px;
    margin: 4px 0 0 0;
}

.centered {
    text-align: center;
}