@import '../../../marketplace.css';

.mainWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}
  
.priceWrapper {
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
    margin-top: 10px;
}

.timeWrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 10px;
}

.title {
    /* Font */
    @apply --marketplaceH3FontStyles;
    margin-top: 0;
    margin-bottom: 10px;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 10px;
    }
}
  
.contentContainer {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    color: var(--marketplaceColorLight);
}