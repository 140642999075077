@import '../../marketplace.css';

.modalMessage {
    @apply --marketplaceModalParagraphStyles;
    margin-bottom: 10px;
  }

.submitButton {
    margin-top: 20px;
    border-radius: 5px;
}