@import '../../../marketplace.css';

.questionTitle {
    @apply --marketplaceH3FontStyles;
    color: var(--matterColor);
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 10px 0;
}

.questionAnswers {
    display: flex;
    flex-direction: column;
}

.answerWrapper {
    display: flex;
    flex-direction: row;
}

.answerTitle {
    margin-left: 5px;
    @apply --marketplaceH4FontStyles;
    color: var(--matterColor);
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 10px 0;
}

.defaultButton {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightMedium);
  
    float: right;
    padding: 0 16px;
    min-height: auto;
    min-width: 150px;
    height: 41px;
  
    display: inline-block;
    margin: 17px 0 0 0;
    width: auto;
}

.defaultButton:active,
.defaultButton:focus {
    background-color: var(--successColor);
    box-shadow: none;
}