@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;


  @media (--viewportMedium) {
    flex-basis:80vw;
    padding:15px;
    margin-top:2rem;
    max-width:1150px; 
  }
}

.modalContent {
  flex-grow: 1;
  @media (max-width: 281px) and (orientation: portrait) { 
    width:85%;
  }
}

.spanStrong {
  font-weight: bold;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 40px;
  text-align: center;
  font-size:1.4rem;
}

.modalTitle__black {
  color:#000;
  font-size:2rem;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  text-align: center;
  line-height: 1;
  padding:0 0rem;
}

.modalMessage p {
  font-size:0.875rem;
  margin:0px;
  line-height: 1.4;
  margin-bottom:10px;
  margin-right: 10px;
  margin-left: 10px;
}
.stepsContainer {
  display: flex;
  flex-direction: row;
  margin:0 auto;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom:0.625rem;
    width: 70%;
  }
}

.step {
  /* width: 140px; */
  margin-bottom: 10px;
  position:relative;



  @media (--viewportMedium) {
    display: block;
  }
}

.pointedLine {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;

}

.point{
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: var(--matterColorAnti);
  position:relative;
}

.point > svg {
  fill: #fff;
  position: absolute;
  left: 0;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 1.5rem;
  width:80%;
}


.pointCompleted {
  background-color: var(--marketplaceColor);
}


.pointCurrent {
  background-color: var(--marketplaceColor);
  height: 32px;
  width: 32px;
}

.lineright {
  &:after {
    content: "";
    display: inline-block;
    height: 2px;
    width: 35%;
    position: absolute;
    right: 0px;
    background: #b2b2b2;
  } 
}

.lineleft {
  &:before {
    content: "";
    display: inline-block;
    height: 2px;
    width: 35%;
    position: absolute;
    left: 0px;
    background: #b2b2b2;
  } 
}

.lineboth {
  &:after {
    content: "";
    display: inline-block;
    height: 2px;
    width: 35%;
    position: absolute;
    right: 0px;
    background: #b2b2b2;
  }
  
  &:before {
    content: "";
      display: inline-block;
      height: 2px;
      width: 35%;
      position: absolute;
      left: 0px;
      background: #b2b2b2;
  }
}

.lineStatusCurrent {
  &:before { 
    background: #3c88f8; 
  }
}

.lineStatusComplete {
  &:before { 
    background: #3c88f8 !important;
  }
  &:after {
    background: #3c88f8 !important;  
  }
}


.pointCurrent > svg {
  font-size: 2rem;
}

.marginBottom {
   margin-bottom: 15px;
}

.line {
  width: 90px;
  height: 2px;
  background-color: var(--matterColorAnti);
  margin-left: 5px;
  display: none;
  @media (--viewportMedium) {
    display: block
  }
}

.nModalTitle {
  font-size:1.4rem;
  text-align: center;
  font-weight: bold;
}

.lineCompleted {
  background-color: var(--marketplaceColor);
}

.stepTitle {
  color: var(--matterColorAnti);
  font-size: 0.675rem;
  text-align: center;

  @media (--viewportMedium) {
    margin-left: 0;
    margin-top: 5px;
  }
}

.stepTitleCurrent {
  color: var(--matterColor);
  /* color: var(--marketplaceColor); */
}

.mainInfo {
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.contactTitle {
  font-size: 16px;
  margin-top: 32px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.contactButton {
  width: 200px;
  min-height: 50px;
  max-height: 50px;
  margin-right: auto;
  margin-left:auto;
  margin-top:20px;
  margin-bottom: 10px;
}

.buttonLink {
  font-size: 18px;
  color: var(--matterColor);
  &:hover {
    text-decoration: none;
  }
}

.buttonIcon, .infoIcon, .bellIcon {
  vertical-align: text-top;
}

.buttonIcon {
  margin-right: 5px;
}

.bigButton {
  width: 275px;
  margin: 0 auto 20px auto;
}

.infoIcon {
	margin-left: 5px;
}

.tooltipFont {
  font-size: 20px;
}

.jobLink:hover,
.jobLinkDisabled:hover {
  text-decoration: none;
}

.jobLinkDisabled {
  pointer-events: none;
}

.modalTextBox {
text-align: justify;
line-height: 1.5;
margin-bottom:40px;
}

.bottonMargin{
  margin-bottom: 20px;
}

.invitationSection {
  background: rgba(80, 140, 240, 0.05);
  padding:35px 90px;
  width: 114%;
  margin-left: -7%;
}

.ModalTextFormat {
  color:#000;
  font-size:1rem;
  margin-top:10px;
  margin-bottom:35px;
}

.buttonHollow {
  font-size: 18px;
  background: transparent;

  border: 1px solid #727171;
  border-radius: 8px;
  

  color:#727171;
  padding:18px 24px;
  transition: all 0.3s;
}

.buttonHollow:hover {
  background: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
  transition: all 0.3s;
}





.icons {
  text-align: center;
}
.icons > svg {
  fill:#ECECEC;
}

.smallTextFormat {
  text-align: center;
  font-size:1.1rem;
  padding-left:20px;
  padding-right:20px;
  margin-bottom: 1rem;
}

.buttonGreen {
  color: #62C87A;
  border-color: #62C87A;
  font-size: 1.175rem;
  background: #fff;
  width: 100%;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  padding: 15px 0px;
}
.iconContainer {
  margin-top:1.875rem;
  margin-bottom:1rem;



}

.modalContactSection {
  border-top:1px solid #727171;
  width:90%;
  margin:0 auto 0 auto;
  @media (--viewportMedium){
    width:60%;
  }

  @supports (-webkit-touch-callout: none) {
    padding-bottom:6rem; 
  }
}

.modalMessageStyling{
  text-align: justify;
  padding: 0px 40px;
  line-height: 1.2;
  margin-bottom:2rem;

  @media (max-width: 280px) and (orientation: portrait) { 
    padding: 0px 10px;
  }
}

.iconSubText {
  text-align: center;
  font-size:1rem;
  margin-bottom:1rem;
}

.iconBold {
  font-weight: 800 !important;
}
.iconDesc {
  font-size:0.8rem;
  @media (--viewportMedium){
    font-size:0.875rem;
  }
}

.modalContent img {
  width:100%;
}

.ModalSideImage {
  max-height: 800px;
  max-width: 600px;
}

.showOnMobile {
  @media (--viewportMedium){ 
    display:none;
  }
}

.HideOnMobile {
  display:none;



   @media (min-width: 1181px) and (orientation: landscape) { 
    display:flex;
  } 

  @media (min-width: 1025px) and (orientation: portrait)
  {
    display:flex; 
  } 

  @media (max-width: 1253px)
  {
    display:none; 
  }
}

.HideOnMobile img {
  display: flex;
}

.borderRight {
  border-right: 1px solid #e6e6e6;
  height: 100%;
  padding: 0 1rem 0 0;
  align-items: center;
  justify-content: center;
}

