@import '../../../marketplace.css';

.buttonWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.titleWrapper {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 10px 0;
}

.rulesWrapper {
  margin-top: 20px;
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 10px 0;
}

.examTitle {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 10px 0;
}