@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}


