@import '../../marketplace.css';

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportForTopbar) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 20px 24px 20px 24px;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;
  flex-wrap: wrap;

  @media (--viewportLarge) {
    padding: 13px 0 82px 36px;
    flex-direction: column;
    background-color: var(--matterColorLight);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    padding-right: 2px !important;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 22px;
  border-bottom: solid 1px var(--matterColorNegative);

  @media (--viewportMedium) {
    margin-right: auto;
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  @media (--viewportLarge) {
    border-left: none;
    padding-bottom: 0;
  }
}

.tabs {
  margin-right: 0;
  text-align: center;
  margin: auto 0 auto auto;

  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: auto 8% auto auto;
    text-align: left;
    border-left: none;
    padding-top: 12px;
    padding-top: 16px;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 0;
  padding: 0;
  @media (--viewportMedium) {
    padding-top: 50px;
  }
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
}

.item {
  display: flex;
  flex-direction: row;
}

.itemLink {
  flex: 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);

  /* Layout */
  flex-grow: 1;
  flex: 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 3px;
    margin-bottom: 0;
  }

  color: var(--matterColor);
}

.itemUsername {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  line-height: 18px;
  text-align: left;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingInfoWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 14px;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 8px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF'; /* middot */
  }
}

.itemState {
  /* Font */
  @apply --marketplaceH5FontStyles;
  width: 24%;
  text-align: right;
  align-items: center;
  border-left: solid 1px var(--matterColorNegative);

  font-weight: var(--fontWeightRegular);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportLarge) {
    width: 12%;
  }
}

.lastTransitionedAt {
  text-align: right;
  color: var(--matterColor);
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: var(--fontWeightRegular);
  color: var(--attentionColor);
}

.stateNoActionNeeded {
  color: var(--matterColorAnti);
}

.stateSucces {
  font-weight: var(--fontWeightRegular);
  color: var(--successColor);
}

.nameEmphasized {
  font-weight: var(--fontWeightBold);

  /* baseline alignment fixes */
  margin-top: -1px;
  margin-bottom: 1px;
}

.nameNotEmphasized {
  font-weight: var(--fontWeightRegular);
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--matterColorAnti);
}

.lastTransitionedAtEmphasized {
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
}

.lastTransitionedAtNotEmphasized {
  color: var(--matterColorAnti);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName,
  & .lastTransitionedAt {
    color: var(--matterColorAnti);
  }
}

.pmMeetingButton:hover {
  text-decoration: none !important;
}

.newConversationButton {
  max-width: 400px;
  margin-bottom: 40px;
  text-decoration: none;
  border-radius: 5px;
}

.linksNavigation {
  color: var(--matterColor);
  @apply --marketplaceTabNavSmallFontStyles;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: right;
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    text-align: initial;
    text-align: none;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom-width: 0px;
    margin: 0 auto 8px 42px;
    text-align: left;
  }
}

.navigationWrapper {
  color: var(--matterColor);
  text-decoration: none;
  outline: none;

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }
}

.price {
  font-size: 17px;
  margin: auto 6px auto 6px;
}

.navigationMenu {
  display:none;
  @media(--viewportLarge) {
    display: block;
  }
}

.navigationMenuWrapperMobile {
  display: flex;
  flex-direction: row;
  @media(--viewportLarge) {
    display: none;
  }
}

.listingTitle {
  text-align: left;
}

.mainTab {
  margin-bottom: 8px;
  margin-right: 10px;
}

.mainTabSelected {
  font-weight: var(--fontWeightMedium);
  margin-bottom: 8px;
  margin-right: 10px;
}

.navigationMenuMobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 40%;
  justify-content: space-evenly;
  border-right: solid 1px var(--matterColorNegative);
}

.mobileSubtabs {
  display:flex;
  width: 100%;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.tooltip {
  display: none;
  @media (--viewportMedium){
    display: block;
  }
}

.deletedListing {
  width: 40px;
  height: 40px;
  padding-top: 12%;
  text-align: center;
  color: white;
  font-size: 55px;
  border-radius: 50%;
  background-color: var(--marketplaceColor);
}

.chatLink {
  @apply --marketplaceLinkStyles;
}

.archiveInboxTitleText {
  margin: 0;
  margin-bottom: 5px;
}

.archiveInboxWrapper {
  margin-bottom: 20px;
}

.archiveSubtitleWrapper {
  display: flex;
  flex-direction: row;
}

.archiveSubtitleText {
  font-size: 14px;
}

.inviteButton,
.exportCSVButton {
  width: 100%;
  @media (--viewportLarge) {
    width: 90%;
  }
}