@import '../../marketplace.css';

.root {
  display: none;
  @media (--viewportMedium) {
    display: block;
    margin: 0 0 20px 0;
  }
}

.mobileBottomPanel {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 16px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    display: none;
  }
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.sectionSideActionBar,
.sectionSideActionBarPendingApproval {
  display: flex;
  flex-direction: column;
  min-width: 90%;
  @media (--viewportMedium){
    min-width: 245px;
  }
}

.sectionSideActionBarPendingApproval {
  margin-top: 29px;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  /*top: 13px;*/
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */
  margin-bottom: 6px;

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  border-radius: var(--borderRadius);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 12px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 12px;
  }
}

.editListingLink:hover,
.newListingLink:hover,
.postJobLink:hover {
  text-decoration: none;
}

.editButton {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 225px;
  margin-bottom: 8px;
  width: 100%;
}

.editIconFeather {
  fill: none;
  margin-right: 15px;
}

.sectionCTA {
  @media (--viewportLarge) {
    display: block;
    min-width: 272px;
  }
}

.getFrameButton {
  background-color: white;
  color: rgb(74,74,74, 0.9);
  border: 1px solid #e7e7e7;
  &:hover {
    background-color: white;
    color: rgb(74,74,74, 0.9);
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.postJobButton {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 225px; */
}

.buyServiceButton {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shoppingCartIcon { 
  fill: none;
  margin-right: 8px;
}

.messageIcon { 
  fill: none;
  margin-right: 15px;
}

.discussServiceButton {
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}