@import '../../marketplace.css';

.layout {
      /* Layout */
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 10px 12px;
  overflow-x: visible;
  overflow-y: hidden;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: #ffffff;
  box-shadow: var(--boxShadow);

  /* cssPatent nie wiadomomo po co

    @media (--viewportMedium) {
    width: auto;
    padding: 40px 24px 24px 24px;
  } */

  @media (--viewportLarge) {
    overflow-y: initial;
    padding: 40px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: #ffffff;
  }
  @media (--viewportLargeWithPaddings) {
    padding: 40px 0 82px calc((100% - 1056px) / 2);
  }
}

.hideIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 28px;
    border-radius: 50%;
    background-color: #ffffff;

  &:hover {
    color: var(--marketplaceColor);
  }


  
  /* 
    Rozwala menu miedzy 764-1023
    @media (--viewportMedium) {
    top: 0;
    right: -14px;
    background-color: #ffffff;
  } */
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 8px;
  border-bottom: solid 1px var(--matterColorNegative);

  /* @media (--viewportMedium) {
    margin-right: auto;
    padding-bottom: 10px; 
    margin-top: 0;
    margin-bottom: 22px;
    border-bottom: none;
  } */

  @media (--viewportLarge) {
    border-left: none;
    padding-bottom: 0;
  }
}

.tabs {
  width: 250px;
  text-align: left;
  margin: auto 0;

  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    
    border-left: none;
  }
}

.projectsTabs {
  width: 250px;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 10px 0 auto 0;
    border-left: none;
  }
}

.tab {
  width: 250px;
  /* margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  } */
}

.icon {
  vertical-align: text-top;
  margin-right: 15px;
}

.icon path {
  stroke: currentColor;
}

.iconSidebar {
  display: flex;
  flex-direction: column;
}

.iconLink {
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.iconLinkActive {
  color: var(--marketplaceColor);
}

.iconLinkaActive path{
  stroke: var(--marketplaceColor)
}

.iconVertical {
  font-size: 24px;
  margin: 20px 20px 20px -10px;
  cursor: pointer;
}

.iconVertical path {
  stroke: inherit;
}

.iconHovered path {
  stroke: var(--marketplaceColor);
}


.notificationDot {
  /* Dimensions */
  font-size: 30px;

  /* Style: red dot */
  color: var(--failColor);
}