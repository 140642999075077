@import '../../marketplace.css';

.topbar {
  z-index: 1;
}

.main {
  /* @media (--viewportLarge) {
  width: 80%;
  } */
  @media (--viewportMediumMax) {
    padding:0px !important;
  }
  padding: 40px 0;
  border-left:none !important;
}



.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 2rem;
  width:95%;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
  border-color: var(--matterColorAnti);
  border-radius: 5px;
  @media (--viewportMediumMax) {
      width: 100%;
      padding:0px 1rem;
  }
}

.navigation {
  /* Layout */
  display: none;
  position: relative;
  flex-direction: row;
  padding: 20px 24px 20px 24px;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  /* border-top: 1px solid var(--matterColorNegative); */
  hiredByorder:none !important;
  overflow-x: visible;

  @media (--viewportLarge) {
    display: block;
  }

  @media (--viewportLarge) {
    padding: 13px 0 13px 36px;
    height:unset;
    flex-direction: column;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;

  }

  @media (--viewportLargeWithPaddings) {
    padding: 20px 0 20px 50px;
    min-width: 300px;
    border-right: 1px solid #e9e9e9;

  }
} 

.navigation > div {
  height: 100%;
}

.goBackButton {
  @apply --marketplaceLinkStyles;
  display: flex;
  align-items: center;
  max-width: 200px;
  outline:none;
  &:hover {
    text-decoration: none;
  }
}

.goBack {
  display: flex;
  flex-direction: row; 
  width: 10%;
  margin-left:15px;
  @media (--viewportMedium) {
    position: absolute;
    visibility:hidden;
  }
}
.mobileProgress{
  position: fixed;
  left:50%;
  @media (--viewportMedium) {
    visibility:hidden;
  }
}
.mobileProgressSection{
  display: flex;
}

.goBackIcon {
  fill: none;
  stroke: var(--marketplaceColor);
  margin-right: 4px;
}

.stepsContainer {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;
  }
}
.mobileProgress{
  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top:0;
  align-self: flex-start
}
.mobileBar{

  @media screen and (max-width: 758px){
  position: -webkit-sticky;
  position: sticky;
  background: white;
  z-index: 10;
  top:60px;
  }
}
.mobileProgressBar{
  background: rgb(158,158,158);
  background: linear-gradient(180deg, rgba(158,158,158,1) 0%, rgba(212,212,212,1) 57%, rgba(212,212,212,1) 100%);
  width:100%;
  height:15px;
  &::after{
    position: absolute;
    left: 0%;
    content: '';
    width: 0;
    background: #3c88f8;
    animation: tabProgress 500ms ease-in-out forwards;
    height: 15px;
  }
}
@keyframes tabProgress{
  0% {
    width:0%;
  }
  100%{
    width: calc(100% * (var(--index) / var(--indexLength)));
  }
}

.step {
  width: 33%;
  margin-right: 8px;
  margin-bottom: 10px;
  display: flex;
  @media (--viewportMedium) {
    display: block;
  }
}

.pointedLine {
  display: flex;
  align-items: center;
}

.point{
  height: 8px;
  width: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: var(--matterColorAnti);
}

.pointCompleted {
  background-color: var(--marketplaceColor);
}

.line {
  width: 100%;
  height: 2px;
  background-color: var(--matterColorAnti);
  margin-left: 5px;
  display: none;
  @media (--viewportMedium) {
    display: block
  }
}

.lineCompleted {
  background-color: var(--marketplaceColor);
}

.stepTitle {
  color: var(--matterColorAnti);
  margin-left: 10px;
  font-size: 16px;

  @media (--viewportMedium) {
    margin-left: 0;
    margin-top: 5px;
  }
}

.stepTitleCurrent {
  color: var(--matterColor);
  font-weight: 500;
}

.imageBlock {
  padding:0rem 3rem 2rem; 
  & img {
    width: 100%;
  }
}

.hiredByMobile {
  @media (--viewportMedium) {
    display:none;
  }
}

.hiredBy {
  display:none;
  @media (--viewportMedium) {
    display:block;
  }
}