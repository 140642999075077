@import '../../marketplace.css';
/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
    display: none;
    font-size: 0.8em;
    @media (--viewportMedium) {
      display: block;
    }
  }
  
  .detailCardTitle {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    @media (--viewportLarge) {
      margin-top: 0px;
      margin-bottom: 0;
    }
  }
  
  .detailCardSubtitle {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  
    @media (--viewportLarge) {
      margin-top: 9px;
      margin-bottom: 0;
    }
  }
  
  .detailCardMainInfo {
    display: flex;
  }