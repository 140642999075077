@import '../../marketplace.css';

.sidePanel {
  /* default font size */
  font-size: 22px;
    @media (--viewportMedium) {
      /* No display of the panel on medium screen */
      flex-basis: 0%;
      /* Own layout settings */
      display: flex;
      height: 100%;
    }
    @media (--viewportLarge) {
      flex-basis: 37.5%;
    }
}

.sidePanelProjectForm {
  font-size: 22px;
  display: flex;
}
  
.sidePanelWrapper {
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
}
  
.sideContent {
    height: calc(100vh - 72px); 
    /* left border commented out due to creation of right corner on mainContent  */
    /* border-left: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.02); */
    background-color: #ffffff;
    /* Centered content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    @media (--viewportHeightMaxHDPlus) {
      padding: 0;
    }
}

.sideContentNotFixed {
  position: static;
  height: auto;
}

.sideContentWithBeam {
  height: calc(100vh - 122px);
}

.headingWrapper {
  text-align: center;
  margin-bottom: 3vh;
  font-size: 0.95em;
  @media (--viewportXLargeMax) {
    font-size: 0.85em;
  }
  @media (--viewportXMLargeMax) {
    font-size: 0.73em;
  }
  @media (--viewportXSLargeMax) {
    font-size: 0.65em;
  }
  @media (--viewportHeightMaxHDPlus) {
    margin-bottom: 2vh;
  }
}

.headingText {
  color: rgb(74,74,74, 0.7);
  line-height: 1.3em;
}

.headingTextBold {
  color: rgb(74,74,74, 0.8);
  font-weight: var(--fontWeightMedium);
}

.stepsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.stepsRowFirst {
  display: flex;
  margin-bottom: 3vh;
  @media (--viewportHeightMaxHDPlus) {
    margin-bottom: 5vh;
  }
  @media (--viewportHeightMaxHD) {
    margin-bottom: 2vh;
  }
}

.stepsRowSecond {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3vh;
  @media (--viewportHeightMaxHDPlus) {
    margin-bottom: 3vh;
  }
  @media (--viewportHeightMaxHD) {
    margin-bottom: 2vh;
  }
}

.customersWrapper {
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (--viewportHeightMaxHD) {
      margin-bottom: 2vh;
    }
    @media (--viewportHeightMaxS) {
      margin-bottom: 1vh;
    }
}

.testimonialsText {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.stepCard {
  width: 11.3vw;
  height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 3%;
}

.stepCardLong {
  width: 14vw;
}

.stepCardNoRightMargin {
  margin-right: 0px;
}

.stepCircleOuter {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  @media (--viewportHeightMaxHD) {
    width: 22px;
    height: 22px;
  }
  @media (--viewportHeightMaxS) {
    width: 18px;
    height: 18px;
  }
}

.stepCircleInner {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #eaeaea;
  @media (--viewportHeightMaxHD) {
    width: 18px;
    height: 18px;
  }
  @media (--viewportHeightMaxS) {
    width: 14px;
    height: 14px;
  }
}

.stepCircleNumber {
  /* to eliminate span padding */
  position: absolute;
  line-height: 1;
  color: rgba(0,0,0,0.7);
  font-size: 0.60em;
  font-weight: var(--fontWeightMedium);
  @media (--viewportHeightMaxHD) {
    font-size: 0.5em;
  }
  @media (--viewportHeightMaxS) {
    font-size: 0.4em;
  }
}

.stepRectangleWrapper {
  /* background-color: #fdfdfd; */
  /* border-radius: 5px; */
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09); */
  width: 100%;
  height: 100%;
  z-index: 1;
  margin-top: 15px;
  padding: 1vh 5px 0px 5px;
  text-align: center;
  line-height: 1.0em;
  @media (--viewportHeightMaxHD) {
    line-height: 0.6em;
  }
}

.stepRectangleText {
  font-size: 0.7em;
  line-height: 0;
  @media (--viewportXLargeMax) {
    font-size: 0.65em;
  }
  @media (--viewportXMLargeMax) {
    font-size: 0.55em;
  }
  @media (--viewportXSLargeMax) {
    font-size: 0.5em;
  }
}

.ctaWrapper {
  margin-bottom: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportHeightMaxHD) {
    margin-bottom: 1vh;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Create listing (CTA for providers) */
.createListingLinkCTA {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}

.ctaButton {
  padding: 5px 6px 5px 6px;
  border-radius: 5px;
  white-space: nowrap;
  width: 10vw;
  height: 6vh;
  background-color: var(--successColor);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1vw;
  font-size: 1.2em;

  @media (--viewportHeightMaxHDPlus) {
    font-size: 1em;
  }
  @media (--viewportHeightMaxHD) {
    font-size: 0.85em;
  }
  @media (--viewportHeightMaxS) {
    font-size: 0.8em;
  }
  @media (--viewportHeightMaxHD) {
    margin-bottom: 1vh;
  }
  
  &:hover {
    background-color: var(--successColorDark);
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    text-decoration: none;
  }
}

.ticketButton {
  background-color: white;
  color: var(--matterColor);
  border: 1px solid #e7e7e7;
  width: 7vw;
  &:hover {
    background-color: white;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    text-decoration: none;
  }
}

.guideButton {
  background-color: var(--marketplaceColorLight);
  width: 6vw;
  &:hover {
    background-color: var(--marketplaceColorDark);
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    text-decoration: none;
  }
}

.expertTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.expertNumberText {
  font-size: 0.95em;
  margin-bottom: 1vh;
  @media (--viewportHeightMaxFHD) {
    font-size: 0.9em;
  }
  @media (--viewportHeightMaxHDPlus) {
    font-size: 0.85em;
  }
  @media (--viewportHeightMaxHD) {
    font-size: 0.7em;
    margin-bottom: 0.7vh;
  }
  @media (--viewportHeightMaxS) {
    font-size: 0.65em;
    margin-bottom: 0vh;
  }
}