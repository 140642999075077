@import '../../marketplace.css';

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 20px;
}

.features {
    display:flex;
    flex-direction: row;
    width: 100%;
    margin: 16px 0;
    font-size: 16px;
}

.tickIcon {
    color: var(--marketplaceColor);
    min-width:16px;
    min-height:16px;
    margin-top: 5px;
}

.radioButtonsContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 40px 0;
}

.fieldContainer {
    position: relative;
    font-size: 24px;
    width: 310px;
    padding: 20px 40px;
    margin-bottom: 25px;
    box-shadow: 0 16px 48px 0 rgba(26, 39, 81, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricingAccentRed {
    box-sizing: border-box;
    display: block;
    position: absolute;
    line-height: 2em;
    left: 0px;
    top: -3px;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: #d5243a;
}

.pricingAccentBlue {
    box-sizing: border-box;
    display: block;
    box-sizing: border-box;
    line-height: 2em;
    position: absolute;
    left: 0px;
    top: -3px;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background-color: var(--marketplaceColor);
}

.fullVersionActive {
    font-size: 32px;
    align-self: center;
    color: var(--successColor);
}

.versionTitle {
    font-size: 28px;
}

.priceValue {
    display: block;
    width: 100%;
    font-size: 50px;
}

.priceText {
    text-align: left;
    font-size: 28px;
    padding-bottom: 16px;
    border-bottom: solid 2px var(--marketplaceColor);
    margin-bottom: 28px;
}

.freeTrialEnd {
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: var(--attentionColor);
}

.submitButton {
    margin-top: 20px;
}

.readMoreLink {
    text-align: center;
}