@import '../../marketplace.css';

.topbar {
  z-index: 1;
}

.main {
  @media (--viewportLarge) {
  width: 80%;
  }
  padding: 40px 0;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 32px;
  margin: 0 auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
  border-color: var(--matterColorAnti);
  border-radius: 5px;
  @media (--viewportMedium) {
      flex-direction: row-reverse;
      width: 90%;
  }
}

.navigation {
  /* Layout */
  display: none;
  position: relative;
  flex-direction: row;
  padding: 20px 24px 20px 24px;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    display: block;
  }

  @media (--viewportLarge) {
    padding: 13px 0 13px 36px;
    flex-direction: column;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 13px 50px;
  }
}