@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height:100%;
}

.loading {
  margin-left: 24px;
  margin-right: 24px;
}

.error {
  margin-left: 24px;
  margin-right: 24px;
  color: var(--failColor);
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    padding: 13px 0 82px 36px;
    flex-direction: column;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportLarge) {
    padding-left: 30px !important;
    padding-right: 2px !important;
  }
}

.navigationNone {
  display: none !important;
}

.tabContent {
  display: none;
}

.tabContentVisible {
  display: block;
}

.footer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.bookButton {
  width: 50px;
  @apply --marketplaceButtonStylesPrimary;
  padding-top: 20px;
}

.layout {
  padding: 0;
  @media (--viewportLarge) {
    padding: 0 0px 0px 59px;
  }
}
