@import '../../marketplace.css';

.shareContentRegular {
  width: 278px;
  min-height: 52px;
  border-radius: var(--borderRadius);
  padding: 17px 30px; 
  margin: 14px auto 0 auto;
  background-color: var(--marketplaceColorVeryLight);
  border: 1px solid var(--matterColorAnti);
  display: flex;
  flex-direction: row;
}

.shareContentAdjusted {
  min-width: 274px;
  min-height: 52px;
  border-radius: var(--borderRadius);
  padding: 17px 30px; 
  margin: auto auto 20% auto;
  background-color: var(--marketplaceColorVeryLight);
  border: 1px solid var(--matterColorAnti);
  display: flex;
  flex-direction: row;
  pointer-events: all;
  @media (--viewportMedium) {
    margin: auto auto 2% auto;
  }
}
  
.shareTitle {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  font-size: 14px;
  color: var(--matterColor);
  margin: 0 14px 0 0;
}
  
.shareButtonsPanel {
  display: flex;
  justify-content: space-between;
  width: 40%;
}
  
.shareButton {
  width: calc(25% - 3px);
}
  
.shareIcon:hover {
  color: var(--marketplaceColor);
}