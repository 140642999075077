@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
  --SearchPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 10;
  }
}

.container {
  /* Layout */
  width: 98%;
  flex-grow: 1;
  display: flex;
  margin-left: 0px;
  background-color: var(--matterColorLight);
  padding-top: 12px;
  @media (--viewportMedium) {
    margin-left: 20px;
    position: relative;
    min-height: var(--SearchPage_containerHeight);
  }
}

.containerWithBeam {
  /* Layout */
  width: 98%;
  flex-grow: 1;
  display: flex;
  margin-left: 0px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin-left: 20px;
    position: relative;
    min-height: var(--SearchPage_containerHeight);
  }
}

.searchResultContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;
  z-index: 10;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.sidePanel {
  @media (--viewportMedium) {
    /* No display of the panel on medium screen */
    flex-basis: 0%;
    /* Own layout settings */
    display: flex;
    height: 100%;
  }
  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sidePanelWrapper {
  width: 100%;
  height: 100%;
}

.sideContent {
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 15px;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0; */

    /* Fixed content needs width relative to viewport */
    /* width: 50vw;
    height: var(--SearchPage_containerHeight); */
  }

  @media (--viewportLarge) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;
    width: 37.5vw;
    height: var(--SearchPage_containerHeight);
  }
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    /* flex-basis: 50%; */

    /* No display of the map on medium screen */
    flex-basis: 0%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0; */

    /* Fixed content needs width relative to viewport */
    /* width: 50vw;
    height: var(--SearchPage_containerHeight); */
  }

  @media (--viewportLarge) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;
    width: 37.5vw;
    height: var(--SearchPage_containerHeight);
  }
}

.helpButton {
  position: fixed;
  bottom: 1.5em;
  right: 100px;
  width: auto;
  min-height: 30px;
  padding: 10px 12px;
  border-radius: 5px;
  border-color: var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color:white;
  white-space: nowrap;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.watermark {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.5em;
  color: var(--matterColor);
  line-height: 0px;
}

.logoWatermarkImage {
  margin-top: 6px;
  width: 110px;
}