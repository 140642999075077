@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditExpertForm_formMargins: {
    margin-bottom: 4px;

    @media (--viewportMedium) {
      margin-bottom: 8px;
    }
  }
  --EditExpertFormScopeForm_formMargins: {
    margin-bottom: 6px;
    @media (--viewportMedium) {
      margin-bottom: 6px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title {
  @apply --EditExpertForm_formMargins;
}

.experience {
  @apply --EditExpertForm_formMargins;
  width: calc(50% - 6px);
  flex-shrink: 0;
}

.projectsCount {
  @apply --EditExpertForm_formMargins;
  width: calc(50% - 6px);
  flex-shrink: 0;
}

.priority {
  @apply --EditExpertForm_formMargins;
  flex-shrink: 0;
}

.description {
  @apply --EditExpertForm_formMargins;
  flex-shrink: 0;
}

.company {
  @apply --EditExpertForm_formMargins;
  flex-shrink: 0;
}

.expertise {
  @apply --EditExpertForm_formMargins;
  flex-shrink: 0;
}

.certificate {
  @apply --EditExpertForm_formMargins;
}

.software {
  margin-bottom: 8px;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  margin-right: 10px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.priceInput {
  @apply --EditExpertForm_formMargins;
  flex-shrink: 0;
  width: 100%;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.hourlyRateInfo {
  color: var(--matterColorAnti);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.button {
  margin-right: 0;
}

.formError {
  margin-top: 8px;
  margin-bottom: 8;
  color: var(--failColor);
  font-size: 0.8em;
}

.projects {
  margin-bottom: 14px;
}

.languages{
  margin-bottom: 24px;
}

.firstRow,
.secondRow {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.secondRowElement {
  width: calc(50% - 8px);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  font-size: 18px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--successColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.imagesField {
  margin-top: 40px;
  flex-shrink: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: 30%;
    margin: 0 24px 24px 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 30%;
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightRegular);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sections {
  width: 100%;
  border-collapse:separate; 
  border-spacing: 0 36px;
}

.sectionTitle {
  display: none;
  @media (--viewportMedium){
    font-size: 22px;
    display: flex;
  }
}

.sectionContent {
  @media (--viewportMedium) {
    width: 80%;
  }
}

.sections tr {
  margin-bottom: 20px;
}

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 0 24px;
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  margin-bottom: 18px;
}

.editPlanButton {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  padding-top: 5px;
}

.editPlanIcon {
  margin: 0px 3px 4px 0;
}

.week {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-bottom: 23px;

  & > .weekDay {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.weekDay {
  @apply --marketplaceTabNavHorizontalFontStyles;
  display: flex;
  flex-direction: row;
  padding: 24px 24px 23px 24px;
}

.dayOfWeek {
  width: 100px;
}

.blockedWeekDay {
  background-color: #f3f3f3;
  & > .dayOfWeek {
    color: var(--matterColorAnti);
  }

  &:hover > .dayOfWeek {
    color: var(--matterColor);
  }
}

.entry {
  display: block;
}

.exceptionsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noExceptions {
  @apply --marketplaceH4FontStyles;
  display: flex;
  align-items: center;
  margin: 0 0 24px 0;
}

.exceptions {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 11px;

  & > .exception {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.exceptionHeader {
  display: flex;
  flex-direction: row;
}

.exceptionAvailability {
  padding-right: 12px;
  margin: 12px auto 0px 24px;
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.exceptionAvailabilityDot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 12px;
  background-color: var(--failColor);
}

.isAvailable {
  background-color: var(--successColor);
}

.exceptionAvailabilityStatus {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 0;
}

.timeRange {
  @apply --marketplaceH5FontStyles;
  margin: -2px 12px 13px 32px;
  color: var(--matterColorAnti);
}

.removeIcon {
  width: 10px;
  height: 10px;
  stroke: var(--matterColorAnti);
  fill: var(--matterColorAnti);
}

.removeExceptionButton {
  padding: 0 24px 0 24px;
  border: 0;
  cursor: pointer;

  &:hover .removeIcon {
    stroke: var(--matterColorDark);
    fill: var(--matterColorDark);
  }
}

.addExceptionButton {
  @apply --marketplaceH4FontStyles;
  padding: 1px 0 5px 0;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  padding-left: 0;
  padding-right: 0;

  background-color: var(--matterColorBright);
}

.error {
  color: var(--failColor);
}

@media (--viewportMedium) {
  .root {
    padding: 11px 24px 0 24px;
  }

  .sectionHeader {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .title {
    margin-bottom: 16px;
  }

  .sectionTitle {
    @apply --marketplaceSearchFilterSublabelFontStyles;
    color: var(--matterColorAnti);
  
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 3px 0 3px 0;
  }

  .weekDay {
    padding-top: 22px;
    padding-bottom: 25px;
    padding-right: 24px;
    padding-left: 24px;
    cursor: pointer;

    &:hover {
      color: var(--matterColorDark);
    }
  }

  .dayOfWeek {
    width: 200px;
  }

  .exceptions {
    margin-bottom: 8px;
  }

  .exceptionAvailability {
    margin: 8px auto 0px 24px;
  }

  .exceptionAvailabilityDot {
    margin-top: 12px;
  }

  .exceptionAvailabilityStatus {
    margin: 0px;
    padding: 5px 0 3px 0;
  }

  .timeRange {
    padding: 6px 0 2px 0;
    margin: -8px 44px 15px 44px;
  }

  .removeIcon {
    width: 12px;
    height: 12px;
  }

  .removeExceptionButton {
    padding: 6px 24px 0 24px;
  }

  .addExceptionButton {
    padding: 3px 0 5px 0;
  }

  .modalContainer {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}

.panelTitle {
  @apply --marketplaceH1FontStyles
}

.numbersRow {
  display: flex;
  justify-content: space-between;
}

.required {
  color: var(--failColor)
}

.hiddenTab {
  display: none;
}
.removeBtn {
  padding: 5px;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
}

.hideField{
  display:none;
}

.fileTable{

  & td {
    padding:10px;
  }
}


.customfileinput::-webkit-file-upload-button {
  visibility: hidden;
}

.customfileinput {
  border: unset !important;
  overflow: hidden;
  width: 131px;
}

.customfileinput::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.customfileinput:hover::before {
  border-color: black;
}
.customfile-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
