@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.signupText {

  font-size: 18px;
  text-align: left;
}
.signupTextMail {
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.asterisk {
  color: var(--failColor);
}
.countryCodePlaceholder{
  position: absolute !important;
  bottom:0;
  min-width: max-content;
  width:70px;
  & input{
    font-size: 20px !important;
    line-height: 24px !important;
    border-bottom: 1px solid #2ecc71;
    font-family: "Ubuntu", Helvetica, Arial, sans-serif;
    color: rgba(74, 74, 74, 0.9);
    padding: 4px 0 10px !important;
    padding-left: 25px !important;
  }
  & input::placeholder{
    color: rgba(74, 74, 74, 0.9);
  }
  &::before{
    border-bottom: 1px solid #2ecc71;
  }
}
.countryCode{
  position: absolute !important;
  bottom:0;
  min-width: max-content;
  width:70px;
  & input{
    font-size: 20px !important;
    line-height: 24px !important;
    border-bottom: 1px solid #2ecc71;
    font-family: "Ubuntu", Helvetica, Arial, sans-serif;
    color: rgba(74, 74, 74, 0.9);
    padding: 4px 0 10px !important;
  }
  &::before{
    border-bottom: 1px solid #2ecc71;
  }
}
.countryArrow{
  position:absolute !important;
}
@media(max-device-width:768px){
  .countryCodePlaceholder{
    & input::placeholder{
      color: rgba(74, 74, 74, 0.9);
    }
  }
}
@media(max-width:768px){
.countryCode{
  & input{
    padding-bottom: 5px !important;
  }
}
.countryCodePlaceholder{
  & input{
  padding-bottom: 5px !important;
  padding-left: 25px !important;
  }
}
.phoneNumber{
  position:absolute !important;
  bottom:0;
  left:70px;
  z-index: 50;
  width:90%;
  max-width: calc(100% - 70px);
  margin-right:100px;
  & [class^="ValidationError"]{
    position:absolute !important;
  }
}
}
.phoneNumber{
  position:absolute !important;
  bottom:0;
  left:70px;
  z-index: 50;
  & [class^="ValidationError"]{
    position:absolute !important;
  }
}
.errorPhone{
  display: flex;
  position: relative;
  margin-top: 32px;
  margin-bottom: 74px;
  height:72px;
  transition: margin 10ms;
}
.phone{
  display: flex;
  position: relative;
  margin-top: 32px;
  height:72px;
  transition: margin 10ms;
}
.phoneLabel
{
  position:absolute !important;
  margin-bottom: 30px;
  top:0;
}
.ccBorder {
  position:absolute !important;
	border-bottom:2px solid #2ecc71;
  z-index: 50;
  height: 50px;
  width:100%;
  bottom:0;
}
.hidden{
  width:0;
  visibility:hidden;
  position: absolute;
}

.typeButton {
    padding: 10px;
  border:1px solid #e5e5e5;
  border-radius: 8px;
  flex-grow: 1;
  text-align: center;
}

.bulletTitle {
  margin-bottom:0.875rem;
}

.bulletContainer {
  display: flex;
  gap: 12px;
  margin-bottom:0.875rem;
}

.singleBulletItem {
  display:flex;
  flex-grow:1;

  & input {
    display: none;
  }
  & input:checked ~ label {
    border: 0px solid #62C87A;
    background: #61C87A;
    transition: all -1s;
    color:#fff;
  }

  & label:hover {
    box-shadow: 0 8px 11px rgb(0 0 0 / 5%), 0 0px 12px rgb(0 0 0 / 5%);
  }
}