@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.withPadding {
  padding: calc(2 * var(--spacingUnit));

  @media (--viewportMedium) {
    padding: calc(2 * var(--spacingUnitDesktop));
  }
}

.withMargin,
.defaultWrapperStyles {
  margin: calc(2 * var(--spacingUnit)) 0;

  @media (--viewportMedium) {
    margin: calc(2 * var(--spacingUnitDesktop)) 0;
  }
}

.navBar {
  flex-basis: 300px;
  flex-shrink: 0;
  composes: withPadding;
  box-shadow: var(--boxShadow);
}

.main {
  flex-grow: 1;
  composes: withPadding;

  @media (--viewportMedium) {
    padding: 48px 16px 0 16px;
  }
}

.examplesList {
  margin: 24px 0;
}

.example {
  padding: 0;
}

.link {
  color: var(--marketplaceColor);
}

.groups {
  margin: 0 0 12px 0;
}

.group {
  text-transform: capitalize;
}

.selectedGroup {
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
}

/* Typography.example */
.typographyContent {
}

.baselines {
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="24" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10M0 06h10M0 12h10" stroke="%23ccc" shape-rendering="crispEdges" /><path d="M0 18h10" stroke="%23ddd" stroke-width="1" shape-rendering="crispEdges" /></svg>');
  background-repeat: repeat;

  @media (--viewportMedium) {
    background-image: url('data:image/svg+xml;utf8,<svg width="10" height="24" viewBox="0 0 10 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h10" stroke="%23ddd" stroke-width="1" shape-rendering="crispEdges" /><path d="M0 08h10M0 16h10M0" stroke="%23ccc" shape-rendering="crispEdges" /></svg>');
    background-repeat: repeat;
  }
}

.spacing2x {
  margin: calc(2 * var(--spacingUnit)) 0;

  @media (--viewportMedium) {
    margin: calc(2 * var(--spacingUnitDesktop)) 0;
  }
}

.fontsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  @media (--viewportMedium) {
    margin-top: 19px;
  }
}

.fontCard {
  flex-basis: 300px;
  display: flex;
  flex-direction: column;

  margin-right: calc(2 * var(--spacingUnit));
  margin-bottom: calc(2 * var(--spacingUnit) + 2px);

  @media (--viewportMedium) {
    margin-right: calc(2 * var(--spacingUnitDesktop));
    margin-bottom: calc(2 * var(--spacingUnitDesktop) + 2px);
  }
}

.element {
  border-style: solid;
  border-color: #aaa;
  border-width: 1px;
}

.description {
  flex-grow: 1;
  width: 100%;
  background-color: #f1f1f1;
  padding: calc(2 * var(--spacingUnit) - 3px) calc(2 * var(--spacingUnit));

  border-style: solid;
  border-color: #aaa;
  border-width: 1px;
  border-top-color: transparent;

  @media (--viewportMedium) {
    padding: calc(2 * var(--spacingUnitDesktop) - 3px) calc(2 * var(--spacingUnitDesktop));
  }
}

.heroTitle {
  @apply --marketplaceHeroTitleFontStyles;
}

.tinyFont {
  @apply --marketplaceTinyFontStyles;
}

/* Colors.example */

.colorsContent,
.colorsContainer {
  display: flex;
  flex-direction: column;
}

.colorsGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(4 * var(--spacingUnit));
}

.colorCard {
  flex-basis: 275px;
  height: 275px;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(4 * var(--spacingUnit));
  margin-right: calc(2 * var(--spacingUnit));
  overflow: hidden;

  border: 1px solid var(--matterColorNegative);
}

.color {
  width: 100%;
  height: 66.6%;
}

.colorDescription {
  flex-grow: 1;
  width: 100%;
  background-color: #fff;
  padding: calc(2 * var(--spacingUnit));

  @media (--viewportMedium) {
    padding: calc(2 * var(--spacingUnitDesktop) - 2px) calc(2 * var(--spacingUnitDesktop));
  }
}

.marketplaceColorBackground {
  composes: color;
  background-color: var(--marketplaceColor);
}

.marketplaceColorLightBackground {
  composes: color;
  background-color: var(--marketplaceColorLight);
}

.marketplaceColorDarkBackground {
  composes: color;
  background-color: var(--marketplaceColorDark);
}

.successColorBackground {
  composes: color;
  background-color: var(--successColor);
}

.failColorBackground {
  composes: color;
  background-color: var(--failColor);
}

.attentionColorBackground {
  composes: color;
  background-color: var(--attentionColor);
}

.matterColorBackground {
  composes: color;
  background-color: var(--matterColor);
}

.matterColorAntiBackground {
  composes: color;
  background-color: var(--matterColorAnti);
}

.matterColorDarkBackground {
  composes: color;
  background-color: var(--matterColorDark);
}

.matterColorNegativeBackground {
  composes: color;
  background-color: var(--matterColorNegative);
}

.matterColorLightBackground {
  composes: color;
  background-color: var(--matterColorLight);
}
