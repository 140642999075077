@import '../../marketplace.css';

.verifiedFlag {
    display: inline-block;
    text-align: center;
    color: var(--successColor);
    overflow: auto;
    font-size: 20px;
}

.verifiedChip {
    align-self: flex-start;
}

@media (--viewportSmallMax) {
    .verifiedFlag {
        visibility: hidden;
        width: 0;
    }
}