@import '../../../marketplace.css';

.scopeWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.jobScopeTaskName {
    width: 100%;
    margin-right: 10px;
}

.scopeTitle {
    font-size: 36px;
    font-weight: var(--fontWeightRegular);
    line-height: 56px;
    letter-spacing: -2px;
    margin-top: 24px;
    margin-bottom: 12px;
}

.scopeTaskWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.scopeSubtaskWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
}

.scopeSubtaskPointer {
    width: 5%;
    margin-right: 10px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: center;
}

.scopeSubtaskName {
    width: 57%;
    margin-right: 10px;
    margin-top: 18px;
}

.scopeSubtaskTime {
    width: 15%;
    margin-right: 10px;
    margin-top: 18px;
}

.scopeSubtaskPrice {
    width: 15%;
    margin-right: 10px;
    margin-top: 18px;
}

.scopeSubtaskButtonsWrapper {
    width: 8%;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.scopeSubtaskButton {
    width: 8%;
    margin-top: 18px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}


.scopeTaskName {
    width: 60%;
    margin-right: 10px;
}
  
.scopeTaskTime {
    width: 15%;
    margin-right: 10px;
}

.scopeTaskPrice {
    width: 15%;
    margin-right: 10px;
}
  
.scopeTaskButton {
    width: 8%;
    margin-top: 32px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
  
.scopeTaskButtonsWrapper {
    width: 8%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
  
.scopeTaskButtonMin {
    width: 50%;
    margin: 0;
}
  
.missingAccountVerification {
    color: var(--failColor)
}
  
.scopeCommentWrapper {
    margin-bottom: 10px;
}
  
.scopeCommentText {
    font-size: 0.7em;
    color: var(--matterColor);
}
  
.scopeInputLabel {
    display: flex;
    flex-direction: row;
    color: var(--matterColor);
    font-size: 16px;
    line-height: 24px;
    font-weight: var(--fontWeightRegular);
    padding-top: 6px;
    padding-bottom: 2px;
}
  
.scopeInputValue {
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 2px solid var(--matterColor);
    padding: 4px 0 10px 0;
}
  
.taskPriceLabelWrapper {
    display: flex;
    flex-direction: row;
}
  
@media (--viewportMediumMax) {
    .scopeTaskWrapper {
      display: grid;
      grid-template-columns: 4fr 4fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
        "scopeTaskName scopeTaskName scopeTaskName"
        "scopeTaskTime scopeTaskPrice scopeTaskButton";
    }
    .scopeTaskName {
      grid-area: scopeTaskName;
      width: 100%;
    }
    .scopeTaskTime {
      grid-area: scopeTaskTime;
      width: 90%;
      margin-right: 10px;
    }
    .scopeTaskPrice {
      grid-area: scopeTaskPrice;
      width: 100%;
    }
    .scopeTaskButtonsWrapper {
      grid-area: scopeTaskButton;
      width: 100%;
    }
    .scopeInputValue {
      padding: 5px 0 5px 0;
    }
    .scopeInputLabel {
      padding-top: 0;
      padding-bottom: 0;
    }
    .scopeSubtaskWrapper {
        display: grid;
        grid-template-columns: 1fr 4fr 4fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
          "scopeSubtaskPointer scopeSubtaskName scopeSubtaskName scopeSubtaskName"
          "scopeSubtaskPointer scopeSubtaskTime scopeSubtaskPrice scopeSubtaskButton";
    }
    .scopeSubtaskPointer {
        grid-area: scopeSubtaskPointer;
        width: 100%;
    }
    .scopeSubtaskName {
        grid-area: scopeSubtaskName;
        width: 90%;
    }
    .scopeSubtaskTime {
        grid-area: scopeSubtaskTime;
        width: 90%;
    }
    .scopeSubtaskPrice {
        grid-area: scopeSubtaskPrice;
        width: 90%
    }
    .scopeSubtaskButtonsWrapper {
        grid-area: scopeSubtaskButton;
        width: 100%;
    }
}