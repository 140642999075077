@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.userContainer {
  display: flex;
  flex-direction: row;
}

.userStatus {
  margin-left: 20px;
}

.userAlreadyInvited {
  color: var(--successColor)
}

.userPendingInvite {
  color: var(--attentionColor)
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: var(--marketplaceColor);
  border-radius: 3px;
}

.buttonText {
  color: var(--matterColorLight);
  margin: 10px;
}

.user {
  margin-top: 5px;
  list-style: none;
}