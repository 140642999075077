@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    margin-top: 8vh;
  }
}

.modalContent {
  flex-grow: 1;
}

.checkbox {
  width: 10px;
  margin-right: 16px;
}

.checkboxWrapper { 
  @apply --marketplaceDefaultFontStyles;
  display: flex;
  width: 100%;
  margin-bottom: 6px;
  justify-content: left;
  align-items: center;
  width:  100%;
  &:hover {
    color: var(--marketplaceColor);
  }
}

.checkboxWrapper label, .checkboxWrapper input, .checkboxWrapper span {
  cursor: pointer;
}

.error {
  color: var(--failColor);
  text-align: center
}