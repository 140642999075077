@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 47px;
  }
}

.waitForApproval {
  color: var(--attentionColor);
  margin-bottom: 20px;
  text-align: center;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: left;
  margin: auto 0px 20px 0px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.subheadingRegularPrint,
.regularPrint {
  font-size: 16px;
  line-height: normal;
  color: var(--matterColorAnti);
  text-align: left;
  margin: auto 0px 20px 0px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.subheadingRegularPrint {
  margin-top:12px;
  color: var(--matterColor);
}

.presentAccountInfo {
  font-size: 16px;
  line-height: normal;
  color: var(--matterColor);
}

.rootModalContent {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    margin-top: 8vh;
  }
}

.modalContent {
  flex-grow: 1;
}

.alternativeInfoMain {
  font-size: 18px;
  line-height: 28px;
}

.alternativeInfoNote {
  display: block;
  margin-bottom: 20px;
}

.linkButton {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
