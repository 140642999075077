@import '../../marketplace.css';

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  /* background-color: var(--matterColorLight); */
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    padding: 13px 0 82px 36px;
    flex-direction: column;
    overflow-x: visible;
    /* background-color: var(--matterColorBright); */
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    padding-right: 2px !important;
  }
}

.navigationNone {
  display: none !important;
}

.mainWrapper {
	display: flex;
	flex-direction: column;
	margin: 16px 0;
	@media (--viewportMedium) {
		flex-direction: row;
	}
}

.loadingWrapper {
	display: flex;
	flex-direction: row;
  margin: 16px;
}

.mainSection {
	flex-direction: column;
	flex-grow: 1;
	@media (--viewportMedium) {
		padding-right: 59px;
	}
}

.asideDesktop {
  display: flex;
	flex-direction: column;
	margin-top: 25px;
	@media (--viewportMedium) {
		max-width: 345px;
		min-width: 345px;
	}
  @media (--viewportLarge) {
		padding-left: 36px;
		border-left-style: solid;
  	border-left-width: 1px;
  	border-left-color: var(--matterColorNegative);
  }
  @media (--viewportLargeWithPaddings) {
    padding-left: 59px; 
  }
}

.projectBoardHeading {
	margin: 16px 0 0 0;
	font-size: 16px;
	color: var(--matterColorAnti);
}

.projectTitle {
	margin: 0 0 16px;
}

.projectInfoSubtitle {
	font-size: 16px;
	margin: 0;
	cursor: pointer;
	white-space: pre-line;
}

.projectDescription {
	font-size: 14px;
}

.approvalInfo {
	font-size: 14px;
	line-height: 18px;
	margin: 0;
	color: var(--attentionColor);
}

.ticket,
.sectionExpertWrapper {
	display: flex;
	flex-direction: column;
}

.firstRow {
	@media (--viewportLarge) {
		margin: 0 200px;	
	}
}

.firstRow,
.progressBarWrapper  {
	display: flex;
	flex-direction: row;
}

.messages {
	margin-top: 32px;
}


.progressBarWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 24px;
}

.projectProgressWrapper {
	margin: 0 auto;
	width: 100%;
}

.progressTitleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.progressTitle, .yourExpertTitle {
	font-size: 20px;
	text-align: center;
	margin: 0 auto 8px auto;
}

.yourExpertTitle {
	margin-left: 0;
}

.expertLinks {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
}

.progressHours, .progressTickets {
	width: 100%;
	text-align: center;
	margin: 8px 0 0;
	font-size: 16px;
	line-height: 20px;
}

.progressValues {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 14px;
}

.goToProjectButton {
	display: flex;
	align-items: center;
	max-width: 200px;
	font-size: 14px;
	outline:none;
	&:hover {
	  text-decoration: none;
	}
}

.goBackIcon {
	fill: none;
	stroke: var(--marketplaceColor);
	margin-right: 4px;
  }
  

/* TICKET SECTION */

.sectionTitle {
	font-size: 18px;
	margin-bottom: 15px;
}

.ticketSection {
	margin-top: 14px;
	display: flex;
	flex-direction: column;
	@media (--viewportLarge) {
	flex-direction: row;
	}
}

.ticketSection {
	width: 100%;
	justify-content: space-between;
}

.progressInfoWrapper
 {
	display: flex;
	flex-direction: column;
	width: 75%;
  margin: 0 auto;
}

.singleTicketSection {
	min-height: 400px;
	background-color: #fafafa;
	border-radius: 5px;
	padding: 14px;
	width: 100%;
	margin-bottom: 12px;
	@media (--viewportLarge) {
		width: 30%;
		margin-bottom: 0;
	}
}

.ticketTitle {
	font-size: 16px;
	display: block;
	/* overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; */
	width: 100%;
	margin-bottom: 12px;
}

.singleSectionContent:hover,
.singleSectionContent,
.ticketTitle,
.addTicket {
	text-decoration: none;
	color: var(--matterColor)
}

.addTicket {
	font-size: 14px;
	&:hover {
		color: var(--marketplaceColor);
	}
}

.ticketAuthorAvatar {
	width: 40px;
	height: 40px;
	margin-left: 25px;
}

.singleTicketWrapper {
	background-color: var(--matterColorLight);
	padding: 10px;
	margin-bottom: 8px;
	border: 1px solid var(--matterColorNegative);
	border-radius: 5px;
	box-shadow: var(--boxShadow);
	&:hover {
		border: 1px solid var(--matterColorAnti);
	}
}

.ticketDetails {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 12px;
	font-size: 14px;
}

.plusIcon {
	margin-top: auto;
	margin-bottom: auto;
}

.singleTicketProgressbarWrapper {
	display: flex;
	flex-direction: row;
	margin: auto 0 auto auto;
	width: 50%;	
}

.hours {
	font-size: 12px;
	margin-left: 10px;
}

.noEstimationHours {
	font-size: 11px;
	width: 60%;
	color: var(--successColor)
}

.ticketProgressInfo {
	display: flex;
	justify-content: space-between;
	width: 60%;
}

.progressBar {
	margin-top: auto;
	margin-bottom: auto;
	width: 80%
}

/*SECTION EXPERT*/

.sectionExpertWrapper {
	padding: 20px;
	border-spacing: 20px;
	margin-top: 14px; 
	width: 100%;
	border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 24px;
	@media (--viewportMedium) {
		margin-right: 0;
		margin-left: auto;
		margin-top: 0; 
	}
}

.expertLinkContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--matterColor);
	&:hover {
		text-decoration: none;
	}
}

.yourExpertTitleLink {
	margin: 12px 0 20px;
	font-size: 20px;
	color: var(--matterColor);
	display: block;
	&:hover {
		color: var(--marketplaceColor);
		text-decoration: none;
	}
}

.offerLink {
	font-size: 12px;
	display: block;
}

.expertAvatar {
	font-family: 'sofiapro', Helvetica, Arial, sans-serif;
	border-radius: 50%;
	/* Position possible initials to the center of the component */
	display: flex;
	align-items: center;
	justify-content: center;
	width:  90px;
	height: 90px;
	margin: 0 auto;
	/* Colors */
	background-image: linear-gradient(
	  -180deg,
	  var(--marketplaceColorLight) 0%,
	  var(--marketplaceColor) 100%
	);
	color: var(--matterColorLight);
	&:hover {
	  text-decoration: none;
	}
}

.expertInitials {
	font-size: 18px;
	font-weight: var(--fontWeightSemiBold);
	@media (--viewportMedium) {
	  font-size: 18px;
	  font-weight: var(--fontWeightSemiBold);
	}
}

.expertAvatarAspectWrapper {
	margin: 14px 0;
}

.bookCallButtonLink {
	width:100%;
}

.bookCallButtonLink:hover {
	text-decoration: none
}

.bookCallButton {
	width: 100%;
	margin-top: auto;
	margin-bottom: auto;
	padding: 20px;
}

.button {
	padding: 20px;
	margin-bottom: 12px;
}

.submitCredentialsButton:hover,
.pmCallButton:hover {
	text-decoration: none; 
}

.sectionButtonsWrapper {
	padding: 20px;
}

.expertAndButtons {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.projectSolvedWrapper {
	display: flex;
	justify-content: center;
	/*top: 13px;*/
	width: 100%;
	color: var(--matterColorNegative);
	background-color: var(--matterColor);
	margin: 12px auto;
	padding: 8px;
	font-size: 13px;
	  
	/* Action bar prevents the image click events going to the parent and
	 should not show a pointer */
	cursor: initial;
	border-radius: var(--borderRadius);

	@media (--viewportMedium) {
		width: 50%;
	}
	  
}
.infoIcon {
	vertical-align: text-top;
	margin-left: 6px;
}

.buttonIcon {
	vertical-align: text-top;
	margin-right: 6px;
}

.scope {
  display: flex;
  flex-direction: row;
}

.scopeSideWrapper {
  margin-top: 9px;
}

.scopeSideLine,
.scopeLastSideLine {
  width: 1px;
  background-color: var(--chipPrimaryColor);
  margin-left: 4px;
}

.scopeSideLine {
  height: 110%;
}

.scopeLastSideLine {
  height: 75%;
}

.scopeNoSideLine {
  height: 0;
}

.scopePointer {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--marketplaceColor);
}

.milestoneWrapper {
  margin-left: 15px;
  min-height: 40px;
  margin-bottom: 10px;
}

.projectPointer {
  color: var(--marketplaceColor)
}

.milestoneFirstRow {
  margin-bottom: 6px;
}

.milestoneTitle {
	font-size: 14px;
}

.subtasksWrapper {
  color: var(--matterColorAnti)
}

.subtask {
  display: block;
  font-size: 12px;
}

.solutionForm {
	margin-top: 25px; 
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
	flex-direction: column;
}

.sureButton {
	max-width: 300px;
	margin: 12px auto;
}

.npsLinkWrapper {
	display: flex;
	flex-direction: row;
	font-size: 14px;
	width: 100%;
	justify-content: left;
	@media (--viewportLarge) {
	  padding: unset;
	}
  }
  
  .copyButton,
  .copiedText {
	margin-top: auto;
	margin-bottom: auto;
  }
  
  .copyButton:hover {
	color: var(--matterColorDark);
	cursor: pointer;
  }
  
  .npsLink {
	display:flex;
	margin-right: 12px;
  }

  .npsTitle {
	min-width: 20%;
	margin-right: 6px;
	@media (--viewportMedium) {
		min-width: unset;
	}
  }
	
.expertsTitle {
	text-align: center;
	margin: 0;
}


.projectCompleted {
	font-size: 20px;
	
}


.priority{
	font-size: 13px;
	width:65px;
  	text-align: center;
	@media (--viewportLarge) {
		text-align: left;
	}
}

.medium,
.high,
.critical,
.low,
.undefined {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-top: 14px;
  margin-right: 4px;
  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.medium {
  background-color: var(--attentionColor)
}

.high {
  background-color: var(--failColor)
}

.critical {
  background-color: var(--matterColorDark)
}

.low {
  color: var(--successColor)
}

.undefined {
  background-color: var(--matterColorAnti)
}
.prioritycritical {
	justify-content: center;
	background-color: #e2c7c7;
	color: #8b0000;
  }
  
  .priorityhigh {
	justify-content: center;
	background-color: #ffeeee;
	color: #fe5454;
  }
  
  .prioritymedium {
	justify-content: center;
	background-color: #fff4eb;
	color: #ffa14f;
  }
  
  .prioritylow {
	justify-content: center;
	background-color: #eefff3;
	color: #3fcd68;
  }
  
  .notificationsSwitch {
	  display: flex;
	  flex-direction: row;
	  padding: 10px 0;
	  align-items: center;
	  justify-content: space-between;
  }

.notificationsWrapper {
	display: flex;
	flex-direction: column;
	width: 75%;
	padding: 10px;
	margin-top: 10px;
}
