@import '../../marketplace.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field,
.expandedField {
  width: 100%;
  margin-top: 24px;
}

.field {
  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.fieldTitle {
  width: 100%;
  margin-top: 24px;
}

.submitInvoiceDetails {
  margin-bottom: 200px;
  margin-top: 20px;
}

.modalHeader {
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
  text-align:center;
}

.formContainer {
  padding-left:80px;
  padding-right:80px;
}

.inputStandard{
  & input{
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #484848;
    padding:20px 15px

  } 
  & select{
 border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #484848;
    padding:20px 15px;
    background-position: right 15px center !important;
  }
}

.buttonSpacer {
  margin-top:15px;
  margin-bottom:15px;
}
