@import '../../marketplace.css';

.mainWrapper {
    width: fit-content;
    min-width: 60px;
    padding: 6px 12px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 8px;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.mainWrapper:hover {
    -webkit-box-shadow: 0 0 5px 2px rgba(0,0,0,.05);
    -moz-box-shadow: 0 0 5px 2px rgba(0,0,0,.05);
    box-shadow: 0 0 5px 2px rgba(0,0,0,.05);
}

.buttonActive {
    -webkit-box-shadow: 0 0 3px 1px rgba(0,0,0,.05);
    -moz-box-shadow: 0 0 3px 1px rgba(0,0,0,.05);
    box-shadow: 0 0 3px 1px rgba(0,0,0,.05);
    background-color: #F0F0F0;
    border: 1px solid #E9E9E9;
}

.buttonTitle {
    font-size: 14px;
    text-align: center;
}