@import '../../marketplace.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.numberWrapper {
  font-size: 14px;
  width: 25px;
  height: 25px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;  
  @media (--viewportMedium) {
    font-size: unset;
    width: 45px;
  height: 45px;
  }
}

.numberWrapper:hover {
  -webkit-box-shadow: 3px 3px 11px 1px rgba(0,0,0,0.3);
  -moz-box-shadow: 3px 3px 11px 1px rgba(0,0,0,0.3);
  box-shadow: 3px 3px 11px 1px rgba(0,0,0,0.3);  
}

.selectedNumberWrapper {
  font-size: 14px;
  width: 25px;
  height: 25px;
  background-color: var(--marketplaceColorDark);
  -webkit-box-shadow: 3px 3px 11px 1px rgba(0,0,0,0.4);
  -moz-box-shadow: 3px 3px 11px 1px rgba(0,0,0,0.4);
  box-shadow: 3px 3px 11px 1px rgba(0,0,0,0.4);  
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; 
  @media (--viewportMedium) {
    font-size: unset;
    width: 45px;
    height: 45px;
  }
}

.numberWrapper:hover {
  cursor: pointer;
}

.number {  
  color: white;
  margin-right: 1px;
  margin-bottom: 2px;
}

.npsNumbersContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.npsContainer {
  margin-top: 14px;
  padding: 12px;
box-shadow: 0 0 5px 4px rgba(0,0,0,.1);
}

.likeliness {
  display: flex;
  flex-direction: row;
}

.left {
  margin-top: 0;
  margin-left: 0;
}

.right {
  margin-top: 0;
  margin-left: auto;
  margin-right: 0;
}

.categories {
  margin-bottom: 22px;
}

/* Choose the version of NPS */

.versionChoosing {
  text-align: center;
  padding: 30% 0;
}

.versionChosen {
  margin-top: 0;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-evenly;
}

.buttonSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightRegular);

  padding: 9px 16px 10px 16px;
}

.buttonExtended {
  width: 100%;
}

.asterisk {
  color: var(--failColor)
}