@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 4px;

    @media (--viewportMedium) {
      margin-bottom: 8px;
    }
  }
  --EditListingDescriptionScopeForm_formMargins: {
    margin-bottom: 6px;
    @media (--viewportMedium) {
      margin-bottom: 6px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}


.error {
  color: var(--failColor);
}

.title {
  @apply --EditListingDescriptionForm_formMargins;
}

.scale {
  @apply --EditListingDescriptionForm_formMargins;
}

.experience {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.projectsCount {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.priority {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.company {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.expertise {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
}

.certificate {
  @apply --EditListingDescriptionForm_formMargins;
}

.software {
  margin-bottom: 8px;
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap;
}

.buttonGroupNotAuthenticated {
  margin-top: 24px;
  margin-bottom: 224px;
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    margin-bottom: auto;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  margin-right: 10px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.priceInput {
  @apply --EditListingDescriptionForm_formMargins;
  flex-shrink: 0;
  width: 100%;
}

.quickSubmitButton {
  margin-top: 50px;
  margin-bottom: 24px; 
}

.smallPrintHeader {
  text-align: left;
  border-bottom: 1px solid #b2b2b2;
  margin-right: auto;
}

.smallPrint {
  text-align: center;
}

.smallPrint,
.smallPrintHeader
 {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.smallPrintLeft {
  text-align: left;
  margin: 0;
  margin-top: 15px;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.infoIconLabel {
  margin-left: 6px;
  margin-top: 4px;
}

.features {
  margin: 10px 0 10px 0;
}

.featuresTitle {
  @apply --EditListingDescriptionForm_formMargins;
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

/* ----- SCOPE -----  */

/* JOB SCOPE */
.jobScopeWrapper {
  margin-bottom: 5vh;
}

.jobScopeTaskName {
  @apply --EditListingDescriptionScopeForm_formMargins;
  width: 100%;
  margin-right: 10px;
}
/* /JOB SCOPE */

.scopeTitle {
  margin-bottom: 12px;
}

.scopeSubtitle {
  @apply --marketplaceTinyFontStyles;
  margin-top: 0;
}

.scopeSubtitleUnderlined {
  text-decoration: underline;
  cursor: pointer;
  color: var(--matterColor);
}

.scopeWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.scopeTaskName {
  @apply --EditListingDescriptionScopeForm_formMargins;
  width: 50%;
  margin-right: 10px;
}

.scopeTaskTime {
  @apply --EditListingDescriptionScopeForm_formMargins;
  width: 20%;
  margin-right: 10px;
}

.scopeTaskPrice {
  @apply --EditListingDescriptionScopeForm_formMargins;
  width: 20%;
}

.scopeTaskButton {
  @apply --EditListingDescriptionScopeForm_formMargins;
  width: 10%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scopeTaskButtonsWrapper {
  @apply --EditListingDescriptionScopeForm_formMargins;
  width: 10%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scopeTaskButtonMin {
  width: 50%;
  margin: 0;
}

.missingAccountVerification {
  color: var(--failColor)
}

.scopeCommentWrapper {
  margin-bottom: 10px;
}

.scopeCommentText {
  font-size: 0.7em;
  color: var(--matterColor);
}

.scopeInputLabel {
  display: flex;
  flex-direction: row;
  color: var(--matterColor);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  padding-top: 6px;
  padding-bottom: 2px;
}

.scopeInputValue {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 2px solid var(--matterColor);
  padding: 4px 0 10px 0;
}

.taskPriceLabelWrapper {
  display: flex;
  flex-direction: row;
}

@media (--viewportMediumMax) {
  .scopeWrapper {
    display: grid;
    grid-template-columns: 4fr 4fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
      "scopeTaskName scopeTaskName scopeTaskName"
      "scopeTaskTime scopeTaskPrice scopeTaskButton";
  }
  .scopeTaskName {
    grid-area: scopeTaskName;
    width: 100%;
  }
  .scopeTaskTime {
    grid-area: scopeTaskTime;
    width: 90%;
    margin-right: 10px;
  }
  .scopeTaskPrice {
    grid-area: scopeTaskPrice;
    width: 100%;
  }
  .scopeTaskButtonsWrapper {
    grid-area: scopeTaskButton;
    width: 100%;
  }
  .scopeInputValue {
    padding: 5px 0 5px 0;
  }
  .scopeInputLabel {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* ----- /SCOPE -----  */


.button {
  margin-right: 0;
}

.primarySoftwareError {
  @apply --marketplaceEnquiryFontStyles;
  color: var(--failColor);
}

.primarySoftwareError {
  font-size: 17px;
}

.formError {
  margin-top: 8px;
  margin-bottom: 8;
  color: var(--failColor);
  font-size: 0.8em;
}

.projects {
  margin-bottom: 14px;
}

.projectsFieldCenter {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.jobScopeSuggestWrapper {
  width: 75%;
  margin-bottom: 13px;
}

.unknownSoftware,
.languages{
  margin-bottom: 24px;
}

.firstRow,
.secondRow {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.secondRowElement {
  width: calc(50% - 8px);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  font-size: 18px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--successColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.imagesField {
  margin-top: 40px;
  flex-shrink: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: 30%;
    margin: 0 24px 24px 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 30%;
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightRegular);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sections {
  width: 100%;
  border-collapse:separate; 
  border-spacing: 0 36px;
}

.sectionTitle {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  color: var(--matterColorAnti);

  margin-top: 18px;
  margin-bottom: 18px;
  padding: 3px 0 3px 0;
  display: flex;
}

.sectionContent {
  @media (--viewportMedium) {
    width: 80%;
  }
}

.sections tr {
  margin-bottom: 20px;
}

.panelTitle {
  @apply --marketplaceH1FontStyles
}

.required {
  color: var(--failColor)
}

.creatingServiceInfo {
  @apply --marketplaceTinyFontStyles;
}