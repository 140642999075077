@import '../../marketplace.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & li {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 23px 0 19px 0;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 10px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}

/* p, li {
  text-align: justify;
} */

h3 {
  text-align: center;
}

.dash {
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
}
.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}