@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
  --EditListingDescriptionScopeForm_formMargins: {
    margin-bottom: 6px;
    @media (--viewportMedium) {
      margin-bottom: 6px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  @apply --marketplaceDefaultFontStyles;
  color: var(--failColor);
}

.buttonGroupNotAuthenticated {
  margin-top: 24px;
  margin-bottom: 224px;
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMedium) {
    margin-bottom: auto;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  margin-right: 10px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.button {
  margin-right: 0;
}

.projectWrapper,
.projectsButtonWrapper {
  display: flex;
  flex-direction: row;
}

.projectFirstRowWrapper {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.projectFirstRowWrapper {
  justify-content: space-between;
}

.projectWrapper {
  margin-bottom: 26px;
}

.fieldsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.projectsButtonWrapper {
  margin: 15px auto auto auto;
  min-width: 2.6em;
}

.addProjectButton {
  margin-right: 0;
}

.removeProjectButton {
  margin-left: 0;
}

.projectUrl {
  margin-bottom: 12px;
}

.experienceTitle,
.experienceCompany,
.yearsTitle {
  width: 100%;
  @media (--viewportMedium) {
    width: calc(33% - 12px);
  }
}

.projectTitle,
.projectCompany {
  width: 100%;
  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}

.removeProjectButton:hover,
.addProjectButton:hover {
  cursor: pointer;
}