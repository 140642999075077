@import '../../marketplace.css';

.serviceScopeTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    margin-top: 0;
    margin-bottom: 13px;
    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 20px;
    }
}
  
.sectionServiceScopeWrapper {
    display: flex;
    flex-direction: column;
}
  
.serviceScopeContainer {
    display: flex;
    flex-direction: column;
}
  
.scopeItemWrapper {
    display: flex;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.boldText {
    font-weight: var(--fontWeightMedium);
}
  
.scopeItemIcon {
    width: 10%;
}
  
.scopeItemTask {
    width: 60%;
}
  
.scopeItemTaskWide {
    width: 80%;
}
  
.scopeItemTaskFull {
    width: 90%;
}
  
.scopeItemTime {
    width: 18%;
}
  
.scopeItemPrice {
    width: 12%;
}

.scopeSubtaskWrapper {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.scopeSubtaskEmptyWrapper {
    width: 10%;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
}

.scopeSubtaskIcon {
    width: 5%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.scopeSubtask {
    width: 55%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.scopeSubtaskWide {
    width: 75%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.scopeSubtaskTime {
    width: 18%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.scopeSubtaskPrice {
    width: 12%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

  
.scopeItemSummaryWrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}
  
.scopeItemSummaryTitle {
    width: 18%;
    font-weight: var(--fontWeightMedium);
}
  
.scopeItemSummaryPrice {
    font-weight: var(--fontWeightMedium);
}
  
.scopeSmallPrintImportant {
    color: #555555;
}

.noMarginHeading {
    margin: 0px;
    margin-bottom: 6px;
}

.smallPrint {
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColorAnti);
    flex-shrink: 0;
  
    @media (--viewportMedium) {
      margin-top: auto;
      margin-bottom: 20px;
    }
  
    @media (--viewportLarge) {
      margin-top: 4px;
      margin-bottom: 21px;
    }
}
  
.smallPrintHeading {
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColorAnti);
    flex-shrink: 0;
  
    @media (--viewportMedium) {
      margin-bottom: 6px;
    }
}

.scopeSmallPrintImportant {
    color: #555555;
}

.infoIcon {
    vertical-align: text-top;
    margin-left: 6px;
}
  
@media (--viewportMediumMax) {
    .scopeItemSummaryWrapper,
    .scopeItemWrapper,
    .scopeSubtaskWrapper {
      font-size: 16px;
    }
    .scopeItemIcon {
      width: 7%;
    }
    .scopeItemTask {
      width: 53%;
    }
    .scopeItemTime {
      width: 25%;
    }
    .scopeItemPrice {
      width: 15%;
      text-align: left;
    }
    .scopeSubtaskEmptyWrapper {
        width: 2%;
    }
    .scopeSubtaskIcon {
        width: 5%;
    }
    .scopeSubtask {
        width: 53%;
    }
    .scopeSubtaskTime {
        width: 25%;
    }
    .scopeSubtaskPrice {
        width: 15%;
    }
    .scopeItemSummaryTitle {
      width: 25%;
    }
    .scopeItemSummaryPrice {
      width: 15%;
    }
}
  
@media (--viewportSmallMax) {
    .scopeItemSummaryWrapper,
    .scopeItemWrapper,
    .scopeSubtaskWrapper {
      font-size: 16px;
    }
}

.projects {
    display: flex;
    flex-direction: row;
}
  
.projectsSideLine,
.projectsLastSideLine {
    width: 1px;
    background-color: var(--chipPrimaryColor);
    margin-left: 4px;
}
  
.projectsSideLine {
    height: 110%;
}
  
.projectsLastSideLine {
    height: 75%;
}

.projectsNoSideLine {
    height: 0;
}

.projectPointer {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--marketplaceColor);
}

.projectImage {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.projectWrapper {
    width: 100%;
    margin-left: 15px;
    min-height: 40px;
    margin-bottom: 10px;
}

.projectPointer {
    color: var(--marketplaceColor)
}

.projectFirstRow {
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #1111;
}

.projectSideWrapper {
    margin-top: 9px;
}

.projectDescription {
    color: var(--matterColorAnti)
}

.projectTitle {
    font-size: 16px;
}
.subtask {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.taskDetails {
    display: flex;
}
.projectTaskTime {
    margin-left: 25px;
  /* width: 60px; */
}

.projectTaskPrice {
  margin-left: 25px;
  /* width: 60px; */
}