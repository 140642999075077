@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
}
.content {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.profileLink {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;

  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;

  float: right;
  flex-shrink: 0;

  margin: 19px 0 0 auto;
  padding: 9px 16px 8px 16px;

  @media (--viewportMedium) {
    margin: 37px 0 0 auto;
  }
}


.removeConfiramtion {
  border-radius: 8px;
  border: 2px solid #bbb;
  padding: 1rem;
  background: #f7f7f7;

  & button {
    background: #42aafc;
    border-radius: 4px;
    padding: 4px 10px;
    border: 1px solid #9d9d9d;
    margin-right: 10px;
    color: #fff;
    margin-top: 10px;
  }
}
.alert {
  font-size:12px;
  color:red;
}