@import '../../marketplace.css';

.mainWrapper {
    width: 570px;
    height: 150px;
    font-size: 0.6em;
    background-color: #f7f7f7;
    z-index: 2000;
    border-radius: 5px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    /* animation */
    left: -400px;
    visibility: hidden;
    opacity: 0;
    -webkit-animation: slide 0.9s forwards;
    -webkit-animation-delay: 5s;
    animation: slide 0.9s forwards;
    animation-delay: 5s;
}

@-webkit-keyframes slide {
    100% { left: 15px; opacity: 1; visibility: visible; }
}

@keyframes slide {
    100% { left: 15px; opacity: 1; visibility: visible; }
}

.buttonWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.readStoryButton {
    width: 70px;
    margin-top: 0px;
    margin-right: 0px;
    border: solid 2px #2ecc71;
    background-color: #2ecc71;
    color: white;
    font-size: 1em;
    border-radius: 5px;
    padding: 4px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
}

.readStoryButton:hover {
    /* box-shadow: var(--boxShadowButton); */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    background-color: var(--successColorDark);
    border-color: var(--successColorDark);
    text-decoration: none;
}

.link {
    text-decoration: none !important;
}

.closeButton {
    position: absolute;
    top: 3px;
    right: 12px;
    cursor: pointer;
}

.arrowRightButton {
    position: absolute;
    bottom: 5px;
    right: 12px;
    cursor: pointer;
}

.arrowLeftButton {
    position: absolute;
    bottom: 5px;
    right: 40px;
    cursor: pointer;
}

.mainContent {
    margin: 10px;
    line-height: 1.3em;
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
    text-rendering: optimizeSpeed;
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: 1fr;
    grid-template-areas: 
      "profileImage profileDescription";
}

.profileImageWrapper {
    grid-area: profileImage;
}

.profileImage {
    border: solid 0px #f7f7f7;
    background-color: #f0f0f0;
    margin: 15px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    z-index: 20;
    overflow: hidden;
    display: flex;
    flex-direction: center;
    align-items: center;
    box-shadow: 2px 2px 2px  rgba(0,0,0,0.3);
}

.img {
    border: solid 3px #f7f7f7;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    flex-direction: center;
    align-items: center;
}

.profileImageShadow {
    border: solid 1px #3D89F9;
    background-color: #3D89F9;
    margin: 20px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
}

.profileDescriptionWrapper {
    grid-area: profileDescription;
}

.profileDescriptionTitle {
    font-weight: var(--fontWeightMedium);
}

.profileDescriptionQuote {
    font-style: italic;
}

.profileDescriptionDate {
    font-size: 0.7em;
    margin-top: 5px;
}

.starsWrapper {
    margin-top: 5px;
    margin-bottom: 5px;
}

@media (max-width: 800px) { 
    .mainWrapper {
        width: 400px;
        height: 120px;
        font-size: 0.5em;
        line-height: 1.8em;
        padding-right: 10px;
    }
    .mainContent {
        grid-template-columns: 75px auto;
    }
    .profileImage {
        margin: 10px;
        width: 50px;
        height: 50px;
    }
    .img {
        width: 50px;
        height: 50px;
    }
    .profileImageShadow {
        margin: 15px;
        width: 50px;
        height: 50px;
    }
    .readStoryButton {
        width: 60px;
        padding: 4px 0px;
    }
    .arrowRightButton {
        right: 16px;
    }
    .arrowLeftButton {
        right: 38px;
    }
}

@media (max-width: 500px) { 
    .mainWrapper {
        width: 100%;
        height: 180px;
        font-size: 0.5em;
        line-height: 1.6em;
        border-radius: 0px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        box-shadow: 0 0 0 0;
    }
    @-webkit-keyframes slide {
        100% { left: 0px; opacity: 1; visibility: visible; }
    }
    @keyframes slide {
        100% { left: 0px; opacity: 1; visibility: visible; }
    }
    .profileImage {
        margin: 10px;
        width: 50px;
        height: 50px;
    }
    .img {
        width: 50px;
        height: 50px;
    }
    .profileImageShadow {
        margin: 15px;
        width: 50px;
        height: 50px;
    }
    .readStoryButton {
        margin-top: 5px;
        padding: 4px 6px;
    }
    .arrowRightButton {
        bottom: unset;
        top: 5px;
        right: 40px;
    }
    .arrowLeftButton {
        bottom: unset;
        top: 5px;
        right: 60px;
    }
}

@media (max-width: 380px) { 
    .readStoryButton {
        margin-top: 5px;
        padding: 2px 2px;
        font-size: 0.8em;
    }
}