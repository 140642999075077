@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.offer {
    width: calc(100% - 48px);
    margin: 0 24px;
    flex-shrink: 0;

    @media (--viewportLarge) {
      width: 100%;
      margin: auto;
    }
}

.price {
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: left;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.smallPrintWarning {
  @apply --marketplaceTinyFontStyles;
  color: var(--failColor);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.smallPrintAttention {
  @apply --marketplaceTinyFontStyles;
  color: var(--attentionColor);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}

.newSubmitButtonWrapper {
  background-color: white;
}

.priceInput {
  flex-shrink: 0;
  width: calc(100% - 48px);
  margin: 0 24px;
  @media (--viewportLarge) {
    width: 100%;
  margin: auto;
  }
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.infoIconPlaceholder {
  margin: auto auto auto 6px
}

.submitButton {
  margin-bottom: 10px;
}
.bookACall {
  font-size:12px;
  text-align:center;
}
