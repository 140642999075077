@import '../../marketplace.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: visible;

  @media (--viewportLarge) {
    padding: 113px 0 82px 36px;
    flex-direction: column;
    overflow-x: visible;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 13px 0 82px 50px;
  }
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    min-width:40px;
    padding-right: 2px !important;
  }
}

.navigationNone {
  display: none !important;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  margin-bottom: 24px;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  /*top: 13px;*/
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */
  margin-bottom: 6px;

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
  border-radius: var(--borderRadius);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 12px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 12px;
  }
}

.editListingLink {
  &:hover {
    text-decoration: none;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.singleIcon {
  margin: 0px 8px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  width: 100%;
  margin: 0px 0px;
  @media (--viewportMedium) {
    /* max-width: 1056px; */
    margin: 0px 0px;
    padding: 0 0px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    margin: 0px 0px 117px;
  }

  @media (--viewportXLarge) {
    /* max-width: 1056px; */
    padding: 0;
    margin: 135px 0px 117px;
  }
}

.mainContent {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 30px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 0px;
    flex-grow: 1;
  }
}

.avatarDesktop {
  /* display: none; Hide the large avatar from the smaller screens */
  display: flex;
  width: 190px;
  height: 190px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 30px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  flex-grow: 1;
  flex-direction: row;
  display: inline-flex;
  margin-bottom: 5px;
  max-width: 83%;
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
 
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display:inline;
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.sectionDescription,
.sectionBenefits {
  padding: 0 8px;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.descriptionTitle,
.benefitsTitle {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  @apply --marketplaceDefaultFontStyles;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 450px; 

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 650px; 
  }

  @media (--viewportLarge) {
    max-width: 750px; 
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.reminder {
  margin-bottom: 10px;
}

.goBackButton {
  display: flex;
  align-items: center;
  max-width: 200px;
  outline:none;
  &:hover {
    text-decoration: none;
  }
}

.sectionShare {
  padding: 0;
  margin-bottom: 35px;
  @media (--viewportMedium) {
    margin-top: 52px;
    margin-left: 0;
  }
}

.shareContent {
  width: 278px;
  min-height: 52px;
  border-radius: var(--borderRadius);
  padding: 17px 30px; 
  margin: auto auto 0 auto;
  background-color: var(--marketplaceColorVeryLight);
  border: 1px solid var(--matterColorAnti);
  display: flex;
  flex-direction: row;
}

.shareTitle {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  font-size: 14px;
  color: var(--matterColor);
  margin: 0 14px 0 0;
}

.shareButtonsPanel {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.shareButton {
  width: calc(25% - 3px);
}

.shareIcon:hover {
  color: var(--marketplaceColor);
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.priceTimeHeading {
  @apply --marketplaceDefaultFontStyles;
  margin-left: 8px;
}

/* New Design */

.pageWrapper {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
  justify-content: center;

  @media (--viewportLarge) {
    /* min-width: 1000px; */
    max-width: 1300px;
  }
}

.rowsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportLarge) {
    margin-right: 60px;
  }
}

.goBack {
  display: flex;
  flex-direction: row; 
  margin-top: 8px;
  /* margin-right: auto; */

  @media (--viewportMedium) {
    margin-top: 29px;
    padding: 0px 12px 0px;
  }

  @media (--viewportLarge) {
    padding: 0px 36px 0px;
  }
}

.goBackIcon {
  fill: none;
  stroke: var(--marketplaceColor);
  margin-right: 4px;
}

.sideBorderWrapper {
  @media (--viewportMedium) {
    border-right: 1px solid var(--matterColorAnti)
  }
}

.firstRow {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 0px;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
  }

  @media (--viewportLarge) {
    padding: 12px 36px 24px;
  }
}

.secondRow {
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: center;
  padding: 0;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 12px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.sectionSideActionBar {
  display: flex;
  flex-direction: column;
}

.sectionCTA {
  display: none;
  @media (--viewportLarge) {
    display: block;
    min-width: 272px;
  }
}

.sectionCTAMargin {
  margin-top: 20px;
}

.mainContentWrapper {
  /* flex-basis: 100%; */
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;

  @media (--viewportMedium) {
    flex-grow: 1;
    align-items: flex-start;
  }
}

.sectionExpertise {
  padding: 0 24px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 16px;
  }
}

.expertise {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 0px;
  @media (--viewportMedium) {
    margin-right: 18px;
    align-items: flex-start;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  text-align: right;
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 42px;
    line-height: 55px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.editButton {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 225px;
  margin-bottom: 8px;
  @media (--viewportLarge) {
    width: 100%;
  }
}

.getFrameButton {
  background-color: white;
  color: rgb(74,74,74, 0.9);
  border: 1px solid #e7e7e7;
  &:hover {
    background-color: white;
    color: rgb(74,74,74, 0.9);
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  }
}

.editIconFeather {
  fill: none;
  margin-right: 15px;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 6px;
}

.newListingLink:hover {
  text-decoration: none;
}

.chip {
  margin: 4px 10px 4px 0px;
}

/* ---------- SERVICE PAGE ---------- */
.priceTimeWrapper {
  display: flex;
  flex-direction: row;
}

.sectionPriceTime {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  color: var(--matterColor);
  @media (--viewportMedium) {
    display: flex;
    margin-top: 10px;
    width: 90%;
  }
  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.priceTimeValue {
  @apply --marketplaceDefaultFontStyles;
  color: var(--marketplaceColor);
  margin: auto 0 auto auto;
  display: flex;
  justify-content: center;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
}

.priceTimeIcon {
  margin: auto 0;
}

.mobileServiceAuthor {
  @media (--viewportMedium) {
    display: none;
  }
}

.sectionServiceAuthor {
  display: flex;
  width: 100%;
  @media (--viewportMedium) {
    display: flex;
    margin-top: 29px;
  }
}

.sectionServiceAuthor {
  display: flex;
  flex-direction: column;
}

.serviceAvatarWrapper {
  margin-top: 6px;
  display:flex;
  flex-direction: column;
}

.serviceAvatarAspectWrapper {
  position: relative;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
}

.supportPlanProvider {
  display: flex;
  padding: 10px 0 0 0;
  font-size: 1em;
}

.serviceExpertName {
  @apply --marketplaceH1FontStyles;
  text-align: center;
  margin-top: 15px;
}

.serviceExpertTitle {
  padding-left: 10px;
  font-size: 0.8em;
}

.serviceAvatarDesktop {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  border-radius: 50%;
  /* Position possible initials to the center of the component */
  display: flex;
  width: 140px;
  height: 140px;
  align-items: center;
  justify-content: center;
  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);
  &:hover {
    text-decoration: none;
  }

  @media(--viewportMedium) {
    width: 200px;
    height: 200px;
  }

}

.serviceInitialsDesktop {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  @media (--viewportMedium) {
    font-size: 18px;
    font-weight: var(--fontWeightRegular);
  }
}

.serviceExpertLink {
  color: var(--matterColor); /* Loading BG color */
  &:hover {
    text-decoration: none;
  }
}

.serviceScopeTitle {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 13px;
  }
}

.sectionServiceScopeWrapper {
  display: flex;
  flex-direction: column;
}

.serviceScopeContainer {
  display: flex;
  flex-direction: column;
}

.scopeItemWrapper {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.scopeItemIcon {
  width: 10%;
}

.scopeItemTask {
  width: 60%;
}

.scopeItemTaskWide {
  width: 80%;
}

.scopeItemTaskFull {
  width: 90%;
}

.scopeItemTime {
  width: 18%;
}

.scopeItemPrice {
  width: 12%;
}

.scopeItemSummaryWrapper {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.scopeItemSummaryTitle {
  width: 18%;
  font-weight: var(--fontWeightMedium);
}

.scopeItemSummaryPrice {
  width: 12%;
  font-weight: var(--fontWeightMedium);
}

.scopeSmallPrintImportant {
  color: #555555;
}

@media (--viewportMediumMax) {
  .scopeItemSummaryWrapper,
  .scopeItemWrapper {
    font-size: 0.8em;
  }
  .scopeItemIcon {
    width: 7%;
  }
  .scopeItemTask {
    width: 53%;
  }
  .scopeItemTime {
    width: 25%;
    text-align: center;
  }
  .scopeItemPrice {
    width: 15%;
    text-align: center;
  }
  .scopeItemSummaryTitle {
    width: 25%;
  }
  .scopeItemSummaryPrice {
    width: 15%;
  }
}

@media (--viewportSmallMax) {
  .scopeItemSummaryWrapper,
  .scopeItemWrapper {
    font-size: 0.8em;
  }
}

.viewExpertButtonWrapper {
  position: absolute;
  bottom: 0;
  right: 0; 
}

.viewExpertButton {
  @apply --marketplaceTinyFontStyles;
  min-height: 25px;
  width: 80px;
}

.linkViewExpert {
  outline:none;
  text-decoration: none;
  margin-left: 5%;
  margin-right: 5%;
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

.sectionExpertServices {
  margin-top: 20px;
  padding: 0 8px;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.addServiceModalText {
  font-size: 18px;
  margin-bottom: 24px;
}

.linkStyled {
  @apply --marketplaceLinkStyles;
}

.newExpertReadMoreLink {
  text-align: center;
  font-size: 14px;
  display: block;
  margin-top: 20px;
}

/* NEW STYLING FOR SERVICE PAGE */ 
.sideBar {
  display: none;
  @media (--viewportMedium) {
  display: flex;
  flex-direction: column;
  }
}

.benefit {
  display: flex;
  flex-direction: row;
}

.benefitWrapper {
  margin-left: 15px;
  min-height: 40px;
  margin-bottom: 10px;
}

.benefitPointer {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--matterColor);
}

.benefitFirstRow {
  margin-bottom: 8px;
  margin-left: 8px;
}

.benefitSideWrapper {
  margin-top: 5.5px;
}

.benefitSideLine {
  width: 1px;
  background-color: var(--matterColorAnti);
  margin-left: 4px;
  height: 100%;
}

.sectionTags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}
