@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
    margin-top: 8vh;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 40px;
}


/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.stepsContainer {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  width: 100%;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}




.benefit {
  display: flex;
  margin-bottom: 15px;
  margin-left: 10px
  
}



.benefits {
  border-left: 2px solid var(--marketplaceColor);
  
}

.summarize {
  color: var(--marketplaceColor);
  
}


.step {
  /* width: 140px; */
  margin-right: 8px;
  margin-bottom: 10px;
  display: flex;
  @media (--viewportMedium) {
    display: block;
  }
}

.pointedLine {
  display: flex;
  align-items: center;
}

.point{
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--matterColorAnti);
}

.pointCompleted {
  background-color: var(--marketplaceColor);
}



.marginBottom {
   margin-bottom: 15px;
}
.line {
  width: 175px;
  height: 2px;
  background-color: var(--matterColorAnti);
  margin-left: 5px;
  display: none;
  @media (--viewportMedium) {
    display: block
  }
}

.lineCompleted {
  background-color: var(--marketplaceColor);
}

.stepTitle {
  color: var(--matterColorAnti);
  margin-left: 10px;
  font-size: 16px;

  @media (--viewportMedium) {
    margin-left: 0;
    margin-top: 5px;
  }
}

.stepTitleCurrent {
  color: var(--matterColor);
  /* color: var(--marketplaceColor); */
}

.mainInfo {
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}

.contactTitle {
  font-size: 16px;
  margin-top: 32px;
}

.buttonsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.contactButton {
  width: 200px;
  min-height: 50px;
  max-height: 50px;
  margin-right: 20px;
  margin-bottom: 10px;;
}

.buttonLink {
  font-size: 16px;
  color: var(--matterColor);
  &:hover {
    text-decoration: none;
  }
}
.buttonLinkPrime {
  font-size: 16px;
  color: white;
 
  &:hover {
    text-decoration: none;
  }
}

.buttonIcon, .infoIcon, .bellIcon {
  vertical-align: text-top;
}

.buttonIcon {
  margin-right: 5px;
}

.bigButton {
  width: 275px;
  margin: 0 auto 20px;
}

.infoIcon {
	margin-left: 5px;
}

.tooltipFont {
  font-size: 20px;
}

.jobLink:hover,
.jobLinkDisabled:hover {
  text-decoration: none;
}

.jobLinkDisabled {
  pointer-events: none;
}