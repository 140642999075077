.root {
    /* Dimensions */
    width: 100%;
    height: auto;
  
    /* Layout */
    display: flex;
    flex: 1;
    flex-direction: column;
  
    padding-top: 8px;
  
    @media (--viewportMedium) {
      padding-top: 8px;
    }
  }

  .button {
      margin-top: 24px;
  }

  .formWrapper {
    border: 1px solid #e7e7e7;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  }