@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 47px;
  }
}

.sectionSeparator {
    height:1px;
    background:#e5e5e5;
    margin: 1rem 0;
}

h3 {
    text-align: left;
    font-size:1rem !important;
}

.smallParagraf{
    & p {
        font-size: 1rem !important;
    }
    
}