@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.linkClass:hover {
  text-decoration:none;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 30%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  width: 60px;
  height: 20px;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  @media (--viewportSmall) {
    width: 96px;
    height: 32px;
  }
}

/* ---------- SERVICE CARD STYLES ------ */

.serviceMain,
.expertPageServiceMain {
  margin-top: 10px;
  margin-right: 12px;
  width: 100%;
  display: grid;
  grid-template-rows: auto 50px;
  grid-template-areas:
    "logo main"
    "logo details";
}

.serviceMain {
  grid-template-columns: 140px auto;
}

.expertPageServiceMain {
  grid-template-columns: auto auto auto;
}

.serviceLogoSection {
  grid-area: logo;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.serviceAvatarWrapper {
  position: relative;
  width: 100%;
  height: 80%;
  @media (--viewportSmallMax) {
    height: 70px;
  }
}

.serviceAvatarAspectWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serviceProvidedByBarWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.serviceProvidedByBar {
  width: 100%;
  height: 15%;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  color: rgb(74,74,74, 0.9);
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  font-size: 0.45em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: auto;
  @media (--viewportSmallMax) {
    height: 20px;
  }
}

.serviceMainContentSection {
  grid-area: main;
  margin-top: 20px;

}

.serviceDetailsSection {
  grid-area: details;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.servicePriceTimeWrapper,
.serviceExpertPagePriceTimeWrapper {
  display: flex;
  flex-direction: column;
  height: 50px;
}

.servicePriceTimeWrapper {
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
}

.serviceExpertPagePriceTimeWrapper {
  margin-top: 0;
  flex-direction: column;
  text-align: right;
}

.servicePriceWrapper,
.serviceTimeWrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
}

.servicePriceTimeSpace {
  width: 20px;
}

.servicePriceTimeTitle {
  @apply --marketplaceTinyFontStyles;
  font-size: 11px;
  color: var(--matterColor);
  margin: 0px;
}

.servicePriceTimeValue {
  @apply --marketplaceH4FontStyles;
  margin: 0px;
}

.serviceAuthorWrapper {
  display: flex;
  flex-direction: column;
}

.serviceAuthorSubWrapper {
  display: flex;
  flex-direction: row;
}

.serviceAuthorText {
  display: inline-block;
  font-size: 0.7em;
  color: var(--matterColor);
}

.serviceAuthorAvatarWrapper {
  display: inline-block;
}

.serviceAuthorAvatar {
  border-radius: 50%;
  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);
  width: 26px;
  height: 26px;
}

/* ---------- JOB CARD STYLES ---------- */

.cardRootWrapper {
  width: 100%;
  position: relative;
}

.cardBookmark {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20%;
  height: 30px;
  margin-left: 15px;
  border: solid 1px #e5e5e5;
  color: var(--matterColor);
  background-color: var(--matterColorLight);
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 0px 5px;
  font-size: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  /* font-weight: var(--fontWeightMedium); */
}

.jobMain {
  margin-top: 10px;
  margin-right: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-rows: auto 50px;
  grid-template-areas:
    "logo-budget main"
    "logo-budget details";
}

.jobLogoBudgetSection {
  grid-area: logo-budget;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
}

.jobLogoAspectWrapper {
  width: 100px;
  height: 60px;
  background: var(--matterColorNegative); /* Loading BG color */
  position: relative;
  overflow: hidden;
}

.jobRootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
}

.jobBudgetWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-top: 20px;
  margin-right: 0px;
  height: 50px;
  align-items: center;
}

.solvedWrapper {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLight);
  justify-content: flex-start;
  flex-shrink: 0;
  justify-content: space-evenly;
  margin-right: 0px;
  margin-top: 24px;
  height: 50px;
  @media (--viewportLarge) {
    @apply --marketplaceMessageFontStyles;
  }
}

.jobBudgetTitle {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  margin: 0px;
}

.jobBudgetValue {
  @apply --marketplaceH3FontStyles;
  margin: 0px;
}

.jobNewStatusChipWrapper {

  width: 55px;
  margin-left: 10px;
  margin-top: 2px;
}

.jobDetailsSection {
  grid-area: details;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.jobLocationRootWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.jobLocationWrapper {
  display: flex;
  align-items: center;
}

.jobMainContentSection {
  grid-area: main;
}
.titleInRow {
   display: flex;
}

.jobTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 5px;
  flex-direction: column;
  display: flex;
}

.sectionOfferTags {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  /* grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3; */
  color: var(--marketplaceColor);
  font-size: 0.8em;
  margin-right: 18px;
}
.offersCountWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.offersValue {
  color: var(--marketplaceColor);
  font-size: 1em;
  margin: 0em;
  padding: 0;
  line-height: 20px;
  text-align: center;
}

.offersSubtitle {
  @apply --marketplaceTinyFontStyles;
  white-space: nowrap;
  color: var(--matterColor);
  font-size: 0.8em;
  margin: 0em;
  padding: 0;
  line-height: 20px;
}

.priority {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  font-size: 0.8em;
  padding: 0;
  line-height: 20px;
  margin: auto 30px 0 auto;
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
    margin: auto 30px 0 auto;
  }
}

@media (--viewportMediumMax) {
  .jobTitle {
    @apply --marketplaceH4FontStyles;
  }
}

@media (--viewportSmallMax) {
  .jobMain {
    grid-template-columns: 90px auto;
  }
  .jobLogoAspectWrapper {
    width: 70px;
    height: 40px;
  }
  .jobBudgetTitle {
    font-size: 0.7em;
  }

  .jobTitle {
    @apply --marketplaceH5FontStyles;
    margin-top: 0px;
  }
  .location {
    font-size: 0.7em !important;
  }
  .mapIcon {
    margin-right: 2px !important;
  }
  .offersCountWrapper {
    font-size: 0.8em;
    margin-left: 10px;
  }
  .priceValueText {
    font-size: 0.7em;
  }
}

/* ---------- NEW EXPERT CARD STYLES ---------- */

.expertMain {
  margin-top: 10px;
  margin-right: 12px;
  width: 100%;
  display: grid;
  grid-template-columns: 140px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "avatar-budget main";
}

.expertAvatarBudgetSection {
  grid-area: avatar-budget;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-right: 20px;
}

.expertAvatarAspectWrapper {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.expertRootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
}

.expertBudgetWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  margin-top: 10px;
  margin-right: 0px;
  height: 50px;
}

.expertBudgetTitle {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  margin: 0px;
}

.expertBudgetValue {
  @apply --marketplaceH3FontStyles;
  margin: 0px;
}

.expertNameWrapper {
  display: flex;
}

.expertNewStatusChipWrapper {
  margin-top: 3px;
  display: flex;
}

.expertDetailsSection {
  grid-area: details;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.expertLocationRootWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.expertLocationWrapper {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.expertMainContentSection {
  grid-area: main;
}

.expertName {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
  margin-top: 6px;
  margin-right: 10px;
}

.unknowBudgetSmallText {
  font-size: 0.75em;
}

@media (--viewportMediumMax) {
  .expertMain {
    grid-template-columns: 110px auto;
  }
}

@media (--viewportSmallMax) {
  .expertMain {
    grid-template-columns: 90px auto;
  }
  .avatarDesktop {
    width: 60px !important;
    height: 60px !important;
  }
  .expertAvatarBudgetSection {
    padding-right: 10px;
  }
  .expertAvatarAspectWrapper {
    margin-left: 0px;
    align-items: center;
  }
  .experiencedChip, .newChip {
    visibility: hidden;
    width: 0;
  }
  .unknowBudgetSmallText {
    font-size: 0.6em;
  }
}

/* ---------- EXPERT CARD STYLES ---------- */

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  border-radius: var(--borderRadius);
  @media (--viewportSmall) {
    height: 64px;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 16px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 0px;
  height: 50px;
  @media (--viewportSmall) {
    margin-right: 18px;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  text-align: right;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.listingCardContent {
  display: flex;
}

.main {
  margin-right: 12px;
  display: grid;
  grid-template-columns: 60px auto auto;
  width: 100%;
  @media (--viewportSmall) {
    grid-template-columns: 96px auto auto;
  }
}

.sectionAvatarAndLinks {
  grid-row-end: span 1;
  display: flex;
  flex-direction: column;
  margin: 12px 0px;

  @media (--viewportMedium) {
    grid-row-end: span 2;
  }
}

.sectionLocationAndSkills {
  grid-column-end: span 3;
  align-self: end;
  @media (--viewportSmall) {
    grid-column-end: span 2;
    margin-left: 0px;
  }
  @media (--viewportMedium) {
    grid-column-end: span 1;
    margin-left: 24px;
  }
}

.avatarWrapper {
  /* Layout */
  display: block;
  width: 100%;
  height: 96px;
  position: relative;
}

.sectionMainContent {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    margin-left: 24px;
  }
}

.sectionLocation {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 35px;
  }
}

.sectionExpertise {
  margin-bottom: 8px;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.sectionBudget {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: auto;
  grid-row-end: span 1;

  @media (--viewportMedium) {
    justify-content: flex-start;
    grid-row-end: span 1;
  }
}

.sectionSoftwares {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin-top: auto; */
}

.firstLine {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  @media (--viewportSmall) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.chips {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  @media (--viewportSmall) {
    margin-left: 10px;
    margin-bottom: 0px;
  }
}

.primaryChip {
  @apply --marketplaceChipStylesPrimary;
  margin-right: 10px;
}

/* .experiencedChip, .newChip { */
/*   align-self: flex-start; */
/* } */

.icon {
  fill: none;
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.mapIcon {
  stroke: var(--matterColorAnti);
  fill: none;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.name {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 5;
  margin-right: 2px;

  @media (--viewportMedium) {
    @apply --marketplaceH3FontStyles;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.location {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColorAnti);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    @apply --marketplaceH4FontStyles;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.expertise {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  letter-spacing: unset;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    @apply --marketplaceH3FontStyles;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.desktopView {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}

.mobileView {
  display: flex;
  margin-bottom: 16px;
  margin-top: 10px;
}

.avatarDesktop {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);

  &:hover {
    text-decoration: none;
  }

  width: 70px;
  height: 70px;
  @media (--viewportMedium) {
    width: 85px;
    height: 85px;
  }
}

.initialsDesktop {
  font-size: 26px;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 5px;
  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: var(--fontWeightRegular);
    padding-bottom: 6px;
  }
}

.initialsServiceAuthor {
  font-size: 12px;
}

.solvedIcon {
  margin-top: auto;
  margin-bottom: 5px;
  margin-right: 6px;
}

.smallPrintHeading {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  margin: 8px 6px auto 0;
}

.dates {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 6px;
  margin-top: 0;
}

.serviceShortDescription {
  @apply --marketplaceDefaultFontStyles;
  font-size: 15px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  color: var(--matterColor);
  word-break: break-all;
}

.whatWillBeDone {
  margin-right: 8px;
  margin-bottom: 3px;
}
