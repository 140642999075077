@import '../../../marketplace.css';
@import './panelWrappers.css';

.sliderTitleWrapper {
    margin-bottom: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em;
}

.sliderTitleWrapper > * {
    margin-bottom: 2vh;
}

.subtitleWrapper {
    margin: 1vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.subtitleText {
    width: 90%;
    font-size: 0.7em;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.lightboxButtonWrapper {
  margin: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleButton {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 50%;
  background-color: var(--marketplaceColorLight);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.2vw;
  cursor: pointer;
}

.ctaWrapper {
    margin-top: 1vh;
    margin-bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createListingLinkCTA {
    @apply --marketplaceH4FontStyles;
    color: var(--marketplaceColor);
    margin-top: 0;
    margin-bottom: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: var(--marketplaceColorDark);
      text-decoration: none;
    }
  }

  .ctaButton {
    padding: 5px 6px 5px 6px;
    border-radius: 5px;
    white-space: nowrap;
    width: 16vw;
    height: 6vh;
    background-color: var(--successColor);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1vw;
    font-size: 1.1em;
    &:hover {
      background-color: var(--successColorDark);
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

  .companiesHeadingWrapper {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.5em;
    color: #C3C3C3;
  }

  .companiesWrapper {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .companyLogoImg {
    height: 3vh;
    margin: 0 1vw;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
  }

  .ticketButton {
    background-color: white;
    color: var(--matterColor);
    border: 1px solid #e7e7e7;
    width: 9vw;
    font-size: 0.9em;
    &:hover {
      background-color: white;
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

/* LOWER THEN 768PX HEIGHT NEW SIDE PANEL */
@media (--viewportHeightMaxHD) {
  .titleWrapper {
      font-size: 1em;
  }
  .sliderTitleWrapper > * {
    margin-bottom: 0.5vh;
  }
  .subtitleText {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .ctaWrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
}
