@import '../../marketplace.css';

.input {
  @apply --marketplaceEnquiryFontStyles;
  border-bottom-color: var(--successColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.labelWithTooltip {
  display: flex;
  flex-direction: row;
}
