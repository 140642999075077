@import '../../marketplace.css';

.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.supportIcon {
  height: auto;
  width: auto;

  @media (--viewportMedium) {
    height: 100;
    width: 200;
  };

  @media (--viewportLarge) {
    height: 100;
    width: 200;
  }
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.woodpeckerImage {
  background-image: url("../../assets/woodpecker.svg");
}

.softwaresuppImage {
  background-image: url("../../assets/softwaresupp.png");
}

.zohoImage {
  background-image: url("../../assets/zoho.png");
}

.mondayImage {
  background-image: url("../../assets/monday.png");
}

.pipedriveImage {
  background-image: url("../../assets/pipedrive.png");
}

.hubspotImage {
  background-image: url("../../assets/hubspot.jpg");
}

.shopifyImage {
  background-image: url("../../assets/shopify.png");
}

.livechatImage {
  background-image: url("../../assets/livechat.png");
}

.magentoImage {
  background-image: url("../../assets/magento.png");
}

.zapierImage {
  background-image: url("../../assets/zapier.png");
}

.msDynamicsImage {
  background-image: url("../../assets/msDynamics.svg");
}

.salesforceImage {
  background-image: url("../../assets/salesforce.png");
}

.sapImage {
  background-image: url("../../assets/sap.png");
}

.bigCommerceImage {
  background-image: url("../../assets/bigCommerce.png");
}

.powerBIImage {
  background-image: url("../../assets/powerBI.png");
}

.officeImage {
  background-image: url("../../assets/MSOffice.png");
}

.tableauImage {
  background-image: url("../../assets/tableau.png");
}

.gaImage {
  background-image: url("../../assets/googleAnalytics.png");
}

.gSuiteImage {
  background-image: url("../../assets/gSuite.png");
}

.matomoImage {
  background-image: url("../../assets/matomo.png");
}

.piwikImage {
  background-image: url("../../assets/piwik.png");
}

.twilioImage {
  background-image: url("../../assets/twilio.png");
}

.klaviyoImage {
  background-image: url("../../assets/klaviyo.png");
}

.integromatImage {
  background-image: url("../../assets/integromat.jpg");
}

.autopilotImage {
  background-image: url("../../assets/autopilot.png");
}

.sharetribeImage {
  background-image: url("../../assets/sharetribe.png");
}

.webflowImage {
  background-image: url("../../assets/webflow.png");
}

.figmaImage {
  background-image: url("../../assets/figma.png");
}

.adobexdImage {
  background-image: url("../../assets/adobe.png");
}

.invisionImage {
  background-image: url("../../assets/invision.png");
}

.wordpressImage {
  background-image: url("../../assets/wordpress.png");
}

.airtableImage {
  background-image: url("../../assets/airtable.png");
}

.googleAdsImage {
  background-image: url("../../assets/googleAds.jpg");
}

.typeformImage {
  background-image: url("../../assets/typeform.png");
}

.nocrmImage {
  background-image: url("../../assets/nocrm.png");
}

.clickupImage {
  background-image: url("../../assets/clickup.png");
}

.hyperiseImage {
  background-image: url("../../assets/hyperise.png");
}

.paperformImage {
  background-image: url("../../assets/paperform.jpg");
}

.woocommerceImage {
  background-image: url("../../assets/woocommerce.png");
}

.prestashopImage {
  background-image: url("../../assets/prestashop.png");
}

.addicnetImage {
  background-image: url("../../assets/addicnet.png");
}

.satismeterImage {
  background-image: url("../../assets/satismeter.png");
}

.voucheryImage {
  background-image: url("../../assets/vouchery.jpg");
}

.chatbotImage {
  background-image: url("../../assets/chatbot.png");
}

.ringOverImage {
  background-image: url("../../assets/ringover.png");
}

.harvestrImage {
  background-image: url("../../assets/harvestr.png");
}

.livestormImage {
  background-image: url("../../assets/livestorm.png");
}

.mailchimpImage {
  background-image: url("../../assets/mailchimp.png");
}

.activeCampaignImage {
  background-image: url("../../assets/activeCampaign.png");
}

.slackImage {
  background-image: url("../../assets/slack.png");
}

.sketchImage {
  background-image: url("../../assets/sketch.png");
} 

.pandaDocImage {
  background-image: url("../../assets/pandaDoc.png");
} 

.intercomImage {
  background-image: url("../../assets/intercom.png");
} 

.facebookAdsImage {
  background-image: url("../../assets/facebookAds.jpg");
} 

.notionImage {
  background-image: url("../../assets/notion.png");
}

.asanaImage {
  background-image: url("../../assets/asana.png");
} 

.atlassianImage {
  background-image: url("../../assets/atlassian.png");
} 

.jiraImage {
  background-image: url("../../assets/jira.png");
} 

.awsImage {
  background-image: url("../../assets/aws.png");
} 

.adobeIllustratorImage {
  background-image: url("../../assets/adobeIllustrator.jpg");
} 

.adobePhotoshopImage {
  background-image: url("../../assets/adobePhotoshop.png");
} 

.googleDocsImage {
  background-image: url("../../assets/googleDocs.png");
} 

.linkedInAdsImage {
  background-image: url("../../assets/linkedInAds.png");
} 

.microsoftAzureImage {
  background-image: url("../../assets/microsoftAzure.png");
} 

.mauticImage {
  background-image: url("../../assets/mautic.png");
} 

.marketoImage {
  background-image: url("../../assets/marketo.png");
} 

.woodpeckerImage,
.softwaresuppImage,
.zohoImage,
.mondayImage,
.pipedriveImage,
.hubspotImage,
.shopifyImage,
.livechatImage,
.magentoImage,
.zapierImage,
.msDynamicsImage,
.salesforceImage,
.sapImage,
.bigCommerceImage,
.powerBIImage,
.officeImage,
.tableauImage ,
.gaImage,
.gSuiteImage,
.matomoImage,
.piwikImage,
.twilioImage,
.klaviyoImage,
.integromatImage,
.autopilotImage,
.sharetribeImage,
.webflowImage,
.figmaImage,
.adobexdImage,
.invisionImage ,
.wordpressImage,
.airtableImage,
.googleAdsImage,
.typeformImage,
.nocrmImage,
.clickupImage,
.hyperiseImage,
.paperformImage,
.woocommerceImage,
.prestashopImage,
.addicnetImage,
.satismeterImage,
.chatbotImage,
.ringOverImage,
.voucheryImage,
.mailchimpImage,
.livestormImage,
.harvestrImage,
.activeCampaignImage,
.slackImage,
.sketchImage,
.pandaDocImage,
.intercomImage,
.facebookAdsImage,
.notionImage,
.asanaImage,
.atlassianImage,
.jiraImage,
.awsImage,
.adobeIllustratorImage,
.adobePhotoshopImage,
.googleDocsImage,
.linkedInAdsImage,
.microsoftAzureImage,
.marketoImage,
.mauticImage {
  background-position: center;
  background-size: contain;
}

.noImageText {
  @apply --marketplaceH6FontStyles;
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.noImageSupportText {
  @apply --marketplaceH6FontStyles;

  padding-bottom: 24px;

  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
