@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  border-radius: var(--borderRadius);
  @media (--viewportSmall) {
    height: 64px;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  @media (--viewportMedium) {
    align-items: flex-end;
  }
}

.ownOfferMessage {
  /* Layout */
  display: flex;
  font-size: 18px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  margin: auto;
  @media (--viewportMedium) {
    align-items: flex-end;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.ticketMessageContent {
  height: auto;
  display: flex;
}

.answerInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3%;

  @media (--viewportMedium) {
    padding: 2% 0 2% 0;
    width: 100%;
    height: 100%;
    flex-direction: row;
  }
}

.main {
  margin-top: 14px;
  display: grid;
  grid-template-columns: 60px auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #e5e5e5;
  @media (--viewportSmall) {
    grid-template-columns: 96px auto;
  }
}

.sectionAvatar {
  grid-row-end: span 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 0 0 3px;

  @media (--viewportMedium) {
    padding: 12px 0 0 7px;
    grid-row-end: span 2;
  }
}

.avatarWrapper {
  /* Layout */
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}

.sectionMainContent {
  margin: 4px, 12px, 12px, 12px;
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    margin-left: 24px;
  }
}

.sectionCta {
  display: flex;
  flex-direction: row;
  border-top: solid 1px #e5e5e5;
  padding-top: 6px;

  @media (--viewportMedium) {
    border-top: none;
    min-width: 20%;
    padding-top: 0;
    border-left: solid 1px #e5e5e5;
    padding-left: 6px;
    flex-direction: column;
    margin: auto 6px auto auto;
    justify-content: right;
  }
}

.jobTitle {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 18px;
  color: var(--matterColor);
  display: flex;
  flex-direction: row;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 5px;
  margin-right: 2px;

  @media (--viewportMedium) {
    @apply --marketplaceH3FontStyles;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.avatarDesktop {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;

  /* Colors */
  background-image: linear-gradient(
    -180deg,
    var(--marketplaceColorLight) 0%,
    var(--marketplaceColor) 100%
  );
  color: var(--matterColorLight);

  &:hover {
    text-decoration: none;
  }
  
  width: 60px;
  height: 60px;
  @media (--viewportMedium) {
    width: 76px;
    height: 76px;
  }
}

.initialsDesktop {
  font-size: 26px;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 5px;
  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: var(--fontWeightRegular);
    padding-bottom: 6px;
  }
}

.solution {
  @apply --marketplaceMessageFontStyles;
  font-size: 14px;
  width: 100%;
  margin-bottom: 0;
}

.messageDate {
  @apply --marketplaceMessageDateFontStyles;
  color: var(--matterColorAnti);

  font-size: 10px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin: 0, auto, 0, auto;
  }
}

.deleteIcon {
  color: var(--matterColorAnti);
  margin-top: 5px;
  margin-right: 5px;
  &:hover {
    color: var(--matterColor);
    cursor: pointer
  }
}

.contentWrapper {
  display: flex;
  flex-direction: row;
}

.title {
  @apply --marketplaceMessageDateFontStyles;
  max-width: 45%;
  width: 100%;
  word-wrap: break-word;
  @media (--viewportMedium) {
    word-wrap: none;
    max-width: unset;
    width: unset;
    @apply --marketplaceDefaultFontStyles;
  }
}

.activity {
  display: flex;
  flex-direction: row;
  padding: 8px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.activityItemContent {
  @apply --marketplaceTxTransitionFontStyles;
  font-size: 14px;
  margin: 0;
}

.activityItemDate {
  @apply --marketplaceMessageDateFontStyles;
  font-size: 12px;
  color: var(--matterColorAnti);
  margin: 7px 0 0 0;

  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.activityDot {
  margin-right: 6px;
}
.solutionText{
  font-size: 14px;
}