@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  /* @apply --marketplaceModalInMobileBaseStyles; */


  padding: 0 20px;

  @media (--viewportMedium) {
    
   
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

/* New Design */

.modalContainer {
  display: flex;
  align-items: center;
  background-color: white;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 500px;
  max-height: 500px;
}

.openBookingButtonProject {
  margin: 10px auto 0 auto;
  width: 80%;
}

.openBookingButton {
  margin: 10px auto 0 auto;
  width: 80%;
  @media (--viewportMedium) {
    margin: auto;
    width: 100%;
  }
}

.close {
  top: 60px;

  @media (--viewportMedium) {
    top: unset;
  }
}