@import '../../marketplace.css';

.layoutWrapper {
  flex-direction: row;
  justify-content: center;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px auto;

  @media (--viewportLarge) {
    padding-top: 55px;
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.modalButton {
  width: calc(33% - 14px);
  padding: 10px;
}

.features,
.priceExplanation {
    display:flex;
    flex-direction: row;
    width: 100%;
    margin: 16px 0;
    font-size: 15px;
}

.priceExplanation {
  color: var(--matterColorAnti);
}

.tickIcon {
    color: var(--marketplaceColor);
    min-width:16px;
    min-height:16px;
    margin-top: 5px;
}

.fieldContainer {
    position: relative;
    font-size: 24px;
    width: 290px;
    padding: 30px 40px 20px;
    margin: 0 5px 25px;
    box-shadow: 0 0 8px 3px rgb(31 44 61 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fieldContainerPopular {
  background-color: #f3f7fc;
}

.fieldContainerBig {
    width: 100%;
    padding-top: 55px;
    margin: 10px;
    @media (--viewportLarge) {
        width:60%;
        padding-top: 55px;
        margin: 0 20px;
    }
}

.featuresContainer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.featuresColumn{
    width: max(200px, 50%);
}

.showMoreLink {
  cursor: pointer;
  font-size: 16px;
  color: var(--marketplaceColor);
  display: block;
  margin-top: 12px;
}

.showLess,
.showLessNewProjectPage {
  height: 502px;
  overflow-y: hidden;
}

.showLessNewProjectPage {
  height: 52px;
}

.showMore {
  height: auto;
}

.noShowMoreLink {
  height: 32px;
}

.versionTitle {
    font-size: 28px;
}

.versionTitleSmallFont {
  font-size: 22px
}

.priceValue {
    display: block;
    width: 100%;
    font-size: 22px;
}

.priceValueSmallFont {
  font-size: 18px;
}

.priceText {
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 16px;
    border-bottom: solid 1px var(--marketplaceColor);
    margin-bottom: 28px;
}

.submitButton {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 8px;
    box-shadow: 0 0 8px 3px rgb(31 44 61 / 10%);
    border: none;
}

.readMoreLink {
    text-align: center;
}

.mostPopular {
  position: absolute;
  top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.infoIcon {
  vertical-align: text-top;
  margin-left: 2px;
}

.tooltipMessage {
  @apply --marketplaceTinyFontStyles;
}