@import '../../../marketplace.css';
@import './panelWrappers.css';

.centerPanel {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    grid-area: centerPanel;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sliderTitleWrapper {
    color: var(--matterColor);
    margin-bottom: 30px;
    font-size: 24px;
}

.expertWrapper {
    display: grid;
    grid-template-columns: 140px 5fr;
    grid-template-areas: 
        "avatar data";
    font-size: 22px;
    width: fit-content;
    margin-bottom: 3vh;
}

.expertAvatar {
    grid-area: avatar;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expertAvatarImage {
    width: 90px;
    height: auto;
    border-radius: 50%;
}

.expertData {
    grid-area: data;
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.expertName {
    color: #5F5E63;
    margin-bottom: 1vh;
}

.expertTitle {
    margin-bottom: 1vh;
}

.expertTags {
  display: flex;

}

.createListingLinkCTA {
  margin-top: 20px;
}

.ctaButton {
  min-height: 44px !important;
  max-height: 54px !important;
  padding: 10px 16px !important;
}

.jobWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
}

.firstRow {
  display: flex;
  justify-content: space-around;
}

.jobLogoWrapper {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-left: 50px; */
   
    margin-bottom: 2vh;
}

.jobLogoImage {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* ANIMATION */
.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  max-height: 430px;
}

.contentLeftOpen {
  animation: openRightContent 0.4s ease-out forwards;
}
.contentLeftClosed {
  animation: closeRightContent 0.4s ease-out forwards;
}
.contentRightOpen {
  animation: openLeftContent 0.4s ease-out forwards;
}
.contentRightClosed {
  animation: closeLeftContent 0.4s ease-out forwards;
}

@keyframes openLeftContent {
  0% { 
    transform: translateX(100%);
  }
  100% { 
    transform: translateX(0); 
  }
}
@keyframes closeLeftContent {
  0% { 
    transform: translateX(0);
  }
  100% { 
    transform: translateX(-100%);
  }
}
@keyframes openRightContent {
  0% { 
    transform: translateX(-100%);
  }
  100% { 
    transform: translateX(0%); 
  }
}
@keyframes closeRightContent {
  0% { 
    transform: translateX(0);
  }
  100% { 
    transform: translateX(100%);
  }
}

/* old styles */

.subtitleWrapper {
    margin: 1vh 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.subtitleText {
    width: 90%;
    font-size: 0.7em;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.lightboxButtonWrapper {
  margin: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleButton {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 50%;
  background-color: var(--marketplaceColorLight);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.2vw;
  cursor: pointer;
}

  .companiesHeadingWrapper {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.5em;
    color: #C3C3C3;
  }

  .companiesWrapper {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .companyLogoImg {
    height: 3vh;
    margin: 0 1vw;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .ticketButton {
    background-color: white;
    color: var(--matterColor);
    border: 1px solid #e7e7e7;
    width: 9vw;
    &:hover {
      background-color: white;
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

  .smallCTAButton {
    width: 11vw;
    &:hover {
      box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

/* LOWER THEN 768PX HEIGHT NEW SIDE PANEL */
@media (--viewportHeightMaxHD) {
  .sliderTitleWrapper {
    font-size: 18px;
    margin-bottom: 3vh;
  }
  .jobTitleWrapper {
    font-size: 18px;
  }
  .expertData {
    font-size: 14px;
  }
  .jobQuoteText {
    font-size: 14px;
  }
  .expertWrapper {
    grid-template-columns: 120px 5fr;
  }

  .titleWrapper {
      font-size: 1em;
  }
  .sliderTitleWrapper > * {
    margin-bottom: 0.5vh;
  }
  .subtitleText {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .ctaWrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.trustpilotRatingWrapper {
  margin-bottom: 2vh;
  text-align: center;
}

.trustpilotRatingImage {
  width: 7vw;
  height: auto;
}

