@import '../../../marketplace.css';

.examCardRoot {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 5px;
  background-repeat: no-repeat;
  box-sizing: inherit;
  color: var(--matterColor);
  margin-bottom: 36px;
  flex-basis: 100%;
  min-height: 200px;
  padding: 15px 15px 17px;
  border: 1px solid #d4d4d4;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
}

.firstColumnWrapper {
  display: block;
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  position: relative;
  transition: ease-in-out 0.1s;
  cursor: pointer;
}

.secondColumnWrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-left: 15px;
  width: 100%;
  min-height: 150px;
  position: relative;
}

.titleWrapper {
  @apply --marketplaceLinkStyles;
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 10px 0;
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.descriptionWrapper {
  @apply --marketplaceH5FontStyles;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  margin-bottom: 1px; /* Fix 1px bug */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 2px;
}

.link {
  color: inherit;
}

.link:hover {
  text-decoration: none;
}