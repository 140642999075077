@import '../../marketplace.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.codeFieldWrapper {
  display: flex;
  flex-direction: column;
}

.codeFieldHeadlineWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8em;
}

.codeCurrentVersion {
  width: 40%;
}

.codeActionButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 60%;
}

.buttonCopy {
  color: var(--marketplaceColorLight);
  text-decoration: underline;
  margin-left: 15px;
  cursor: pointer;
}

.buttonCopy:active,
.buttonSwitch:active {
  color: #064599;
}

.buttonSwitch {
  color: var(--marketplaceColorDark);
  text-decoration: underline;
  margin-left: 15px;
  cursor: pointer;
}

.codeContainer {
  margin: 5px 0px;
  padding: 20px 20px;
  border: 2px dashed darkslategray;
  word-wrap: break-word;
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  font-size: 0.7em;
}

.content {
  max-width: 787px;
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-top: 5vh;
  }
}

.number {
  @apply --marketplaceHeroTitleFontStyles;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  text-align: center;
  margin-top: 16px;

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 0;
  }
}