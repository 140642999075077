.goBackButton {
  display: flex;
  align-items: center;
  max-width: 200px;
  outline:none;
&:hover {
   text-decoration: none;
 }
}

.goBack {
  margin-top: 8px;
/* margin-right: auto; */
@media (--viewportMedium) {
  margin-top: 16px;
  padding: 0px 12px 0px;
}
@media (--viewportLarge) {
  margin-top: 50px;
  padding: 0px 36px 0px;
}
}

.goBackIcon {
  fill: none;
  stroke: var(--marketplaceColor);
  margin-right: 4px;
}

.error {
        color:red;
        font-weight:bold;
        display: flex;
        align-items: center;
        gap:10px;
}

.success {
        color:green;
        font-weight:bold;
        display: flex;
        align-items: center;
        gap:10px;
}

.inprogress {
   color:#7c7c7c;
        font-weight:bold;
        display: flex;
        align-items: center;
        gap:10px;
}
.fileField {
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 5px;

    &:hover {
            cursor: pointer;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 34%);
    transition: all 0.3s;

    }
}

.fieldTitle {
    padding: 8px;
    display: block;
    margin-bottom: 5px;
}

.sendButton {
   width:50% !important;
}

.buttonContainer {
        display:flex;
        justify-content:center;
        margin-top:20px;
}
