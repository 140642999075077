@import '../../marketplace.css';

.root {
  height: 100%;
}

.progressBarContainer {
  margin-left: 0;
  margin-right: auto;
}

.segment {
  fill: #e2e2e2;
  stroke: none;
}

.segmentFilledOut {
  fill: var(--marketplaceColor);
  stroke: none;
}

.textCircle {
  text-anchor: middle;
  font-size: 20px;
}