@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.mainWrapper {
  margin: 12px 12px;

  @media (--viewportMedium) {
    margin: 20px 30%;
  }
}

.submitted {
  margin:10%;
  text-align: center;
  @media (--viewportMedium) {
  margin: 25%;
  }
}

.thankYou{
  text-align: center;
  font-size: 50px;
  margin-bottom: 20px;
}

.arrow{
  transform: rotate(87deg);
  margin-left: 10px;
}