@import '../../marketplace.css';

/* .layout {
  position:sticky;
  top: 50px;
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 10px 12px;
  overflow-x: visible;
  overflow-y: hidden;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {

    padding: 40px 24px 24px 24px;
  }

  @media (--viewportLarge) {
    overflow-y: initial;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
  @media (--viewportLargeWithPaddings) {
    padding: 40px 0 40px calc((100% - 1056px) / 2);
  }
} */

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 8px;
  border-bottom: solid 1px var(--matterColorNegative);

  @media (--viewportMedium) {
    margin-right: auto;
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 22px;
    border-bottom: none;
  }

  @media (--viewportLarge) {
    border-left: none;
    padding-bottom: 0;
  }
}

.tabs {
  position:relative;

  & .currentTab::after{
    position: absolute;
    right: 0%;
    content: '';
    height: 130%;
    background: #3c88f8;
    width: 4px;
    top: -3px;
  }

  /* @media (--viewportMedium) {
    margin-top: 5px;
    border-left: none;
  } */
}
@keyframes tabProgress{
  0% {
    height:0%;
  }
  100%{
    height: calc(100% * (var(--index) / var(--indexLength)));
  }
}

.projectsTabs {
  width: 250px;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin: 10px 0 auto 0;
    border-left: none;
  }
}

.tab,
.saveTab,
.currentTab,
.disabledTab {
  font-size: 1.125rem;
  line-height: 16px;
  border-bottom-width: 0;
  margin-top: 5px;
  margin-bottom: 6px; 
  padding-top: 11px;
  padding-bottom: 7px;
  position:relative;

  & span {
    margin-right:30px;
  }
}

.tab:hover,
.saveTab:hover,
.currentTab {
  line-height: 1rem;
  cursor: pointer;
  /* border-right:4px solid #3c88f8; */
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%233c88f8' fill-rule='evenodd'/></svg>"); */
  background-position: 100%;
}
.currentTab > span {
  font-weight: 600;
}

.saveTab:hover {
  color: var(--successColor);
}

.disabledTab {
  color: var(--matterColorAnti)
}

.icon {
  vertical-align: text-top;
  margin-right: 15px;
}

.icon path {
  stroke: currentColor;
}

.iconSidebar {
  display: flex;
  flex-direction: column;
}

.iconLink {
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.iconLinkActive {
  color: var(--marketplaceColor);
}

.iconLinkaActive path{
  stroke: var(--marketplaceColor)
}

.iconVertical {
  font-size: 24px;
  margin: 20px 20px 20px -10px;
  cursor: pointer;
}

.iconVertical path {
  stroke: inherit;
}

.iconHovered path {
  stroke: var(--marketplaceColor);
}
