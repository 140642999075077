@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFullScreenStyles;
  padding: 20px;
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}


/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}
