@import '../../marketplace.css';

.projectTitle {
  font-weight: 500;
  font-size: 18px;
}

.companyTitle{
  font-weight: 400;
  font-size:18px;
}

.projectTime {
font-weight: 400;
font-size: 15px;
color: #727171;
}
.mapWrapper {
    width: 100%;
    position: relative;
    display: inline-block;
}

.rowBorder{
  border: 1px solid #E5E5E5;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background:#fff;
  padding:1rem;
  margin-bottom:2rem;
}

.fontDescription{
  font-size:20px;
}

.expertTitle {
  font-size:1.5rem;
  font-weight: 500;
  @media (--viewportMediumMax){
    text-align:left;
  }
}
.expertise {
  font-size:1.125rem;
  font-weight:400;
  margin-bottom:1rem;
  @media (--viewportMediumMax){
    text-align:left;
    margin-bottom:0.5rem;
  }

}

.location {
  font-weight:300;
  font-size:1rem;
  color:#727171;
  margin-bottom:1rem;
  @media (--viewportMediumMax){
    text-align:left !important;
    margin-bottom:0.5rem;
  }
}

.sectionPadding {
  padding:1rem;
}

.borderRight{
  border-right:1px solid #E5E5E5;
  @media(--viewportMediumMax){
    border-right:none;
  }
} 

.mobileBorder{
  @media (--viewportMediumMax){
    border-top:1px solid #e5e5e5;
  }
}

.sectionTitle{
  font-weight: 700;
  font-size: 16px;
}
.goBack {
  margin-bottom:1rem;
  margin-top:1rem;
}

.reviewHeaderCorrection{
  padding-top: 13px;
}

.textRight {
  text-align:right;
}

.navigationHidden {
  @media (--viewportMedium) {
    padding-left: 30px !important;
    min-width:40px;
    padding-right: 2px !important;
  }
}

.fileAttachemtn {
  padding:10px;
  background: #f1f3f6;
  border-radius: 5px;
    margin-right: 5px;
}

.modalContainer {
  position:relative;
}
