.mainWrapper {
    height: 100%;
    padding: 0 2vw;
}

.leftPanel {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    grid-area: leftPanel;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerPanel {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    grid-area: centerPanel;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rightPanel {
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    grid-area: rightPanel;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftArrowButton,
.rightArrowButton {
    cursor: pointer;
}

.bottomPaddingBox {
    height: 10vh;
}