@import '../../marketplace.css';

.layoutWrapper {
  flex-direction: column;
  justify-content: center;
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.modalButton {
  width: calc(33% - 14px);
  padding: 10px;
}
.addBudgetbutton {
  margin-top: 30px;
}

.formElements {
  margin-top: 15px;
}
.priceText {
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 16px;
  border-bottom: solid 1px var(--marketplaceColor);
  margin-bottom: 28px;
}

.readMoreLink {
  text-align: center;
}

.mostPopular {
  position: absolute;
  top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.mostPopular svg {
  vertical-align: text-top;
}

.balanceWrapper {
  display: inline;
  padding: 20px 30px;
  margin-bottom: 30px;
  box-shadow: 0 0 8px 3px rgb(31 44 61 / 10%);
  border-radius: var(--borderRadius);
  @media (--viewportMedium) {
    width: 500px;
  }
}



.leftColumn {
  
  @media (--viewportLarge) {
  
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 50px;
  padding-right: 60px;
  padding-bottom: 200px;
  /* part for side panel animation purposes */
  background-color: white;
  z-index: 5;
  border-right: 2px solid rgba(0, 0, 0, 0.04);
  border-bottom: 0px solid rgba(0, 0, 0, 0);
  box-shadow: 0 7px 3px 0px rgba(0, 0, 0, 0.04);
  }
}

/* .listWrapper { */
/*   display: inline; */
/*   padding: 20px 30px; */
/*   margin-bottom: 5px; */
/*   border-radius: var(--borderRadius); */
/*   flex-grow: 1; */
/*   @media (--viewportMedium) { */
/*   } */
/* } */

.invoiceList {
  width:100%;
}

.invoiceItem{
  display: flex;
  box-sizing: border-box;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  justify-content: space-around;
  align-items:center;
  padding:10px 5px;
  margin-bottom:10px;

  & .field {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #4F4E4E;
    margin-right:5px;
  }
}

.mobileInline {
  @media(--viewportMediumMax){
  display:flex;
  }
}

.invoicesList {
  display: flex;
  flex-wrap: 1;
  flex-direction: row;
  @media (--viewportMedium) {
    width: 700px;
  }
}

.listItem {
  /* Layout */
  margin-bottom: 5px;
  padding-bottom: 12px;

  /* border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative); */
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (--viewportLarge) {
    margin-bottom: 5px;
    padding-bottom: 15px;
  }
}

.balanceDetailsTitle {
  font-size: 24px;
  text-align: left;
}

.balanceAmmountInfo {
font-style: normal;
font-weight: 400;
font-size: 10px;

color: #4F4E4E;

  @media(--viewportMediumMax){
    text-align:center;
  
    & span {
      display:block;
      width:100%;
      text-align:center;
    }
  }

    
}

.balanceAmount {
font-weight: 500;
font-size: 32px;
color: #000000;
  @media(--viewportMediumMax){
    & span {
      width:100%;
      display:block;
      text-align:center;
    }
  }
}
.balances {
  display: flex;
  justify-content: space-around;
}

.balanceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loadingInfo {
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: 15px;
}

.balanceInfo > p {
  margin: 4px;
}

.stripeAccountsContent {
  @apply --marketplaceH1FontStyles;
  padding-top: 10px;
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.stripeAccountsData {
  display: flex;
  justify-content: space-between;
}

.invoicesContent {
  @apply --marketplaceH1FontStyles;
  padding-top: 10px;
  display: flex;

  justify-content: space-between;
}

.button {
  padding: 5px 10px;
  max-width:155px;
font-style: normal;
font-weight: 400;
font-size: 14px;
/* identical to box height */

color: #4F4E4E;
margin-bottom: 0px;
min-height: unset;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}

.title {
  font-size: 22px;

  margin-bottom: 12px;
  text-align: left;
}

.space {
  margin-bottom: 5px;
}

.error {
  font-size: 12px;
  color: crimson;
}

.projectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.mainContainer {
  /* @media (--viewportLarge) {
    margin: 50px 50px 100px 50px;
    display: flex;
    flex-direction: row;
    flex: 1;
  } */
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  
  @media (--viewportLarge) {
  width: 80%%;
  flex-grow: 1;
  display: flex;
  margin-left: 0px;
  background-color: var(--matterColorLight);


  }
}

.rightColumn {
  @media (--viewportLarge) {
    flex-grow: 1;
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 200px;
   
  }
}

.invoiceItemContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  @media (--viewportLarge) {
  }
}

.chartContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}


.mainSpinner {

  width: 100%;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  flex: 1;
  justify-content: center;

}
.invoiceInfo {
  flex-direction: column;
  padding-left: 10px;
}


.zeroBalance,
.positiveBalance {
  font-size: 22px;
}

.positiveBalance {
  color: var(--successColor);
}

ul{
  margin: 0;
}

.adjustmentsWrapper{
  display:flex;
  flex-direction:column;
  padding:10px;
  flex-grow: 1;
  max-width:50%;
}

.adjustmentsWrapper > span{
  font-size:15px;
}

.adjustmentsItems{
  margin-bottom: 15px;
  padding: 40px 20px;
  box-shadow: 0 0 8px 3px rgb(31 44 61 / 10%);
  border-radius: var(--borderRadius);
  flex-grow: 1;
}


.callOutStyle {
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #F0F0F0;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 8px;
margin-bottom:20px;

	@media only screen and (max-width:768px){
    min-height:70vh; 
  }
}

.invoiceBlock{
/* Rectangle 42 */
box-sizing: border-box;
border: 1px solid #E6E6E6;
border-radius: 8px;
} 
.balancePanelTitle {
  /* Your balance */
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #4F4E4E;


	@media only screen and (max-width:768px){
   text-align:center; 
  }
}

.tabsStyle {
  border-bottom:1px solid #9f9f9f !important;
  margin-botton:14px;
  & button{
    border:none !important;
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #4F4E4E;
  }

  & button.active {
    font-weight: bold;
  }

}

.projectSubHeader {
font-style: normal;
font-weight: 400;
font-size: 16px;
margin-bottom:12px;
}

.projectSubText {
  font-style: normal;
font-weight: 400;
font-size: 14px;
color: #727171;
  }

.buttonExtend {
  max-width: unset !important;
}

.toBoardLink {
  text-align:right;
}

.containerMargin {
  margin-top:40px;
  @media(--viewportMediumMax){
    margin-top:20px;
  }
}

.greenBtn {
  color:#fff;
  min-height: 65px;
  font-size:18px;
  max-width: unset;

  @media(--viewportMediumMax){
    margin-top:20px;
    margin-bottom:20px;
    max-width:unset !important
  }
}

.accClassCorrect {
  background: transparent !important
}

.AccItemListCorrection {
  margin-top:8px;
  margin-bottom:8px;
  border:1px solid #E6E6E6;
  overflow: hidden;
  border-radius:8px;
  border-top:1px solid #E6E6E6 !important;
  
  & h2 {
    margin-top:0px;
  }

  @media(--viewportMedium){
    & h2 {
      margin-top:0px;
    }
  }
}

.balancePanel {
  @media(--viewportMediumMax){
    margin-top: 15%;
    margin-bottom: 30%;
  }
}


.bottomNaveIcone {
  text-align: center;
}
.bottomNavIcon {
  font-size:15px;
}

.navContainer {
  padding-left:2rem;
  padding-right:2rem;
}

.showTab {
  display:flex !important;
}
.activeNav{
  color:#085bd9 !important
}

.test{
  background:red;
}
.payoutItem {
  text-align: right;
}
