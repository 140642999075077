@import '../../marketplace.css';

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 733px;
}

.message {
  display: flex;
  flex-direction: row;
}

.ownMessage {
  float: right;
}

.avatar {
    flex-shrink: 0;
    margin: 0px 12px 0 0;
  
    @media (--viewportMedium) {
      margin: 0px 12px 0 0;
    }
}
  
.ownMessageContentWrapper {
    @apply --clearfix;
}
  
.messageContent,
.ownMessageContent {
    @apply --marketplaceMessageFontStyles;
  
    display: inline-block;
    margin: 0;
    padding: 8.5px 14px 8.5px 14px;
    border-radius: 8px;
    box-shadow: var(--boxShadow);
  
    @media (--viewportMedium) {
      padding: 8.5px 14px 8.5px 14px;
      margin: 0;
    }
}
  
.messageContent {
    flex: 1;
}
  
.ownMessageContent {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
    float: right;
}
  
.messageDate,
.ownMessageDate {
    @apply --marketplaceMessageDateFontStyles;
    margin: auto;
  
    margin: 11px 0 0 0;
    color: var(--matterColorAnti);
  
    @media (--viewportMedium) {
      margin: 6px 0 0 0;
    }
}
  
.ownMessageDate {
    text-align: right;
}

.ownMessageDateWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.statusIconWrapper {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.longWord {
    /* fallback option */
    word-break: break-all;
    /* use break-word if available */
    word-break: break-word;
    hyphens: auto;
}
  
.ownMessageLink {
    color: var(--matterColorNegative);
}

.messageLine {
  @apply --marketplaceMessageFontStyles;
  font-size: 14px;
}