@import '../../../marketplace.css';

.scopeTitle {
    /* Font */
    @apply --marketplaceH3FontStyles;
    color: var(--matterColorAnti);
    margin-top: 0;
    margin-bottom: 12px;
    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 12px;
    }
}
  
.scopeWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}

.scopeContainer {
    display: flex;
    flex-direction: column;
}

.scopeItemWrapper {
    display: flex;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.boldText {
    font-weight: var(--fontWeightMedium);
}

.scopeItemIcon {
    width: 10%;
}

.scopeItemTask {
    width: 90%;
}

.scopeSubtaskWrapper {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.scopeSubtaskEmptyWrapper {
    width: 10%;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
}

.scopeSubtaskIcon {
    width: 5%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

.scopeSubtask {
    width: 85%;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
}

@media (--viewportMediumMax) {
    .scopeTaskWrapper {
        font-size: 0.8em;
    }
    .scopeSubtaskIcon {
        width: 8%;
    }
    .scopeSubtask {
        width: 82%;
    }
}

.projects {
    display: flex;
    flex-direction: row;
}
  
.projectsSideLine,
.projectsLastSideLine {
    width: 1px;
    background-color: var(--chipPrimaryColor);
    margin-left: 4px;
}
  
.projectsSideLine {
    height: 110%;
}
  
.projectsLastSideLine {
    height: 75%;
}

.projectsNoSideLine {
    height: 0;
}

.projectPointer {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--marketplaceColor);
}

.projectImage {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.projectWrapper {
    margin-left: 15px;
    min-height: 40px;
    margin-bottom: 10px;
}

.projectPointer {
    color: var(--marketplaceColor)
}

.projectFirstRow {
    margin-bottom: 8px;
}

.projectSideWrapper {
    margin-top: 9px;
}

.projectDescription {
    color: var(--matterColorAnti)
}

.projectTitle {
    font-size: 16px;
}
.subtask {
    display: block;
    font-size: 14px;
}