@import '../../marketplace.css';

.root {
  width: 100%;
  height: 100%;
  /* position: sticky; */
  top: 0;
  left: 0;
}

.rootPositionBlocked {
  position: fixed;
}

.lightboxItem {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.9);
  z-index: 10000;
  overflow-x: hidden;
  overflow-y: hidden;
}

.lightboxCloseWrapper {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.contentWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videoWrapper {
  width: 70vw;
  height: 70vh;
}

iframe {
  width: 100%;
  height: 100%;
}

.lightboxText {
  font-size: 20;
  color: white;
}
