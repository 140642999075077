@import '../../../marketplace.css';

.attachmentsWrapper {
  margin-top: 6px;
  margin-bottom: 6px;
}

.linkWhite {
  color: white;
}

.linkBlack {
  color: var(--matterColor);
}